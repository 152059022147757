import React from 'react';

import { Table, message, Spin,   Button, Input,Popconfirm,Popover } from 'antd';
import {CheckCircleTwoTone,StopTwoTone,CheckSquareOutlined,CloseSquareOutlined,  } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';//<i class="fab fa-creative-commons-nc"></i>
import { faStamp } from '@fortawesome/free-solid-svg-icons';//<i class="fas fa-stamp"></i>
import ctrService from '../../services/ctr-service';
import permissions from '../../constants/permissions';
import moment from 'moment';


export default class CurrencyTransactionReport extends React.Component 
{
    state = {
		data: [],
		ctr_code : null,
		loading: false
	};

	
	columns = [
		{
			title: 'Customer',
			key: 'customer',
			sorter: (c1, c2) => c1 > c2,
			render: (_, record) =>
			{
				return (record.name ? `${record.name}`  : `${record.first_name} ${record.middle_name} ${record.last_name}`)
			}
		},
		{
			title: 'Amount',
			key: 'amount',
			sorter: (c1, c2) => c1 > c2,
			render: (_, record) =>
			{
				return parseFloat(record.amount).toLocaleString()
			}
		},
		{
			title: 'Activity Date/Time',
			key: 'created_at',
			sorter: (c1, c2) => c1 > c2,
			render: (_, record) =>
			{
				return (moment(record.created_at).format('MM/DD/YYYY h:mm a'))
			}
		},
		{
			title: 'Confirm Creation',
			render: (_, record) =>
			this.state.data.length >= 1 ? (
				<Popover 
					content={
						<div className="refund-amount-block-full">
							<Input onChange={val => {this.setState({ctr_code: val.target.value});}} />
							{/*<Popconfirm title="Are you sure?" onConfirm={() => this.handleConfirm(record.id)}>*/}			  
								<Button type="primary" loading={this.state.loading} onClick={() => this.handleConfirm(record.id)}><FontAwesomeIcon type="left" className="icons" icon={faStamp} /> Confirm Resolve</Button>
							{/*</Popconfirm>*/}
						</div>
					}
					title="Confirm Resolve"
					trigger="click"
				>
					<CheckSquareOutlined style={{ fontSize: '23px',color:'#52c41a'}}/>
				</Popover>
			) : null
		}
	];

	handleConfirm = async(id) => {

		if(!this.state.ctr_code)
		{
			message.error('File Confirmation Code is Mandatory!!');
			return;
		}
		
		this.setState({ loading: true });
		let resp;
		try 
		{
			let ctrCode = this.state.ctr_code;
			resp = await ctrService.confirmShopNeededCtr(id,ctrCode);
			if (!resp) throw new Error('Something went wrong');
			this.setState({
				data: resp.data,
				loading: false
			});
		}
		catch (e)
		{
			this.setState({ loading: false });
			console.error(e.message);
			message.error(e.message);
			return;
		}
		message.success('Resolved Successfuly');
	}

	async componentDidMount() {
		this.setState({ loading: true });
		let data;
		try {
			data = await ctrService.getShopNeededCtr();
			if (!data.success) throw new Error(data.message);
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}
		console.log(data);
		this.setState({
			data: data.data,
			loading: false
		});
	}

	componentDidUpdate() {

	}

	render() {
		return (
			<Spin spinning={this.state.loading}>
				<Table
					dataSource={this.state.data}
					columns={this.columns}
					style={{ minHeight: '300px' }}
					size="small"
				/>
			</Spin>
		);
	}
}
