import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import shopService from '../../services/shop.service';
import states from '../../assets/states.json';
import authService from '../../services/auth.service';
import permissions from '../../constants/permissions';
import CurrencyService from '../../services/currency.service';
import EditAgentSlabs from './EditAgentSlabs';
import SlabServics from '../../services/slab.service';
import './style.css';

import { Form, Input, Button, Select, Checkbox, message, Spin, Upload,InputNumber,Modal,Row,Col,Switch } from 'antd';
import { UploadOutlined,InfoCircleOutlined,SaveOutlined, StopOutlined } from '@ant-design/icons';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};
const { TextArea } = Input;

const normFile = e => {
	if (Array.isArray(e)) {
		return e;
	}

	return e && e.fileList;
};

const AddNewShop = props => {
	let { id: shopId } = useParams();
	const [unmounted, setUnmounted] = useState(false);
	const [shops, setShops] = useState([]);
	const [loading, setLoading] = useState(!!shopId);
	const [isSlabLoading, setIsSlabLoading] = useState(true);
	const [shop, setShop] = useState();
	let history = useHistory();
	const [form] = Form.useForm();
	const [errors, setErrors] = useState({});
	const [fileList, setFileList] = useState([{}]);
	const [edit_limit_visible, setEditLimitVisible] = useState(false);
	const [maxtrxcount, setMaxLimit] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [currency, currencyData] = useState([]);
	const [startupslabs, setStartupSlabs] = useState([]);
	const [AgentCurrencySlabs, setAgentCurrencySlabs] = useState([]);
	const [allToShow, setShowAll] = useState(false);

	const statesData = states.map(data => {
		return <Option key={data.abbreviation} value={data.abbreviation}>{data.name}</Option>;
	});

	const onShowAll = (e) => {
		setShowAll(e)
	}

	const editMaxLimit = () => 
	{
		if(shop)
			setEditLimitVisible(true);
	}

	const onEditCancel = () => 
	{
		setEditLimitVisible(false);
	}

	const onUpdateShopLimits = async () => {
		setConfirmLoading(true);
		setErrors({});
		try {
			await shopService.editShopLimits({shop_id:shop.id,max_trx_count:maxtrxcount});
			message.success('the shop limit was edited successfully');
			onEditCancel();
			setConfirmLoading(false);
			history.push({
				pathname: '/dashboard/list-shops',
				state: { id: shop.id }
			});
		} catch (e) {
			message.error(e.message);
			console.error(e);
			onEditCancel();
			setConfirmLoading(false);
			return setErrors(e.errors);
		}
	};
	
	const currencies = currency.map(data => {
		return <Option key={`shopcurrency${data.code}`} value={data.code}>{data.code}</Option>;
	});

	const onFinish = async values => {
		setLoading(true);
		setErrors({});
		if (values.logo) values.logo = values.logo[0].originFileObj;
		let fd = new FormData();
		Object.keys(values).forEach(k => values[k] && fd.append(k, values[k]));
		values.country = 'USA';
		if (shop) {
			try {
				await shopService.editShop(fd, shop.id);
				message.success('the shop was edited successfully');
				setLoading(false);
				history.push({
					pathname: '/dashboard/list-shops',
					state: { id: shop.id }
				});
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setLoading(false);
				return setErrors(e.errors);
			}
		} else {
			try {
				await shopService.addNewShop(values);
				setLoading(false);
				message.success('the shop was added successfully');
				form.resetFields();
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setLoading(false);
				return setErrors(e.errors);
			}
		}
	};

	const onUpdateCurrencySlabs = async (data)=>
	{
		setIsSlabLoading(true);
		setErrors({});
		try {
			data['shop_id'] = shopId;
			await shopService.updateShopCurrencySlabs(data);
			setIsSlabLoading(false);
			message.success('the shop was edited successfully');
		} catch (e) {
			setIsSlabLoading(false);
			message.error(e.message);
			console.error(e);
		}
	}

	const fetchShop = async id => {
		if (id) {
			setLoading(true);
			try {
				var resp = await shopService.getShop(id);
				if (!resp.success) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}

			let oldLogoFile = [...resp.data][0].logo;
			delete resp.data[0].logo;

			setShop(resp.data[0]);
			form.setFieldsValue({ ...resp.data[0] });
			setFileList(oldLogoFile);
			setLoading(false);			
			setIsSlabLoading(false);
		}
	};

	const fetchShops = async () => {
		try {
			var resp = await shopService.getShops(0,0,true);
			if (!resp.success) throw new Error('There is an error please try again!');
		} catch (e) {
			message.error(e.message);
			console.error(e);
			setErrors(e.errors || {});
			return;
		}
		setShops(resp.data);
	};
	
	const fetchAgentCurrencySlabs = async shop_id => {
		if (shop_id) {
			setIsSlabLoading(true);
			try {
				var resp = await SlabServics.getShopSlabs(shop_id);
				if (!resp.data) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}
			setAgentCurrencySlabs(resp.data);
			setIsSlabLoading(false);
		}
	};

	useEffect(() => {
		if (!unmounted) {
			form.resetFields();			
			setStartupSlabs([]);
			fetchShop(shopId);
			fetchAgentCurrencySlabs(shopId);
		}
	}, [shopId]);

	useEffect(() => {
		if (!unmounted) fetchShops();
		return () => setUnmounted(true);
	}, []);
	
	useEffect(() => {
		async function fetchCurrrency() {
			let currencyResult = await CurrencyService.getCurrencies({ all: true });
			currencyData(currencyResult.data);
		}
		fetchCurrrency();
	}, []);

	const onReset = () => {
		setErrors({});
		form.resetFields();
	};

	return (
		<Spin spinning={loading}>
			{
				shopId ? <h3 className="h3">Edit Shop</h3> : <h3 className="h3">Add New Shop</h3>
			}
			<div className="p-3">
				<Form
					className="row"
					{...layout}
					form={form}
					name="control-hooks"
					onFinish={onFinish}
					initialValues={{
						name: shop ? shop.name : null,
						tax_id: shop ? shop.tax_id : null,
						contactperson: shop ? shop.contactperson : null,
						phone: shop ? shop.phone : null,
						email: shop ? shop.email : null,
						state: shop ? shop.state : null,
						city: shop ? shop.city : null,
						location: shop ? shop.location : null,
						parent_shop_id: shop ? shop.parent_shop_id : null,
						max_trx_count: shop ? shop.max_trx_count : null,
						current_limit: shop ? shop.current_limit : null,
						default_currency: shop ? shop.default_currency : null,
						terms: shop ? shop.terms : null,
						is_active: shop ? shop.is_active : 1
					}}
				>
					<Form.Item
						className="col-6"
						name="name"
						label="Name"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.name && 'error'}
						help={errors?.name?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						className="col-6"
						name="contactperson"
						label="Contact Person"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.contactperson && 'error'}
						help={errors?.contactperson?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						className="col-6"
						name="phone"
						label="Mobile"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.phone && 'error'}
						help={errors?.phone?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						className="col-6"
						name="email"
						label="email"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.email && 'error'}
						help={errors?.email?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						className="col-6"
						name="tax_id"
						label="Tax ID"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.tax_id && 'error'}
						help={errors?.tax_id?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						className="col-6"
						name="city"
						label="City"
						validateStatus={errors?.city && 'error'}
						help={errors?.city?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						className="col-6"
						name="location"
						label="Address"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.location && 'error'}
						help={errors?.location?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						className="col-6"
						name="state"
						label="State"
						rules={[{ required: true, message: 'Please select the State!' }]}
						validateStatus={errors?.state && 'error'}
						help={errors?.state?.join('\n')}
					>
						<Select
							showSearch
							placeholder="Select a state"
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
						>
							{statesData}
						</Select>
					</Form.Item>

					<Form.Item
						className="col-6"
						name="parent_shop_id"
						label="Parent Shop"
						validateStatus={errors?.parent_shop_id && 'error'}
						help={errors?.parent_shop_id?.join('\n')}
					>
						<Select
							showSearch
							placeholder="Select a state"
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
						>
							{shops.map(s => (
								<Option key={`shopstate${s.id}`} value={s.id}>{s.name}</Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						className="col-6"
						name="default_currency"
						label="Default Currency"
						validateStatus={errors?.default_currency && 'error'}
						help={errors?.default_currency?.join('\n')}
					>
						<Select
							showSearch
							placeholder="Please select the Currency!"
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
						>
							{currencies}
						</Select>
					</Form.Item>
						
					{
						authService.hasPermissions(permissions.operationCanChangeMaxCount)
						? <>
							<Form.Item
								className="col-6"
								name="max_trx_count"
								label="Limit"
								onClick={editMaxLimit}
								rules={[
									{
										required: false
									}
								]}
								tooltip={{
									title: 'Operation Count Limit',
									icon: <InfoCircleOutlined />,
								}}
							>
								<InputNumber
									value= ""
									placeholder="Empty = Unlimited"/>
							</Form.Item>
							<Form.Item								
								tooltip={{
									title: 'Remaining Operations',
									icon: <InfoCircleOutlined />,
								}}
								className="col-6"
								name="current_limit"
								label="Remaining"
							>
								<InputNumber disabled={true}/>
							</Form.Item>
						</>		
						: ''					
					}				

					<Form.Item
						className="col-6"
						name="logo"
						label="Receipt Logo"
						valuePropName="fileList"
						getValueFromEvent={normFile}
						validateStatus={errors?.logo && 'error'}
						help={errors?.logo?.join('\n')}
						rules={[
							{
								required: false
							}
						]}
						fileList={fileList}
					>
						<Upload name="logo" beforeUpload={() => false} listType="picture" multiple={false}>
							<Button>
								<UploadOutlined /> Click to upload
							</Button>
						</Upload>
					</Form.Item>

					<Form.Item
						className="col-6"
						name="terms"
						label="Terms"
						tooltip={{
							title: 'Receipt Terms and Conditions',
							icon: <InfoCircleOutlined />,
						}}
						validateStatus={errors?.terms && 'error'}
						help={errors?.terms?.join('\n')}
					>
						<TextArea rows="4" />
					</Form.Item>

					<Form.Item
						className="col-6"
						{...tailLayout}
						name="is_active"
						valuePropName="checked"
						validateStatus={errors?.is_active && 'error'}
						help={errors?.is_active?.join('\n')}
					>
						<Checkbox>Is Active</Checkbox>
					</Form.Item>

					<Form.Item
						className="col-6"
						{...tailLayout}>
						{shop ? (
							<Button type="primary" loading={loading} icon={<SaveOutlined />} htmlType="submit">
								Save
							</Button>
						) : (
							<Button type="primary" loading={loading} icon={<StopOutlined />} htmlType="submit">
								Submit
							</Button>
						)}
						<Button htmlType="button" onClick={onReset}>
							Reset
						</Button>
					</Form.Item>
				</Form>
			</div>
			<Row>
				<Col className="gray-5 bg-gradient text-white text-center h3" span={12}>
					Fees And Slabs
				</Col>
				<Col className="gray-5 bg-gradient text-white text-center h3" span={12}>
					<Switch
						style={{minWidth: '133px'}}
						checkedChildren="All Currencies"
						unCheckedChildren="Only active Currencies"
						onChange={e => {
							onShowAll(e);
						}}
					>
					</Switch>
				</Col>
			</Row>
			<EditAgentSlabs key={'slabs_of_shopId_'+shopId} allToShow={allToShow} currencies={currency} startupslabs={startupslabs} loading={isSlabLoading} isSlabLoading={isSlabLoading} StartupStabs={[]} onUpdateCurrencySlabs={onUpdateCurrencySlabs} AgentCurrencySlabs={AgentCurrencySlabs} />
			<Modal okText="Update" confirmLoading={confirmLoading} title="Enter new Limit" visible={edit_limit_visible} onCancel={onEditCancel} onOk={onUpdateShopLimits}>
				<InputNumber onChange={(e) =>setMaxLimit(e)}></InputNumber>
			</Modal>
		</Spin>
	);
};

export default AddNewShop;
