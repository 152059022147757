import { useCallback, useEffect, useState } from 'react';
import shopService from '../services/shop.service';
import useFetchData from '../utils/fetchData';
import { titlize } from '../utils/strings';

export const FILTERS = { SHOP: 'shop', CURRENCY: 'currency', TYPE: 'type' };
export const FILTERS_KEYS = Object.keys(FILTERS);
export const filterOpts = (input, option) => option.props.key.toLowerCase().startsWith(input.toLowerCase());
export const filterOptsShop = (input, option) => option.props.children.toLowerCase().startsWith(input.toLowerCase());

const IDENTITY_FITLER = item => item;
export const FILTER_UTILS = {
	filterByType: type => item => item.is_sell === (type.toLowerCase() === 'sell' ? 1 : 0),

	filterByCurrency: currency => item => item.currency?.code === currency,

	filterByShop: shop => item => item.shop_id === shop
};

export const useFilters = ({ data, filters, filterFunctions = FILTER_UTILS }) => {
	// const [data, setData, isLoading, setLoading] = useFetchData(FeesServics.getFees.bind(FeesServics));
	const [shops, setShops] = useState(filters.includes(FILTERS.SHOP) ? [] : null);
	const [unmounted, setUnmounted] = useState(false);
	const [display, setDisplay] = useState(data);
	const [filtersState, setFilters] = useState(
		filters.reduce((agg, k) => {
			agg[k] = null;
			return agg;
		}, {})
	);

	const setFilterValues = newFilters => {
		setFilters({ ...filters, ...newFilters });
	};
	const fetchShops = async () => {
		let resp = await shopService.getShops(0,0,true);
		let shopResult = resp.data;
		setShops(shopResult);
	};

	const filterData = useCallback(
		filtersState => {
			let result = data;
			Object.keys(filtersState).forEach(filter => {
				let func = filterFunctions[`filterBy${titlize(filter)}`];
				if (!func || filtersState[filter] === null) return;
				console.log(filtersState[filter]);
				result = result.filter(
					filtersState[filter] !== null && filtersState[filter] !== undefined
						? func(filtersState[filter])
						: IDENTITY_FITLER
				);
			});
			return result;
		},
		[filtersState]
	);

	useEffect(() => {
		setDisplay(data);
	}, [data]);

	useEffect(() => {
		setDisplay(filterData(filtersState));
	}, [filtersState]);

	useEffect(() => {
		if (!unmounted) fetchShops();
		return () => setUnmounted(true);
	}, []);

	return {
		display,
		unmounted,
		setUnmounted,
		filterData,
		shops,
		setFilters,
		filters: filtersState,
		setDisplay,
		setFilterValues
	};
};
