import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useEditRow() {
	const location = useLocation();
	const [edited, setEdited] = useState(0);

	useEffect(() => {
		if (location.state) {
			let { id } = location.state;
			console.log(id);
			setEdited(id);
		}
	}, [location]);
	return edited;
}
