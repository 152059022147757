export function isObjectsEqual(obj1, obj2) {
	if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
	for (let key of Object.keys(obj1)) {
		if (obj1[key] && obj1[key].constructor.name === 'Object') {
			if (obj2[key] && obj2[key].constructor.name === 'Object') {
				if (!isObjectsEqual(obj1[key], obj2[key])) return false;
				continue;
			} else return false;
		}
		if (obj1[key] === obj2[key]) continue;
		return false;
	}
	return true;
}

export const nullCoalesc = (...vals) => {
	if (vals.length <= 1) return vals;
	let args = vals.slice(0, -1),
		default_val = vals.slice(-1);

	for (let arg of args) {
		if (arg !== undefined && arg !== null) return arg;
	}
	return default_val;
};

export const transfereObject = (src, dst) => {
	let newObj = {};
	Object.keys(src).forEach(k => {
		newObj[k] = dst[k] || src[k];
	});
	return newObj;
};
