import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Table,  Spin,  Select, Col, Row } from 'antd';
import useFetchData from '../../utils/fetchData';
import StockService from '../../services/stock.service';
import currencies from '../../constants/currencies';
import { FILTERS, FILTER_UTILS, useFilters, filterOpts, filterOptsShop } from '../../hooks/filters.hook';
import { formatDashedNumber } from '../../utils/strings';

const ListStock = props => {
	const [data, setData, isLoading] = useFetchData(StockService.listStock.bind(StockService));
	const { display, shops, setFilters, filters } = useFilters({
		data,
		filters: [FILTERS.CURRENCY, FILTERS.SHOP, 'country'],
		filterFunctions: {
			...FILTER_UTILS,
			filterByShopName: shop => item => item.shopName === shop,
			filterByCurrency: currency => item => item.currencyCode === currency
		}
	});

	const columns = [
		{
			title: 'Amount',
			dataIndex: 'amount',
			sorter: (a, b) => a.amount - b.amount,
			sortDirections: ['descend'],
			render: (amount) => formatDashedNumber(amount)
		},
		{
			title: 'Currency',
			dataIndex: 'currencyName'
		},
		{
			title: 'Currency Code',
			dataIndex: 'currencyCode'
		},
		{
			title: 'Shop',
			dataIndex: 'shopName'
		}
	];
	const filterOpts = (input, option) => option.props.key.toLowerCase().startsWith(input.toLowerCase());

	return (
		<div>
			<h1 className="h3"> List Stocks</h1>
			<div className="pl-2">
				{!isLoading && (
					<>
						<Row className="my-3">
							<Col xs={8}>
								<span>Shop Name: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											shopName: val
										});
									}}
									className="w-75"
									placeholder="Select Shop"
									showSearch
									filterOption={filterOptsShop}
								>
									{shops.map(shop => (
										<Select.Option value={shop.name} key={shop.id}>
											{shop.name}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={8}>
								<span>Currency: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											currency: val
										});
									}}
									className="w-75"
									placeholder="Select Currency"
									showSearch
									filterOption={(input, option) =>
										option.props.key.toLowerCase().startsWith(input.toLowerCase()) ||
										option.props.value.toLowerCase().startsWith(input.toLowerCase())
									}
								>
									{Object.keys(currencies).map(c => (
										<Select.Option value={c} key={currencies[c]}>
											<span>{c}</span>
											<br />
											<small>{currencies[c]}</small>
										</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
					</>
				)}
			</div>
			{/* <Row>
				<Col xs={8}>
					<span>Shop: </span>
					<Select
						allowClear
						onChange={val => {
							if (val) setDisplay(data.filter(stock => stock.shopName === val));
							else setDisplay(data);
							// setFromCurrency(e.target.value);
						}}
						className="w-75"
						placeholder="Select Shop"
						showSearch
						filterOption={filterOpts}
					>
						{shops.map(shop => (
							<Select.Option value={shop.name}>{shop.name}</Select.Option>
						))}
					</Select>
				</Col>
			</Row> */}
			<Spin spinning={isLoading}>
				<Table size="small" columns={columns} dataSource={display} />
			</Spin>
		</div>
	);
};

export default ListStock;
