import React from 'react';
import { Input, Select, Form, Modal, InputNumber, message } from 'antd';
import currencyService from '../../services/currency.service';
import transactionService from '../../services/transaction.service';
import { useState } from 'react';
import { useEffect } from 'react';

const { Option } = Select;
//TODO: make this form dynamic, i.e., accepts the fields as props.
const ReconcilationFormModal = function ({ visible, onOk, onCancel }) {
	const [form] = Form.useForm();
	const [currencies, setCurrencies] = useState([]);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function fetchData() {
			let currencyResult = await currencyService.getCurrencies();
			setCurrencies(currencyResult.data);
		}
		fetchData();
	}, []);

	const onFinish = async () => {
		setErrors({});
		setLoading(true);

		let resp;
		try {
			let amount = parseFloat(form.getFieldValue('amount'));
			let currency = form.getFieldValue('currency');
			resp = await transactionService.getTransactionInfo({
				amount: amount,
				from: amount < 0 ? 'USD' : currency,
				to: amount > 0 ? 'USD' : currency,
				// shop_id: 10,
				// user_id: 1,
				amount_in_usd: false,
				is_reconcilation: true
			});
			if (!resp) throw new Error('Something went wrong!');
			else if (resp.key === 0) throw new Error(resp.message || resp.msg);
			else {
				resp = await transactionService.commitOperation({
					type: amount < 0 ? 'operation' : 'stock',
					type_id: resp.data.id,
					// user_id: 1,
					status: 1,
					is_reconcilation: true
				});
				if (!resp) throw new Error('Something went wrong!');
			}
		} catch (e) {
			message.error(e.message || e.msg);
			console.error(e);
			setLoading(false);

			return;
		}
		setLoading(false);
		message.success('Reconcilation operation done successfully!');
		onCancel && onCancel();
	};

	const onReset = () => {
		setErrors({});
		form.resetFields();
	};

	return (
		<Modal
			title="Add new reconcilation operation"
			visible={visible}
			onCancel={onCancel}
			onOk={onFinish}
			okButtonProps={{ loading: loading }}
			className="ta-modal"
		>
			<Form
				form={form}
				name="control-hooks"
				// onFinish={onFinish}
				onReset={onReset}
				initialValues={{
					amount: '',
					currency_id: ''
					// shop_id: slabData ? slabData.shop_id : null,
					// value: slabData ? slabData.value : null,
					// is_fixed: slabData ? slabData.is_fixed : 1,
					// is_sell: slabData ? slabData.is_sell : 1
				}}
			>
				<Form.Item
					name="amount"
					label="Amount"
					rules={[
						{
							required: true
						}
					]}

					// validateStatus={errors?.boundary && 'error'}
					// help={errors?.boundary?.join('\n')}
				>
					<InputNumber className="w-100" />
				</Form.Item>

				<Form.Item
					name="currency"
					label="Currency"
					rules={[{ required: true, message: 'Please select the Currency!' }]}
					// validateStatus={errors?.currency_id && 'error'}
					// help={errors?.currency_id?.join('\n')}
				>
					<Select
						showSearch
						placeholder="Please select the Currency!"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
					>
						{currencies.map(c => (
							<Option value={c.code}>{c.code}</Option>
						))}
					</Select>
				</Form.Item>

				{/* <Form.Item
							name="value"
							label="Value"
							rules={[
								{
									required: true
								}
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item {...tailLayout} name="is_fixed" valuePropName="checked">
							<Checkbox>Is Fixed</Checkbox>
						</Form.Item>

						<Form.Item {...tailLayout} name="is_sell" valuePropName="checked">
							<Checkbox>Is Sell</Checkbox>
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
							<Button htmlType="button" onClick={onReset}>
								Reset
							</Button>
						</Form.Item> */}
			</Form>
		</Modal>
	);
};
export default ReconcilationFormModal;
