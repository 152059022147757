import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import employeeService from '../../services/employee.service';
import shopService from '../../services/shop.service';
import states from '../../assets/states.json';
import './style.css';

import { Form, Input, Button, Select, Checkbox, DatePicker, Upload, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { makePinCode } from '../../utils/strings';
import { fixAutocomplete } from '../../utils/dom';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

const AddNewEmployee = props => {
	let { id: employeeId } = useParams();
	const [employee, setEmployee] = useState();
	const employeeData = props.location.data || null;
	const [form] = Form.useForm();
	const [shop, setShops] = useState([]);
	const [loading, setLoading] = useState(!!employeeId);
	const [errors, setErrors] = useState({});
	const [unmounted, setUnmounted] = useState(false);

	let history = useHistory();

	let passwordRef = useRef();

	const fetchEmployee = async id => {
		if (id) {
			let resp;
			try {
				resp = await employeeService.getEmployee(id);
				if (!resp.key) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}
			console.log(resp.data);
			delete resp.data.image;

			setEmployee(resp.data);
			form.setFieldsValue({ ...resp.data, password: '' });
			passwordRef.current.input.value = '';
			setLoading(false);
		}
	};

	const fetchShops = async () => {
		let resp = await shopService.getShops(0,0,true);
		let shopResult = resp.data;
		setShops(shopResult);
	};

	useEffect(() => {
		if (!unmounted) fetchShops();
		fixAutocomplete();
		passwordRef.current.input.value = '';

		return () => setUnmounted(true);
	}, []);

	useEffect(() => {
		if (!unmounted) fetchEmployee(employeeId);
	}, [employeeId]);

	const shops = shop.map(shop => {
		return <Option value={shop.id}>{shop.name}</Option>;
	});

	const statesData = states.map(data => {
		return <Option value={data.abbreviation}>{data.name}</Option>;
	});

	const onFinish = async values => {
		setErrors({});
		if (values.image) values.image = values.image[0].originFileObj;
		let fd = new FormData();
		Object.keys(values).forEach(k => values[k] && fd.append(k, values[k]));
		console.log(employee);
		if (employee) {
			try {
				setLoading(true);

				await employeeService.editEmployee(employee.id, fd);
				message.success('employee was edited successfully');
				history.push({
					pathname: '/dashboard/list-employees',
					state: { id: employee.id }
				});
			} catch (e) {
				message.error(e.message || e.msg);
				console.error(e);
				setLoading(false);

				return setErrors(e.errors);
			}
		} else {
			try {
				await employeeService.addNewEmployee(fd);
				message.success('Employee was added Successfully');
				form.resetFields();
			} catch (e) {
				message.error(e.message || e.msg);
				console.error(e);
				setLoading(false);

				return setErrors(e.errors);
			}
		}
		setLoading(false);
	};

	const onReset = () => {
		setErrors({});
		form.resetFields();
	};

	const normFile = e => {
		if (Array.isArray(e)) {
			return e[e.length - 1];
		}
		return e && e.fileList.slice(-1);
	};

	return (
		<Spin spinning={loading}>
			<Fragment>
				{employeeId ? <h3 className="h3">Edit Employee</h3> : <h3 className="h3">Add New Employee</h3>}
				<Form
					{...layout}
					form={form}
					name="control-hooks"
					onFinish={onFinish}
					initialValues={{
						username: employee ? employee.username : '',
						pin_code: employee ? employee.pin_code : makePinCode(4),
						first_name: employee ? employee.first_name : '',
						middle_name: employee ? employee.middle_name : '',
						last_name: employee ? employee.last_name : '',
						is_active: employee ? employee.is_active : 1,
						mobile: employee ? employee.mobile : '',
						shop_id: employee?.shop ? employee.shop.id : '',
						id_number: employee?.id_number ? employee.id_number : '',
						id_type: employee?.id_type ? employee.id_type : '',
						address: employee?.address ? employee.address : '',
						state: employee?.state ? employee.state : '',
						city: employee?.city ? employee.city : ''
						// image: employeeData ? employeeData.image : null
					}}
				>
					<Form.Item
						name="id_number"
						label="ID Number"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.id_number && 'error'}
						help={errors?.id_number?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="id_type"
						label="ID Type"
						hasFeedback
						rules={[{ required: true, message: 'Please select the ID Type!' }]}
						validateStatus={errors?.id_type && 'error'}
						help={errors?.id_type?.join('\n')}
					>
						<Select placeholder="Please select a ID Type">
							<option value="PASSPORT">PASSPORT</option>
							<option value="DRIVING_LICENSE">DRIVING LICENSE</option>
							<option value="STATE_ID">STATE ID</option>
							<option value="INTERNATIONAL_DRIVING_LICENSE">INTERNATIONAL DRIVING LICENSE</option>
							<option value="GOVERNMENT_ID">GOVERNMENT ID</option>
							<option value="ALIEN_ID">ALIEN ID</option>
							<option value="SSN">Social Security Number</option>
						</Select>
					</Form.Item>

					<Form.Item
						name="first_name"
						label="First Name"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.first_name && 'error'}
						help={errors?.first_name?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="middle_name"
						label="Middle Name"
						validateStatus={errors?.middle_name && 'error'}
						help={errors?.middle_name?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="last_name"
						label="Last Name"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.last_name && 'error'}
						help={errors?.last_name?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="email"
						label="Email"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.email && 'error'}
						help={errors?.email?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="username"
						label="Username"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.username && 'error'}
						help={errors?.username?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="password"
						label="Password"
						rules={[
							{
								required: employeeId ? false : true
							}
						]}
						validateStatus={errors?.Password && 'error'}
						help={errors?.Password?.join('\n')}
					>
						<Input.Password ref={passwordRef} />
					</Form.Item>

					<Form.Item
						name="pin_code"
						label="Pin Code"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.pin_code && 'error'}
						help={errors?.pin_code?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="birth_date"
						label="Birth Date"
						validateStatus={errors?.birth_date && 'error'}
						help={errors?.birth_date?.join('\n')}
					>
						<input type="date" className="inputDate" />
					</Form.Item>

					<Form.Item
						name="start_working"
						label="Start Working"
						validateStatus={errors?.start_working && 'error'}
						help={errors?.start_working?.join('\n')}
					>
						<input type="date" className="inputDate" />
					</Form.Item>

					<Form.Item
						name="address"
						label="Address"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.address && 'error'}
						help={errors?.address?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="state"
						label="State"
						rules={[{ required: true, message: 'Please select the State!' }]}
						validateStatus={errors?.state && 'error'}
						help={errors?.state?.join('\n')}
					>
						<Select
							showSearch
							placeholder="Select a state"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().startsWith(input.toLowerCase())
							}
						>
							{statesData}
						</Select>
					</Form.Item>

					<Form.Item
						name="city"
						label="City"
						validateStatus={errors?.city && 'error'}
						help={errors?.city?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="zip"
						label="Zip"
						validateStatus={errors?.zip && 'error'}
						help={errors?.zip?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="mobile"
						label="Mobile"
						rules={[
							{
								required: true
							}
						]}
						validateStatus={errors?.mobile && 'error'}
						help={errors?.mobile?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="telephone"
						label="Telephone"
						validateStatus={errors?.telephone && 'error'}
						help={errors?.telephone?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="add_fees"
						label="Add Fees Slab"
						validateStatus={errors?.add_fees && 'error'}
						help={errors?.add_fees?.join('\n')}
					>
						<Input />
					</Form.Item>

					<Form.Item
						name="shop_id"
						label="Shop"
						rules={[{ required: true, message: 'Please select the Shop!' }]}
						validateStatus={errors?.shop_id && 'error'}
						help={errors?.shop_id?.join('\n')}
					>
						<Select
							showSearch
							placeholder="Please select the Shop!"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().startsWith(input.toLowerCase())
							}
						>
							{shops}
						</Select>
					</Form.Item>

					<Form.Item
						name="image"
						label="Image"
						valuePropName="fileList"
						getValueFromEvent={normFile}
						validateStatus={errors?.image && 'error'}
						help={errors?.image?.join('\n')}
					>
						<Upload
							name="image"
							beforeUpload={() => false}
							listType="picture"
							// fileList={avatar ? [avatar] : []}
							// onChange={onFileUploadChange}
							multiple={false}
						>
							<Button>
								<UploadOutlined /> Click to upload
							</Button>
						</Upload>
					</Form.Item>

					<Form.Item
						{...tailLayout}
						name="is_active"
						valuePropName="checked"
						validateStatus={errors?.is_active && 'error'}
						help={errors?.is_active?.join('\n')}
					>
						<Checkbox>Is Active</Checkbox>
					</Form.Item>

					<Form.Item {...tailLayout}>
						{employeeId ? (
							<Button type="primary" htmlType="submit">
								Edit
							</Button>
						) : (
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						)}
						<Button htmlType="button" onClick={onReset}>
							Reset
						</Button>
					</Form.Item>
				</Form>
			</Fragment>
		</Spin>
	);
};

export default AddNewEmployee;
