import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import 'antd/dist/antd.css';
import { Table, Button, Spin, message, Modal, Col, Row, Input } from 'antd';
import CustomerService from '../../services/customer-service';
import useFetchData from '../../utils/fetchData';
import useEditRow from '../../utils/editRow';
import { useFilters } from '../../hooks/filters.hook';

const isNamesMatch = (name, query) => {
	name = name.toLowerCase().split(/\s+/);
	query = query.toLowerCase().split(/\s+/);
	for (let q of query) {
		for (let word of name) {
			if (word.indexOf(q) !== -1) return true;
		}
	}
	return false;
};
const ListCustomers = props => {
	const [data, setData, isLoading, setLoading] = useFetchData(CustomerService.getCustomers.bind(CustomerService));
	const edited = useEditRow();
	const { display, shops, setFilters, filters } = useFilters({
		data,
		filters: ['name'],
		filterFunctions: {
			filterByName: name => item => isNamesMatch(`${item.first_name} ${item.middle_name} ${item.last_name}`, name)
		}
	});
	const columns = [
		{
			title: 'First Name',
			dataIndex: 'first_name',
			ellipsis: true,
			textWrap: 'word-break',
			fixed: 'left',

			sorter: (a, b) => a.first_name.localeCompare(b.first_name),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'middle name',
			dataIndex: 'middle_name',
			ellipsis: true,
			fixed: 'left',

			filterMultiple: false,
			onFilter: (value, record) => record.address.indexOf(value) === 0,
			sorter: (a, b) => a.middle_name.localeCompare(b.middle_name),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'last name',
			dataIndex: 'last_name',
			ellipsis: true
		},
		{
			title: 'email',
			dataIndex: 'email'
		},
		{
			title: 'birth date',
			dataIndex: 'birth_date',
			ellipsis: true
		},
		{
			title: 'address',
			dataIndex: 'address',
			ellipsis: true
		},
		{
			title: 'city',
			dataIndex: 'city',
			ellipsis: true
		},
		{
			title: 'state',
			dataIndex: 'state',
			ellipsis: true
		},
		{
			title: 'zip',
			dataIndex: 'zip',
			ellipsis: true
		},
		{
			title: 'country',
			dataIndex: 'country',
			ellipsis: true
		},
		{
			title: 'mobile',
			dataIndex: 'mobile'
		},
		{
			title: 'telephone',
			dataIndex: 'telephone',
			ellipsis: true
		},
		{
			title: 'id_type',
			dataIndex: 'id_type'
		},
		{
			title: 'id_number',
			dataIndex: 'id_number',
			ellipsis: true
		},
		{
			title: 'Action',
			key: 'action',
			fixed: 'right',
			ellipsis: true,
			render: (text, record) => (
				<React.Fragment>
					<Button
						danger
						size="small"
						onClick={() => {
							Modal.confirm({
								onOk: deleteRecord.bind(this, record.id),
								title: 'Are you sure you want to deactivate this customer?'
							});
						}}
					>
						Deactivate
					</Button>
					<Button type="default" size="small">
						<Link
							to={{
								pathname: '/dashboard/edit-customer',
								data: record
							}}
						>
							Edit
						</Link>
					</Button>
				</React.Fragment>
			)
		}
	];

	async function deleteRecord(id) {
		let result = await CustomerService.deleteCustomer(id);
		console.log(result);
		if (result.success) {
			let newData = data.filter(record => record.id !== id);
			setData(newData);
			message.success('the shop was deactivated successfully');
		}
	}
	function editRecord(record) {
		console.log(record);
	}

	return (
		<div>
			<h1 className="h3"> List Customers </h1>
			<div className="pl-2">
				{!isLoading && (
					<>
						<Row className="my-3">
							<Col xs={8}>
								<span>Name: </span>
								<Input.Search
									allowClear
									onChange={e => {
										let val = e.target.value;
										setFilters({
											...filters,
											name: val
										});
									}}
									className="w-75"
									placeholder="Search"
								/>
							</Col>
						</Row>
					</>
				)}
			</div>
			<Spin spinning={isLoading}>
				<Table
					size="small"
					columns={columns}
					dataSource={display}
					scroll={{ x: true }}
					rowKey="uid"
					rowClassName={(record, _) => (record.id == edited ? 'table-row-dark' : 'table-row-light')}
				/>
			</Spin>
		</div>
	);
};

export default ListCustomers;
