import React from 'react';
import Transaction from '../containers/CurrencyConversion/CurrencyConversion';

import './app-layout.css';
import { Row, Col, Tooltip } from 'antd';
import { Route } from 'react-router-dom';
import StockTable from '../containers/StockTable/StockTable';
import ReportsListContainer from '../containers/ReportsList/ReportsList';
import TotalsReportContainer from '../containers/ProfitReport/TotalsReportContainer';
import DetailsReportContainer from '../containers/ReportsList/DetailsReportContainer';
import CurrencyTransactionReport from '../containers/CurrencyTransactionReport/CurrencyTransactionReport';
import ConvertCurrencyDiv from '../containers/CurrencyConversion/style';
import Menu from '../components/Menu/Menu';
import PendingOpsTable from '../containers/PendingOpsTable/PendingOpsTable';
import { useAuth } from '../contexts/authentication.context';

export default function AppLayout(props) {
	let { user,setUnAuthenticated } = useAuth();

	return (
		<div className="app-container">
			<ConvertCurrencyDiv style={{ width: '90%', margin: '0 auto', marginTop: '10px' }} key={Date.now()}>
				<Row gutter={8}>
					<Col xs={24}>
						<Row type="flex" justify="space-between" className="agent-info">
							<Col span={6}>
								<img alt="" src={require('../assets/user.svg').default} height="25px" width="25px" />
								{user.full_name}
								<Tooltip title="Sign out">
									<img
										 alt=""
										src={require('../assets/logoff.svg').default}
										style={{height:"19px",width:"19px",cursor:'pointer',marginLeft:'13px'}}									
										onClick={e => {
											e.preventDefault();
											setUnAuthenticated();
											window.localStorage.clear();
											window.location.reload();
										}}
									/>
								</Tooltip>
							</Col>
							<Col span={6}><img alt="" src={require('../assets/agent.svg').default} height="25px" width="25px" /> { user.shop_info.shop_name }</Col>
							<Col span={6}><img alt="" src={require('../assets/location.svg').default} height="25px" width="25px" /> { user.shop_info.shop_address}</Col>
						</Row>						
					</Col>
					<Col xs={6}>
						<Menu />
					</Col>
					<Col
						xs={18}
						style={{
							backgroundColor: '#ffffff',
							borderRadius: '15px',
							overflowX: 'hidden'
						}}
					>
						<Route
							path={`${process.env.PUBLIC_URL}/`}
							exact
							render={rprops => <Transaction state={props.app} {...rprops} user={user}/>}
						/>
						<Route
							path={`${process.env.PUBLIC_URL}/transaction`}
							exact
							render={rprops => <Transaction state={props.app} {...rprops} user={user} />}
						/>
						<Route
							path={`${process.env.PUBLIC_URL}/stocks`}
							exact
							render={rprops => {
								return <StockTable state={props.app} {...rprops} />;
							}}
						/>

						<Route
							path={`${process.env.PUBLIC_URL}/pending-ops`}
							exact
							render={rprops => {
								return <PendingOpsTable state={props.app} {...rprops} />;
							}}
						/>
						<Route
							path={`${process.env.PUBLIC_URL}/reports`}
							exact
							render={rprops => {
								return <ReportsListContainer state={props.app} {...rprops} />;
							}}
						/>
						<Route
							path={`${process.env.PUBLIC_URL}/total-reports`}
							exact
							render={rprops => {
								rprops['shop_page'] = true;
								rprops['shop_id'] = user.shop_id;
								return <TotalsReportContainer state={props.app} {...rprops} />;
							}}
						/>
						<Route
							path={`${process.env.PUBLIC_URL}/details-reports`}
							exact
							render={() => {
								return <DetailsReportContainer/>;
							}}
						/>
						<Route
							path={`${process.env.PUBLIC_URL}/ctr`}
							exact
							render={rprops => {
								return <CurrencyTransactionReport state={props.app} {...rprops} />;
							}}
						/>
						{/* <Route
							path="/invoices"
							exact
							render={rprops => {
								return <InvoicesListContainer state={props.app} {...rprops} />;
							}}
						/> */}
					</Col>
				</Row>
			</ConvertCurrencyDiv>
		</div>
	);
}
