import React from 'react';

import { Table, message, Spin, Row, Col, DatePicker, Button, Select, Space  } from 'antd';
import { fixAutocomplete } from '../../utils/dom';
import reportsService from '../../services/reports.service';
import ReportsList from '../../components/ReportsList/ReportsList';
import currencies from '../../constants/currencies';
import shopService from '../../services/shop.service';
import { titlize } from '../../utils/strings';

const filterOpts = (input, option) => option.props.key.toLowerCase().startsWith(input.toLowerCase());
const filterOptsShop = (input, option) => option.props.children.toLowerCase().startsWith(input.toLowerCase());

export default class ReportsListContainer extends React.Component {
	state = {
		data: {},
		display: {},
		shops: [],
		loading: false,
		ready: false,
		filter: {
			fromDate: '',
			toDate: ''
		}
	};

	async componentDidMount() {
		if (!this.props.dashboard) return;
		this.setState({ loading: true });
		let shops;
		try {
			let resp = await shopService.getShops(0,0,this.props.dashboard);
			if (!resp) throw new Error('Something went wrong');
			shops = resp.data;
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}

		this.setState({
			shops,
			loading: false
		});
	}

	componentDidUpdate() {
		fixAutocomplete();
	}

	onSearch = async () => {
		this.setState({ loading: true, ready: false });
		let data;
		let { fromDate, toDate } = this.state.filter;

		try {
			data = await reportsService.getReports({
				from: fromDate ? fromDate.format('YYYY-MM-DD') : undefined,
				to: toDate ? toDate.format('YYYY-MM-DD') : undefined,
				withInvoices: 1,
				admin: this.props.dashboard
			});
			if (!data.success) throw new Error(data.message);
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}
		this.setState({
			data: data.data,
			display: data.data,
			ready: true,
			loading: false,
			filter: {
				type: null,
				currency: null,
				shop: null
			}
		});
	};

	filterByType = type => report => report.is_sell === (type.toLowerCase() === 'sell' ? 1 : 0);

	filterByCurrency = currency => report => report.currency.code === currency;

	filterByShop = shop => report => report.shop_id === shop;

	filterReports = filters => {
		return Object.keys(this.state.data)
			.sort()
			.reduce((agg, date) => {
				let dayInvoices = this.state.data[date];
				Object.keys(filters).forEach(filter => {
					let func = this[`filterBy${titlize(filter)}`];
					if (!func || filters[filter] === null) return dayInvoices;
					func = func.bind(this);
					dayInvoices = dayInvoices.filter(func(filters[filter]));
				});
				return { ...agg, [date]: dayInvoices };
			}, {});
	};

	render() {

		let onChange = (dates, dateStrings) =>{
			console.log('From: ', dates[0], ', to: ', dates[1]);
			console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);

			if (!dateStrings[0] || !dateStrings[1]) return this.setState({ ready: false });
			this.setState({ filter: { ...this.state.filter, toDate: dateStrings[1], fromDate: dateStrings[0] } });
		}
		return (
			<Spin spinning={this.state.loading}>
				<Row className="p-2">
					<span className="mr-5">
						<span style={{ fontWeight: '500' }}> From </span>
						<DatePicker						
							value={this.state.filter.fromDate}
							selected={new Date()}
							format="M/D/YYYY"
							onChange={val => {
								if (!val) return this.setState({ ready: false });
								let toDate = this.state.filter.toDate;
								if (toDate && toDate <= val) {
									message.error('From date must be before to date.');
									val = '';
								}
								this.setState({ filter: { ...this.state.filter, fromDate: val } });
							}}
						/>
					</span>
					<span className="mr-5">
						<span style={{ fontWeight: '500' }}> To </span>
						<DatePicker
							value={this.state.filter.toDate}
							format="M/D/YYYY"
							onChange={val => {
								if (!val) return this.setState({ ready: false });
								let fromDate = this.state.filter.fromDate;
								if (fromDate && val <= fromDate) {
									message.error('To date must be after from date.');
									val = '';
								}
								this.setState({ filter: { ...this.state.filter, toDate: val } });
							}}
						/>
					</span>
					<span className="mr-5">
						<Button
							type="primary"
							onClick={this.onSearch}
							disabled={!(this.state.filter.toDate && this.state.filter.fromDate)}
						>
							Search
						</Button>
					</span>
				</Row>
				{this.state.ready && (
					<Row className="my-3">
						<Col xs={8}>
							<span>Type: </span>
							<Select
								placeholder="Select report type"
								allowClear
								className="w-75"
								onChange={val => {
									if (val === 0 || !val) {
										this.setState({ display: this.state.data });
									} else
										this.setState({
											display: this.filterReports({
												...this.state.filter,
												type: val === 1 ? 'Sell' : 'Buy'
											}),
											filter: {
												...this.state.filter,
												type: val === 1 ? 'Sell' : 'Buy'
											}
										});
								}}
							>
								<Select.Option value={0}>All</Select.Option>
								<Select.Option value={1}>Sell</Select.Option>
								<Select.Option value={2}>Buy</Select.Option>
							</Select>
						</Col>
						<Col xs={8}>
							<span>Currency: </span>
							<Select
								allowClear
								onChange={val => {
									this.setState({
										display: val
											? this.filterReports({
													...this.state.filter,
													currency: val
											  })
											: this.state.data,
										filter: {
											...this.state.filter,
											currency: val
										}
									});
									// setFromCurrency(e.target.value);
								}}
								className="w-75"
								placeholder="Select Currency"
								showSearch
								filterOption={filterOpts}
							>
								{Object.keys(currencies).map(c => (
									<Select.Option value={c} key={currencies[c]}>
										<span>{c}</span>
									</Select.Option>
								))}
							</Select>
						</Col>
						{this.props.dashboard && (
							<Col xs={8}>
								<span>Shop: </span>
								<Select
									allowClear
									onChange={val => {
										this.setState({
											display: val
												? this.filterReports({
														...this.state.filter,
														shop: val
												  })
												: this.state.data,
											filter: {
												...this.state.filter,
												shop: val
											}
										});
										// setFromCurrency(e.target.value);
									}}
									className="w-75"
									placeholder="Select Shop"
									showSearch
									filterOption={filterOptsShop}
								>
									{this.state.shops.map(shop => (
										<Select.Option value={shop.id} key={shop.id}>
											{shop.name}
										</Select.Option>
									))}
								</Select>
							</Col>
						)}
						{/* <ComponentGuard
							permissions={[permissions.shopsReadAll, permissions.shopsReadHierarchy]}
						></ComponentGuard> */}
					</Row>
				)}
				<div
					style={{
						minHeight: '500px',
						textAlign: 'center'
					}}
				>
					{this.state.ready ? (
						<ReportsList showShopName={this.props.dashboard} reports={this.state.display} />
					) : (
						<p style={{ fontSize: '18px', fontWeight: '700', padding: '1rem 0' }}>
							Please select time range
						</p>
					)}
				</div>
			</Spin>
		);
	}
}
