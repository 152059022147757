import React, { useState } from 'react';
import { Modal, Tooltip } from 'antd';
import {PauseCircleOutlined,StopOutlined,FileSyncOutlined} from '@ant-design/icons';
import transactionService from '../../services/transaction.service';

export default function ReportAction(reportObjeck) {

	let [show_reverse, setReverseVisible] = useState(false);
	let [confirmReverse, setReverseConfirm] = useState(false);
    let [selectedTransaction, setSelectedTransaction] = useState();
    let [reverseNote, setReverseNote] = useState('');
    
	const viewReversal = transactionRow => () => {
		console.log('list', transactionRow);
		if (transactionRow.trx_type == undefined)
		{
			setReverseVisible(true)
			setSelectedTransaction(transactionRow)
		}
	};

	const handleReverse = async () => {
		setReverseConfirm(true);
		let resp;
		try {
			const data = {
				request_info:
				{
					trx_id : selectedTransaction.id,
					trx_type: selectedTransaction.is_sell,
					note: btoa(reverseNote)
				}
			}
			resp = await transactionService.reverseTransaction(data);
            selectedTransaction.trx_type = selectedTransaction.is_sell;
            setReverseConfirm(false);
			if (!resp || !resp.key) throw new Error('Something went wrong!');
		} catch (e) {
			Modal.error({
				title: "Couldn't reverse the transaction!",
				content: e.message
			});
			setReverseConfirm(false);
		}
    }
    
    let IconToRetuen = ''
    if(reportObjeck.report.solver_id !=undefined)
        IconToRetuen =
            <Tooltip placement="right" title="Transaction Reversed">            
                <StopOutlined  style={{ fontSize: '19px', color: '#f40000',cursor:'no-drop' }}/>
            </Tooltip>
    else if (reportObjeck.report.trx_type !=undefined)
        IconToRetuen =
            <Tooltip placement="right" title="Reverse Requested">
                <PauseCircleOutlined  style={{ fontSize: '19px', color: '#696969',background: '#f2ff00',cursor:'help',borderRadius: '50%' }} />
            </Tooltip>
    else if (parseInt(reportObjeck.report.days) < 15)
        IconToRetuen =
            <>
                <Tooltip placement="right" title="Request Transaction Reversal">
                    <FileSyncOutlined style={{ fontSize: '19px', color: '#07cd02',cursor:'pointer' }}  onClick={viewReversal(reportObjeck.report)}></FileSyncOutlined>
                </Tooltip>
                <Modal
                        title="Confirm Reversing this Transaction"
                        visible={show_reverse}
                        onOk={handleReverse}
                        confirmLoading={confirmReverse}
                        onCancel={
                            () => {
                                setReverseVisible(false);
                                setSelectedTransaction(null);
                            }						
                        }
                        >
                        <ul>
                            <li> <span>Currency </span> : <span> {selectedTransaction ? selectedTransaction.currency.code : '' }</span> </li>
                            <li> <span>USD Amount </span> : <span> {selectedTransaction ? selectedTransaction.usd_amount : '' }</span> </li>
                            <li> <span>Foreign Amount </span> : <span> {selectedTransaction ? selectedTransaction.foreign_amount : '' }</span> </li>
                    <li>
                        <span>Reverse Note </span> :
                        <input
                                name="txtReverseNote"
                                onChange={
									function(e){
										setReverseNote(e.target.value)
                                }}
                        />
                    </li>
                        </ul>
                </Modal>
            </>
     
    return (IconToRetuen);
}