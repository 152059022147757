import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import customerService from '../../services/customer-service';
import shopService from '../../services/shop.service';
import states from '../../assets/states.json';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import idTypes from '../../constants/id_types';
import Countries from '../../constants/country_states';
import { Form, Input, Button, Select, Checkbox, Upload, message } from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const dateFormat = 'MM/DD/YYYY';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

const EditCustomer = props => {
	const customerData = props.location.data || null;
	const [form] = Form.useForm();
	const [shop, shopData] = useState([]);
	const [errors, setErrors] = useState({});
	const [selectedCountry, setCountry] = useState(customerData?.country);
	const [issuingCountry, setIssuingCountry] = useState(customerData?.country);
	const [idIssuingPlaceVisible, showIdIssuingPlace] = useState(customerData?.id_issuing_place || false);
	let history = useHistory();

	useEffect(() => {
		async function fetchData() {
			let resp = await shopService.getShops(0,0,true);
			let shopResult = resp.data;
			shopData(shopResult);
		}
		fetchData();
	}, []);
	if (!customerData) {
		return <Redirect to="/dashboard/list-customers" />;
	}

	// const shops = shop.map(shop => {
	// 	return <Option value={shop.id}>{shop.name}</Option>;
	// });

	// const statesData = states.map(data => {
	// 	return <Option value={data.abbreviation}>{data.name}</Option>;
	// });

	const onFinish = async values => {
		setErrors({});
		if (customerData) {
			values = {
				...values,
				birth_date: values.birth_date.format('YYYY-MM-DD'),
				id_expiry_date: values.id_expiry_date.format('YYYY-MM-DD'),
				id_start_date: values.id_start_date.format('YYYY-MM-DD')
			};
			try {
				await customerService.editCustomer(customerData.id, values);
				message.success('customer was edited successfully');
				history.push({
					pathname: '/dashboard/list-customers',
					state: { id: customerData.id }
				});
			} catch (e) {
				message.error(e.message);
				console.error(e);
				return setErrors(e.errors);
			}
		}
	};

	const onReset = () => {
		setErrors({});
		form.resetFields();
	};

	return (
		<Fragment>
			<h3 className="h3">Edit Customer</h3>
			<Form
				{...layout}
				form={form}
				name="control-hooks"
				onFinish={onFinish}
				initialValues={{
					id_number: customerData ? customerData.id_number : '',
					id_type: customerData ? customerData.id_type : '',
					id_issuing_country: customerData ? customerData.id_issuing_country : '',
					id_issuing_place: customerData ? customerData.id_issuing_place : '',
					first_name: customerData ? customerData.first_name : '',
					middle_name: customerData ? customerData.middle_name : '',
					last_name: customerData ? customerData.last_name : '',
					email: customerData ? customerData.email : '',
					address: customerData ? customerData.address : '',
					country: customerData ? customerData.country : '',
					state: customerData ? customerData.state : '',
					city: customerData ? customerData.city : '',
					zip: customerData ? customerData.zip : '',
					mobile: customerData ? customerData.mobile : '',
					telephone: customerData ? customerData.telephone : '',
					id_expiry_date: customerData?.id_expiry_date
						? moment(moment(customerData.id_expiry_date).format(dateFormat))
						: '',
					id_start_date: customerData?.id_start_date
						? moment(moment(customerData.id_start_date).format(dateFormat))
						: '',
					birth_date: customerData?.birth_date
						? moment(moment(customerData.birth_date).format(dateFormat))
						: ''
				}}
				onValuesChange={(changed, all) => {
					if (changed.country) {
						all = { ...all, city: '' };
						setCountry(changed.country);
					}
					if (changed.id_issuing_country) {
						all = { ...all, id_issuing_place: '' };
						setIssuingCountry(changed.id_issuing_country);
					}
					if (changed.id_type) {
						if (['DRIVING_LICENSE', 'STATE_ID'].indexOf(changed.id_type) >= 0) showIdIssuingPlace(true);
						else showIdIssuingPlace(false);
					}
					form.setFieldsValue(all);
					// setDirty(true);
				}}
			>
				<Form.Item
					name="first_name"
					label="First Name"
					rules={[
						{
							required: true,
							message: 'First name is required'
						}
					]}
					validateStatus={errors?.first_name && 'error'}
					help={errors?.first_name?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="middle_name"
					label="Middle Name"
					validateStatus={errors?.middle_name && 'error'}
					help={errors?.middle_name?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="last_name"
					label="Last Name"
					rules={[
						{
							required: true,
							message: 'Last name is required'
						}
					]}
					validateStatus={errors?.last_name && 'error'}
					help={errors?.last_name?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="email"
					label="Email"
					rules={[
						{
							required: true,
							message: 'Email is required'
						},
						{ type: 'email', message: 'Not a valid email' }
					]}
					validateStatus={errors?.email && 'error'}
					help={errors?.email?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Day of Birth"
					name="birth_date"
					rules={[{ required: true, message: 'Please enter customer birth date' }]}
					validateStatus={errors.birth_date ? 'error' : null}
					help={errors.birth_date ? errors.birth_date.map(err => <p>{err}</p>) : null}
				>
					<DatePicker
						format={dateFormat}
						className="form-control"
						placeholder="Date of Birth"
						style={{ borderRadius: '15px', padding: '10px' }}
					/>
				</Form.Item>
				{/* 
				<Form.Item name="password" label="Password">
					<Input.Password />
				</Form.Item> */}

				<Form.Item
					name="address"
					label="Address"
					rules={[{ required: true, message: 'Please enter customer address' }]}
					validateStatus={errors?.address && 'error'}
					help={errors?.address?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Country"
					name="country"
					rules={[{ required: true, message: 'Please enter customer country' }]}
					validateStatus={errors.country ? 'error' : null}
					help={errors.country ? errors.country.map(err => <p>{err}</p>) : null}
				>
					<Select
						placeholder="Country"
						showSearch
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{Object.keys(Countries).map(c => (
							<Select.Option value={c} key={c}>
								{c}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					label="State"
					name="state"
					rules={[{ required: true, message: 'Please enter customer state' }]}
					validateStatus={errors.state ? 'error' : null}
					help={errors.state ? errors.state.map(err => <p>{err}</p>) : null}
				>
					<Select
						className="rounded w-100"
						showSearch
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{selectedCountry &&
							Countries[selectedCountry].map(s => (
								<Select.Option value={s} key={s}>
									{s}
								</Select.Option>
							))}
					</Select>
				</Form.Item>

				<Form.Item
					name="city"
					label="City"
					rules={[{ required: true, message: 'Please enter customer city' }]}
					validateStatus={errors?.city && 'error'}
					help={errors?.city?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Zip"
					name="zip"
					rules={[{ required: true, message: 'Please enter customer zip' }]}
					validateStatus={errors.zip ? 'error' : null}
					help={errors.zip ? errors.zip.map(err => <p>{err}</p>) : null}
				>
					<Input placeholder="Zip" />
				</Form.Item>

				<Form.Item
					name="mobile"
					label="Mobile"
					rules={[
						{
							required: true,
							message: 'Mobile is required'
						}
					]}
					validateStatus={errors?.mobile && 'error'}
					help={errors?.mobile?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="telephone"
					label="Telephone"
					validateStatus={errors?.telephone && 'error'}
					help={errors?.telephone?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="id_type"
					label="ID Type"
					hasFeedback
					rules={[{ required: true, message: 'Please select the ID Type!' }]}
					validateStatus={errors?.id_type && 'error'}
					help={errors?.id_type?.join('\n')}
				>
					<Select
						showSearch
						filterOption={(input, option) =>
							option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						size="middle"
					>
						{idTypes.map(type => (
							<Select.Option value={type} key={type}>
								{type}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					name="id_number"
					label="ID Number"
					rules={[{ required: true, message: 'Please enter customer id number' }]}
					validateStatus={errors?.id_number && 'error'}
					help={errors?.id_number?.join('\n')}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="ID Issuing Country"
					name="id_issuing_country"
					rules={[{ required: true, message: 'Please enter customer id issuing country' }]}
					validateStatus={errors.id_issuing_country ? 'error' : null}
					help={errors.id_issuing_country ? errors.id_issuing_country.map(err => <p>{err}</p>) : null}
				>
					<Select
						placeholder="ID Issuing Country"
						showSearch
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{Object.keys(Countries).map(c => (
							<Select.Option value={c} key={c}>
								{c}
							</Select.Option>
						))}
					</Select>
					{/* <Input className="form-control col-sm-12" placeholder="ID Issuing Country" /> */}
				</Form.Item>

				{idIssuingPlaceVisible && (
					<Form.Item
						label="ID Issuing Place"
						name="id_issuing_place"
						rules={[{ required: true, message: 'Please enter customer id issuing place' }]}
						validateStatus={errors.id_issuing_place ? 'error' : null}
						help={errors.id_issuing_place ? errors.id_issuing_place.map(err => <p>{err}</p>) : null}
					>
						<Select
							className="rounded w-100"
							showSearch
							filterOption={(input, option) =>
								option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							placeholder="ID Issuing Place"
						>
							{issuingCountry &&
								Countries[issuingCountry].map(s => (
									<Select.Option value={s} key={s}>
										{s}
									</Select.Option>
								))}
						</Select>
						{/* <Input placeholder="ID Issuing Place" /> */}
					</Form.Item>
				)}
				<Form.Item
					label="ID Start Date"
					name="id_start_date"
					rules={[{ required: true, message: 'Please enter customer id start date' }]}
					validateStatus={errors.id_start_date ? 'error' : null}
					help={errors.id_start_date ? errors.id_start_date.map(err => <p>{err}</p>) : null}
				>
					<DatePicker
						format={dateFormat}
						className="form-control col-sm-12"
						placeholder="ID Start Date"
						style={{ borderRadius: '15px', padding: '10px' }}
						tabIndex={1}
					/>
				</Form.Item>
				<Form.Item
					label="ID Expiry Date"
					name="id_expiry_date"
					rules={[{ required: true, message: 'Please enter customer id expiry date' }]}
					validateStatus={errors.id_expiry_date ? 'error' : null}
					help={errors.id_expiry_date ? errors.id_expiry_date.map(err => <p>{err}</p>) : null}
				>
					<DatePicker
						format={dateFormat}
						className="form-control col-sm-12"
						placeholder="ID Expiry Date"
						style={{ borderRadius: '15px', padding: '10px' }}
						tabIndex={2}
					/>
				</Form.Item>
				{/* <Form.Item
					{...tailLayout}
					name="is_active"
					valuePropName="checked"
					validateStatus={errors?.is_active && 'error'}
					help={errors?.is_active?.join('\n')}
				>
					<Checkbox>Is Active</Checkbox>
				</Form.Item> */}

				<Form.Item {...tailLayout}>
					{customerData ? (
						<Button type="primary" htmlType="submit">
							Edit
						</Button>
					) : (
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					)}
					<Button htmlType="button" onClick={onReset}>
						Reset
					</Button>
				</Form.Item>
			</Form>
		</Fragment>
	);
};

export default EditCustomer;
