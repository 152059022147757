import styled from 'styled-components';

import usernameImage from '../../assets/username.svg';
import pinCode from '../../assets/pinCode.svg';
import password from '../../assets/password.svg';

export const loginDiv = styled.div`
	background: rgba(0, 0, 0, 0.1);
	padding: 4% 0;
	border-radius: 27px;
	& .login {
		background-color: #fff;
		padding: 4% 15%;
		border-radius: 40px;
		width: 80%;
		margin: 0 auto;
	}
	& .loginForm {
		width: 80%;
		margin: 0 auto;
		/* background-color: #35659f; */
		background-color: #073c7b;
		text-align: center;
		padding: 4%;
		border-radius: 20px;
	}
	& h3 {
		font-size: 22px;
		color: #fff;
		margin-bottom: 30px;
	}
	& h3 img {
		width: 34px;
	}
	& .ant-input-password.ant-input-affix-wrapper {
		max-height: none;
		padding-right: 20px !important;
	}
	& .ant-input-password.ant-input-affix-wrapper input {
		height: 48px;
	}
	& input,
	& .ant-input-password.ant-input-affix-wrapper {
		border-radius: 25px;
		/* color: #919191; */
		padding: 0 0 0 40px;
		color: #a1a1a1;
		font-size: 18px;
		height: 50px;
	}
	& #username {
		background-image: url(${usernameImage});
		background-size: 30px 30px;
		background-position: 8px 8px;
		background-repeat: no-repeat;
	}
	& .ant-input-password.ant-input-affix-wrapper {
		background-image: url(${password});
		background-size: 30px 30px;
		background-position: 8px 8px;
		background-repeat: no-repeat;
	}
	& #pin_code {
		background-image: url(${pinCode});
		background-size: 23px 23px;
		background-position: 8px 10px;
		background-repeat: no-repeat;
	}
	& button {
		width: 56%;
		border: 0;
		outline: 0;
		background-color: #62ba46;
		color: #fff;
		font-weight: 500;
		font-size: 22px;
		padding: 5px 0px;
		border-radius: 26px;
		margin: 2% 0;
		height: 50px;
	}
`;
