import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Table, Button, Spin, message, Modal, Tag, Select, List, Col, Row, Input } from 'antd';
import useFetchData from '../../utils/fetchData';
import useEditRow from '../../utils/editRow';
import { useHistory } from 'react-router-dom';
import EmployeeService from '../../services/employee.service';
import roleService from '../../services/role.service';
import employeeService from '../../services/employee.service';
import { filterOptsShop, FILTERS, FILTER_UTILS, useFilters } from '../../hooks/filters.hook';

const SelectRoleModal = ({ visible, onCancel, onOk, roles, selectedUser }) => {
	const [selectedRole, setSelectedRole] = useState(null);
	const onSubmit = async () => {
		// let selectedRole = roleRef.current;
		let user = selectedUser;

		try {
			var resp = await employeeService.changeEmployeeRole(user.id, selectedRole);
			if (resp.key === 0) throw new Error(resp.msg);
		} catch (e) {
			message.error(e.message);
		}
		onOk && onOk(selectedRole);
		setSelectedRole(null);

		return;
	};
	return (
		<Modal okText="Update" title="Enter new role" visible={visible} onCancel={onCancel} onOk={onSubmit}>
			<Select
				style={{ width: '100%' }}
				onChange={role => setSelectedRole(role)}
				allowClear
				filterOption={(value, option) => option.children.indexOf(value.toLowerCase()) > -1}
				defaultValue={selectedUser.role ? selectedUser.role.id : undefined}
			>
				{roles.map(r => (
					<Select.Option value={r.id}>{r.name}</Select.Option>
				))}
			</Select>
		</Modal>
	);
};

const ListEmployees = props => {
	const [data, setData, isLoading, setLoading] = useFetchData(EmployeeService.getEmployees.bind(EmployeeService));
	const [roles] = useFetchData(roleService.getRoles.bind(roleService));
	const [r_p_visible, setPermissionVisible] = useState(false);
	const [confirmLoading, setLoadingPermission] = useState(true);
	const [PermissionList, setPermission] = useState(['Loading...']);
	const [selectedUser, setSelectedUser] = useState(null);
	const [errors, setErrors] = useState({});
	const edited = useEditRow();
	const history = useHistory();
	const { display, shops, setFilters, filters } = useFilters({
		data,
		filters: [FILTERS.CURRENCY, FILTERS.SHOP, 'isActive', 'role'],
		filterFunctions: {
			...FILTER_UTILS,
			filterByName: name => item => item.full_name.toLowerCase().indexOf(name.toLowerCase()) !== -1,
			filterByShop: shop => item => item.shop?.name === shop,
			filterByRole: role => item => item.role?.name === role,
			filterByIsActive: active => item => item.is_active === (active.toLowerCase() === 'active' ? 1 : 0)
		}
	});
	const fetchRole = async id => {
		if (id) {
			setLoadingPermission(true);
			setPermissionVisible(true);
			try {
				var resp = await roleService.getRole(id);
				fetchPermissions(resp);
				if (!resp.key) throw new Error('There is an error please try again!');
			} catch (e) {
				setPermissionVisible(false);
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}
		}
	};

	const fetchPermissions = permissionids => {
		let PermissionList = [''];
		try {
			setLoadingPermission(false);

			permissionids.data.allPermissions.forEach(element => {
				if (permissionids.data.permissions.includes(element.id)) PermissionList.push(element.display_name);
			});
		} catch (e) {
			message.error(e.message);
			console.error(e);
			setErrors(e.errors || {});
			return;
		}
		setPermission(PermissionList);
	};

	const columns = [
		{
			title: 'Name',
			dataIndex: 'full_name',

			sorter: (a, b) => a.full_name.localeCompare(b.full_name),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'Email',
			dataIndex: 'email',
			defaultSortOrder: 'descend',
			sorter: (a, b) => a.email.localeCompare(b.email),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'Mobile',
			dataIndex: 'mobile',

			filterMultiple: false,
			sorter: (a, b) => a.mobile - b.mobile,
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'Join Date',
			dataIndex: 'start_working'
		},
		{
			title: 'Shop',
			dataIndex: 'shop',
			render: value => {
				if (!value) {
					return <Tag color="geekblue"> NA </Tag>;
				}
				return <Tag color="geekblue">{value.name}</Tag>;
				// return <h3 className="h3">{value && value.name}</h3>
			}
		},
		{
			title: 'Role',
			dataIndex: 'role',
			render: (value, record) => {
				if (!value) {
					return <Tag color="gray">General User</Tag>;
				}
				return (
					<Button
						size="small"
						onClick={() => {
							fetchRole(record.role.id);
						}}
						color="geekblue"
					>
						{value.name}
					</Button>
				);
				// return <h3 className="h3">{value && value.name}</h3>
			}
		},
		{
			title: 'Action',
			key: 'action',
			fixed: 'right',
			render: (text, record) => (
				<React.Fragment>
					<Button
						danger={record.is_active}
						type={!record.is_active ? 'primary' : 'ghost'}
						style={{ width: '90px' }}
						size="small"
						onClick={() => {
							Modal.confirm({
								onOk: deleteRecord.bind(this, record.id),
								title: record.is_active
									? 'Are you sure you want to deactivate this employee?'
									: 'Are you sure you want to activate this employee?'
							});
						}}
					>
						{record.is_active ? 'Deactivate' : 'Activate'}
					</Button>
					<Button
						type="primary"
						style={{ width: '90px' }}
						size="small"
						onClick={() => setSelectedUser(record)}
					>
						Attach Role
					</Button>
					<Button
						type="default"
						size="small"
						onClick={() => history.push('/dashboard/edit-employee/' + record.id)}
					>
						Edit
					</Button>
					{/* <Button size="small">
						<Link
							to={{
								pathname: '/dashboard/edit-employee',
								data: record
							}}
						>
							Edit
						</Link>
					</Button> */}
				</React.Fragment>
			)
		}
	];

	async function deleteRecord(id) {
		let result = await EmployeeService.toggleActive(id);
		if (result.key === 1) {
			let newData = data.map(record => {
				if (record.id === id) {
					record.is_active ^= 1;
				}
				return record;
			});
			setData(newData);
			message.success('the employee was deactivated successfully');
		}
	}

	const onOk = role => {
		setData(
			data.map(item => {
				if (item.id === selectedUser.id) item.role = roles.find(r => r.id === role);
				return item;
			})
		);
		setSelectedUser(null);
	};

	const onCancel = () => {
		setSelectedUser(null);
	};

	const handleCancel = () => {
		setPermissionVisible(false);
	};

	const handleOk = () => {
		setPermission(['']);
		setPermissionVisible(false);
	};

	return (
		<div>
			<h1 className="h3"> List Employees </h1>
			<div className="pl-2">
				{!isLoading && (
					<>
						<Row className="my-3">
							<Col xs={8}>
								<span>Name: </span>
								<Input.Search
									allowClear
									onChange={e => {
										let val = e.target.value;
										setFilters({
											...filters,
											name: val
										});
									}}
									className="w-75"
									placeholder="Search"
								/>
							</Col>
							<Col xs={8}>
								<span>Shop: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											shop: val
										});
									}}
									className="w-75"
									placeholder="Select Shop"
									showSearch
									filterOption={filterOptsShop}
								>
									{shops.map(shop => (
										<Select.Option value={shop.id} key={shop.id}>
											{shop.name}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={8}>
								<span>Active: </span>
								<Select
									placeholder="Select report type"
									allowClear
									className="w-75"
									onChange={val => {
										setFilters({
											...filters,
											isActive:
												val === 0 || val === undefined
													? null
													: val === 1
													? 'Active'
													: 'Inactive'
										});
									}}
								>
									<Select.Option value={0}>All</Select.Option>
									<Select.Option value={1}>Active</Select.Option>
									<Select.Option value={2}>Inactive</Select.Option>
								</Select>
							</Col>
						</Row>
						<Row className="my-3">
							<Col xs={8}>
								<span>Role: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											role: val
										});
									}}
									className="w-75"
									placeholder="Select Role"
									showSearch
									filterOption={filterOptsShop}
								>
									{roles.map(role => (
										<Select.Option value={role.name} key={role.id}>
											{role.name}
										</Select.Option>
									))}
								</Select>
							</Col>
							{/* <Col xs={8}>
								<span>State: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											parentShop: val
										});
									}}
									className="w-75"
									placeholder="Select State"
									showSearch
									filterOption={filterOptsShop}
									disabled={!filters.country}
								>
									{country_states[filters.country]?.map(s => (
										<Select.Option value={s} key={s}>
											{s}
										</Select.Option>
									))}
								</Select>
							</Col> */}
						</Row>
					</>
				)}
			</div>
			<Spin spinning={isLoading}>
				<Table
					size="small"
					columns={columns}
					dataSource={display}
					rowClassName={(record, _) => (record.id == edited ? 'table-row-dark' : 'table-row-light')}
				/>
			</Spin>
			{selectedUser && (
				<SelectRoleModal
					roles={roles}
					visible={!!selectedUser}
					selectedUser={selectedUser}
					onOk={onOk}
					onCancel={onCancel}
				/>
			)}
			<Modal
				title="Role Permissions"
				visible={r_p_visible}
				onOk={handleOk}
				onCancel={handleOk}
				onClose={handleOk}
				confirmLoading={confirmLoading}
			>
				<List
					size="small"
					dataSource={PermissionList}
					renderItem={item => <List.Item>{item}</List.Item>}
				></List>
			</Modal>
		</div>
	);
};

export default ListEmployees;
