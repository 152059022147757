import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import config from '../config/config';
import auth from '../services/auth.service';

export default function ProtectedRoute({ isAllowed = true, reditectTo, render, ...props }) {
	const redirectUri = reditectTo || '/';
	return (
		<Route
			{...props}
			render={innerProps =>
				isAllowed ? (
					render(innerProps)
				) : (
					<Redirect
						to={{
							pathname: redirectUri,
							state: {
								referer: innerProps.location.pathname !== redirectUri ? innerProps.location : null
							}
						}}
					></Redirect>
				)
			}
		/>
	);
}

export function AuthRoute({
	authenticated,
	notAuthorizedCallback = null,
	roles = [],
	notAuthenticatedRedirect = '/signin',
	notAuthorizedRedirect = '/not-valid-session',
	notActivatedRedirect = '/acc/confirmation',
	checkActivation = false,
	...props
}) {
	let allowed = typeof authenticated == 'function' ? authenticated() : authenticated;
	let redirectUri = allowed ? null : notAuthenticatedRedirect;
	if (allowed) {
		if (checkActivation && !auth.isActivated()) {
			redirectUri = notActivatedRedirect;
			allowed = false;
		} else {
			if (!Array.isArray(roles)) roles = [roles];
			allowed = roles.length ? auth.hasRoles(...roles) : true;
			if (!allowed) {
				redirectUri = notAuthorizedRedirect;
				notAuthorizedCallback && notAuthorizedCallback();
			}
		}
	}

	return <ProtectedRoute isAllowed={allowed} reditectTo={redirectUri} {...props} />;
}
