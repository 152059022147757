import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Table, Button, Spin, message, Modal, Tag, Switch, Select, Row, Col } from 'antd';

import ShopService from '../../services/shop.service';
import useFetchData from '../../utils/fetchData';
import useEditRow from '../../utils/editRow';
import { FILTERS, FILTER_UTILS, useFilters, filterOpts, filterOptsShop } from '../../hooks/filters.hook';
import country_states from '../../constants/country_states';

function ListShops(props) {
	const [data, setData, isLoading, setLoading] = useFetchData(ShopService.getShops.bind(ShopService, 1,0,true));
	const edited = useEditRow();
	const { display, shops, setFilters, filters } = useFilters({
		data,
		filters: ['parentShop', 'isActive', 'shopName', 'state', 'country'],
		filterFunctions: {
			filterByShopName: name => item => item.name === name,
			filterByIsActive: active => item => item.is_active === (active.toLowerCase() === 'active' ? 1 : 0),
			filterByParentShop: parent => item => item.parent?.name === parent,
			filterByCountry: country => item => item.country === country,
			filterByState: state => item => item.state === state
		}
	});
	const columns = [
		{
			title: 'Shop Name',
			dataIndex: 'name',
			key: 'name',
			sorter: (a, b) => a.name.localeCompare(b.name),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'Tax ID',
			dataIndex: 'tax_id',
			key: 'tax_id',
			// sorter: (a, b) => a.tax_id.localeCompare(b.tax_id),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'Country',
			dataIndex: 'country',
			key: 'country',
			sorter: (a, b) => a.country.localeCompare(b.country),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'State',
			dataIndex: 'state',
			key: 'state',
			sorter: (a, b) => a.state.localeCompare(b.state),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'City',
			dataIndex: 'city',
			key: 'city',
			sorter: (a, b) => a.city.localeCompare(b.city),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'Address',
			dataIndex: 'location',
			key: 'location'
		},
		{
			title: 'Parent Shop',
			dataIndex: 'parent',
			key: 'parent',
			render: (_, record) => <span>{record.parent ? record.parent.name : 'NA'}</span>
		},
		{
			title: 'Action',
			key: 'action',
			fixed: 'right',
			render: (text, record) => (
				<React.Fragment>
					<Button
						danger={record.is_active}
						type={!record.is_active ? 'primary' : 'ghost'}
						style={{ width: '90px' }}
						size="small"
						onClick={() => {
							Modal.confirm({
								onOk:
									record.is_active == 1
										? deleteRecord.bind(this, record.id, 'deactivate')
										: deleteRecord.bind(this, record.id, 'activate'),
								title:
									record.is_active == 1
										? 'Are you sure you want to deactivate this shop?'
										: 'Are you sure you want to activate this shop?'
							});
						}}
					>
						{record.is_active ? 'Deactivate' : 'Activate'}
					</Button>
					<Button size="small">
						<Link
							to={{
								pathname: `/dashboard/edit-shop/${record.id}`
							}}
						>
							Edit
						</Link>
					</Button>
				</React.Fragment>
			)
		}
	];

	async function deleteRecord(id, toggleActivate) {
		let result =
			toggleActivate == 'activate' ? await ShopService.activateShop(id) : await ShopService.deactivateShop(id);
		if (result.success) {
			let newData = data.map(record => {
				if (record.id === id) {
					record.is_active ^= 1;
				}
				return record;
			});
			setData(newData);
			toggleActivate == 'activate'
				? message.success('the shop was activated successfully')
				: message.success('the shop was deactivated successfully');
		}
	}

	return (
		<div>
			<h1 className="h3"> List Shops </h1>
			<div className="pl-2">
				{!isLoading && (
					<>
						<Row className="my-3">
							<Col xs={8}>
								<span>Shop Name: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											shopName: val
										});
									}}
									className="w-75"
									placeholder="Select Shop"
									showSearch
									filterOption={filterOptsShop}
								>
									{data.map(shop => (
										<Select.Option value={shop.name} key={shop.id}>
											{shop.name}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={8}>
								<span>Parent Shop: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											parentShop: val
										});
									}}
									className="w-75"
									placeholder="Select Shop"
									showSearch
									filterOption={filterOptsShop}
								>
									{data.map(shop => (
										<Select.Option value={shop.name} key={shop.id}>
											{shop.name}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={8}>
								<span>Active: </span>
								{/* <Switch
							checkedChildren="Active"
							unCheckedChildren="Inactive"
							onChange={val => {
								setFilters({
									...filters,
									isActive: +val
								});
							}}
						/> */}
								<Select
									placeholder="Select report type"
									allowClear
									className="w-75"
									onChange={val => {
										setFilters({
											...filters,
											isActive:
												val === 0 || val === undefined
													? null
													: val === 1
													? 'Active'
													: 'Inactive'
										});
									}}
								>
									<Select.Option value={0}>All</Select.Option>
									<Select.Option value={1}>Active</Select.Option>
									<Select.Option value={2}>Inactive</Select.Option>
								</Select>
							</Col>
							{/* <ComponentGuard
							permissions={[permissions.shopsReadAll, permissions.shopsReadHierarchy]}
						></ComponentGuard> */}
						</Row>
						<Row className="my-3">
							<Col xs={8}>
								<span>Country: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											country: val
										});
									}}
									className="w-75"
									placeholder="Select Country"
									showSearch
									filterOption={filterOptsShop}
								>
									{Object.keys(country_states).map(c => (
										<Select.Option value={c} key={c}>
											{c}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={8}>
								<span>State: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											parentShop: val
										});
									}}
									className="w-75"
									placeholder="Select State"
									showSearch
									filterOption={filterOptsShop}
									disabled={!filters.country}
								>
									{country_states[filters.country]?.map(s => (
										<Select.Option value={s} key={s}>
											{s}
										</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
					</>
				)}
			</div>
			<Spin spinning={isLoading}>
				<Table
					size="small"
					columns={columns}
					dataSource={display}
					rowClassName={(record, index) => (record.id == edited ? 'table-row-dark' : 'table-row-light')}
				/>
			</Spin>
		</div>
	);
}

export default ListShops;
