import React from "react";
import Menu from "antd/lib/menu";

import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

const PendingFilter = ({ filterBy, ...props }) => {
  const onClick = ({ key }) => {
    filterBy(key);
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="1"> Customer Name</Menu.Item>
      <Menu.Item key="2"> Customer Mobile</Menu.Item>
      <Menu.Item key="3">Currency</Menu.Item>
      <Menu.Item key="4">Amount</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="5">Clear Filter</Menu.Item>
    </Menu>
  );

  return (
    <div {...props}>
      <Dropdown className="filter" overlay={menu}>
        <a className="ant-dropdown-link" href="#">
          Filter By <DownOutlined />
        </a>
      </Dropdown>
    </div>
  );
};

export default PendingFilter;