import ResourceManager from './resource-manager';
import config from '../utils/config';

class SlabService extends ResourceManager {
	async getSlabs() {
		return await this.getResource(config.URIs.get_slabs);
	}
	async getShopSlabs(id) {
		return await this.getResource(config.URIs.get_shop_available_slabs.replace(/:shopId/g, id));
	}
	async deleteSlab(id) {
		return await this.deleteResource(config.URIs.delete_slab.replace(/:id/g, id));
	}
	async addNewSlab(data) {
		return await this.postResource(config.URIs.add_new_slab, data);
	}
	async editSlab(data, id) {
		return await this.putResource(config.URIs.edit_slab.replace(/:id/g, id), data);
	}
}

export default new SlabService();
