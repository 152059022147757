import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../contexts/authentication.context';
import SystemRequstsService from '../../services/system_requests.service';	

import { faBars, faBell, faEnvelope, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';

import ALertList from '../ALertList/ALertList';

export function DashboardNavbar(props) {
	let { user } = useAuth();
	let { setUnAuthenticated } = useAuth();
    let recallalert =null;
	const [all_alerts,SetAlertArray] = useState(null);

	useEffect(() => {
		async function getAlertList()
		{
			try {
				
				var resp = await SystemRequstsService.getSystemRequests();
				if (!resp.success) throw new Error('There is an error please try again!');
				SetAlertArray(resp.data);
			
			} catch (e)
			{
				console.error(e);
				return;
			}
		}
		getAlertList();
	}, []);

	
	const onSelectedAlertAction = request_id => {
		props.props.history.push(`/dashboard/system_requests/${request_id}`);
	};

	return (
		<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
			<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
				<FontAwesomeIcon className="icons" icon={faBars} />
			</button>

			<ul className="navbar-nav ml-auto">
				<li className="nav-item dropdown no-arrow d-sm-none">
					<a
						className="nav-link dropdown-toggle"
						href="#"
						id="searchDropdown"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<i className="fas fa-search fa-fw"></i>
					</a>
					<div
						className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
						aria-labelledby="searchDropdown"
					>
						<form className="form-inline mr-auto w-100 navbar-search">
							<div className="input-group">
								<input
									type="text"
									className="form-control bg-light border-0 small"
									placeholder="Search for..."
									aria-label="Search"
									aria-describedby="basic-addon2"
								/>
								<div className="input-group-append">
									<button className="btn btn-primary" type="button">
										<i className="fas fa-search fa-sm"></i>
									</button>
								</div>
							</div>
						</form>
					</div>
				</li>

				<li className="nav-item dropdown no-arrow mx-1">
					<a
						className="nav-link dropdown-toggle"
						href="#"
						id="alertsDropdown"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<FontAwesomeIcon icon={faBell} style={{fontSize:'21px'}}/>
						<span className="badge badge-danger badge-counter" style={{fontSize:'11px'}}>{(all_alerts) ? all_alerts.length : 0}</span>
					</a>
					<div
						className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
						aria-labelledby="alertsDropdown"
					>
						<h6 className="dropdown-header">Alerts Center</h6>
						<ALertList onSelectedAlertAction={onSelectedAlertAction} all_alerts={(all_alerts) ? all_alerts : []}/>
					</div>
				</li>
{/*
				<li className="nav-item dropdown no-arrow mx-1">
					<a
						className="nav-link dropdown-toggle"
						href="#"
						id="messagesDropdown"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<FontAwesomeIcon icon={faEnvelope} />
						<span className="badge badge-danger badge-counter">7</span>
					</a>
					<div
						className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
						aria-labelledby="messagesDropdown"
					>
						<h6 className="dropdown-header">Newest 10 Messages</h6>
						<a className="dropdown-item d-flex align-items-center" href="#">
							<div className="dropdown-list-image mr-3">
								<div className="icon-circle bg-primary">EF</div>
								<div className="status-indicator bg-success"></div>
							</div>
							<div className="font-weight-bold">
								<div className="text-truncate">
									Hi there! I am wondering if you can help me with a problem I've been having.
								</div>
								<div className="small text-gray-500">Emily Fowler / Shop 1</div>
							</div>
						</a>
						<a className="dropdown-item d-flex align-items-center" href="#">
							<div className="dropdown-list-image mr-3">
								<div className="icon-circle bg-primary">JC</div>
								<div className="status-indicator"></div>
							</div>
							<div>
								<div className="text-truncate">
									I have the photos that you ordered last month, how would you like them sent to you?
								</div>
								<div className="small text-gray-500">Jae Chun / Shop 2</div>
							</div>
						</a>
						<a className="dropdown-item text-center small text-gray-500" href="#">
							Read More Messages
						</a>
					</div>
				</li>
*/}
				<div className="topbar-divider d-none d-sm-block"></div>

				<li className="nav-item dropdown no-arrow">
					<a
						className="nav-link dropdown-toggle"
						href="#"
						id="userDropdown"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="mr-2 d-none d-lg-inline text-gray-600 small">{`${user.first_name} ${user.last_name}`}</span>
					</a>
					<div
						className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
						aria-labelledby="userDropdown"
					>
						<a className="dropdown-item" href="#">
							<FontAwesomeIcon className="icons" icon={faUser} />
							Profile
						</a>
						<div className="dropdown-divider"></div>
						<a className="dropdown-item" href="#" data-toggle="modal" onClick={e => {
								e.preventDefault();
								setUnAuthenticated();
								window.localStorage.clear();
								window.location.reload();
							}} data-target="#logoutModal">
							<FontAwesomeIcon className="icons" icon={faSignOutAlt}/>
							Logout
						</a>
					</div>
				</li>
			</ul>
		</nav>
	);
}
