import React, { useState } from 'react';
import { Row, Tag, List, Col, Table, Modal, Tooltip,message } from 'antd';
import { DateHeader } from './styles';
import { InvoiceModal } from '../Invoices/InvoiceModal';
import Text from 'antd/lib/typography/Text';
import  ReportAction  from './ReportAction';
import {AuditOutlined,FieldTimeOutlined} from '@ant-design/icons';
import stockService from '../../services/stock.service';
import { date } from 'joi';
const defaultListItem = (report, index) => <ReportListItem index={index} report={report} />;
function ReportsDayList({ date, reports, columns }) {
	return (
		<div>
			<DateHeader date={date} />
			<Table
				dataSource={reports}
				columns={columns}
				size="middle"
				locale={{
					emptyText: 'No Transaction'
				}}
				summary={pageData => {
					let totalTransactions = 0;
					let totalAmount = 0;

					pageData.forEach(report => {
						totalTransactions++;
						totalAmount += parseFloat(report.usd_amount);
					});
					console.log(totalAmount);

					return (
						<>
							<Table.Summary.Row>
								<Table.Summary.Cell colSpan={3}>
									<span
										style={{
											fontSize: '16px',
											fontWeight: '700',
											color: '#3F536E',
											textAlign: 'end',
											lineHeight: '19px'
										}}
									>
										Total Transactions
									</span>
								</Table.Summary.Cell>
								<Table.Summary.Cell colSpan={7}>
									<Text type="danger">{totalTransactions.toLocaleString()}</Text>
								</Table.Summary.Cell>
								{/* <Table.Summary.Cell>
									<Text>{t}</Text>
								</Table.Summary.Cell> */}
							</Table.Summary.Row>
							{/*<Table.Summary.Row>
								<Table.Summary.Cell>
									<span
										style={{
											fontSize: '16px',
											fontWeight: '700',
											color: '#3F536E',
											textAlign: 'end',
											lineHeight: '19px'
										}}
									>
										Total Amount
									</span>
								</Table.Summary.Cell>
								<Table.Summary.Cell colSpan={5}>
									<Text type="danger">
										{formatDashedNumber(totalAmount.toFixed(4))}{' '}
										USD
									</Text>
								</Table.Summary.Cell>
									</Table.Summary.Row>*/}
						</>
					);
				}}
			/>
		</div>
	);
}

function ReportListItem({ report, index }) {
	return (
		<List.Item>
			<List.Item.Meta
				title={
					<Row gutter={0}>
						<Col xs={{ span: 6 }} className="text-center mb-2 mb-xl-1">
							<span
								style={{
									fontSize: '13px',
									color: '#3F536E',
									textAlign: 'end',
									lineHeight: '19px'
								}}
							>
								{report.usd_amount}
							</span>
						</Col>
						<Col xs={{ span: 6 }} className="text-center mb-2 mb-xl-1">
							{report.foreign_amount}
						</Col>
						<Col xs={{ span: 6 }} className="text-center mb-2 mb-xl-1">
							{report.is_sell ? <Tag color="gold">Sell</Tag> : <Tag color="green">Buy</Tag>}
						</Col>
						<Col xs={{ span: 6 }} className="text-center mb-2 mb-xl-1">
							{(Math.round(parseFloat(report.profit) * 10000000) / 10000000).toLocaleString()}
						</Col>
					</Row>
				}
			/>
		</List.Item>
	);
}

export default function ReportsList({ reports, showShopName }) {
	let [showInvoiceModal, setMoalVisible] = useState(false);
	let [selectedInvoice, setSelectedInvoice] = useState();
	const [historyVisible, setHistoryVisible] = React.useState(false);
	const [transHistory, setHistoryItems] = React.useState([{date:'LOADING ...',shop:''}]);
	const [historyLoading, setHistoryLoading] = React.useState(false);

	const viewInvoice = invoice => () => {
		console.log('list', invoice);
		setSelectedInvoice(invoice);
		setMoalVisible(true);
	};

	const showHistory = async (id) => {
		console.log(id);
		setHistoryLoading(true);
		setHistoryItems([
			{ date: 'LOADING ...', shop: '' }
		]);
		try {
			var resp = await stockService.getHistory({id:id});
			if (!resp) throw new Error('There is an error please try again!');
			setHistoryVisible(true);
			setHistoryLoading(false);
			setHistoryItems(resp);
		} catch (e) {
		setHistoryLoading(true);
			setHistoryVisible(false);
			message.error(e.message);
			console.error(e);
			return;
		}
	};

	const onCancelHistory = () => {
		setHistoryVisible(false);
		Modal.destroyAll();
	};

	const columns = [
		{
			title: 'Invoice',
			dataIndex: 'invoice.id',
			width: '30px',
			key: 'invoice.id',
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.invoice.id),
					p2 = parseFloat(r2.invoice.id);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				(record.invoice) ? '#'+record.invoice.id : ''
			)
		},
		{
			title: 'USD',
			dataIndex: 'usd_amount',
			key: 'usd_amount',
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.usd_amount),
					p2 = parseFloat(r2.usd_amount);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				parseFloat(record.usd_amount).toLocaleString()
			)
		},
		{
			title: 'Foregin',
			dataIndex: 'foreign_amount',
			key: 'foreign_amount',
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.foreign_amount),
					p2 = parseFloat(r2.foreign_amount);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				parseFloat(record.foreign_amount).toLocaleString()
			)
		},
		{
			title: 'Currency',
			dataIndex: 'currency.code',
			key: 'currency.code',
			render: (text, record) => (
				<span style={{ fontSize: '15px', fontWeight: '700' }}>{record.currency.code}</span>
			),
			sorter: (r1, r2) => {
				let c1 = r1.currency.code,
					c2 = r2.currency.code;
				return c1 > c2 ? 1 : c2 > c1 ? -1 : 0;
			}
		},
		{
			title: 'Fees',
			render: (text, record) => (
				<span>
					{record.is_reconcilation
						? record.fees
						: record.invoice && record.invoice.data.operations.find(i => i.invoicable_id === record.id) && record.invoice.data.operations.find(i => i.invoicable_id === record.id).fees}
				</span>
			),
			sorter: (r1, r2) => {
				let f1 = r1.invoice?.data.operations.find(i => i.invoicable_id === r1.id).fees,
					f2 = r2.invoice?.data.operations.find(i => i.invoicable_id === r2.id).fees;
				return f1 > f2 ? 1 : f2 > f1 ? -1 : 0;
			}
		},
		{
			title: 'Pay Rate',
			render: (text, record) => (
				<span>
					{record.is_reconcilation
						? record.exchange_rate
						: record.invoice && record.invoice.data.operations.find(i => i.invoicable_id === record.id) && record.invoice.data.operations.find(i => i.invoicable_id === record.id).exchange_rate}
				</span>
			),
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.invoice?.data.operations.find(i => i.invoicable_id === r1.id).exchange_rate),
					p2 = parseFloat(r2.invoice?.data.operations.find(i => i.invoicable_id === r2.id).exchange_rate);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			}
		},
		{
			title: 'Spot Rate',
			render: (text, record) => (
				<span>
					{record.is_reconcilation
						? record.market_rate
						: (record.invoice &&
								record.invoice.data.operations.find(i => i.invoicable_id === record.id) &&
								record.invoice.data.operations.find(i => i.invoicable_id === record.id).market_rate) ||
						  'NA'}
				</span>
			),
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.invoice?.data.operations.find(i => i.invoicable_id === r1.id).market_rate),
					p2 = parseFloat(r2.invoice?.data.operations.find(i => i.invoicable_id === r2.id).market_rate);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			}
		},
		{
			title: 'Type',
			dataIndex: 'is_sell',
			key: 'is_sell',
			render: (_, report) => {
				return (
					<>
						{report.is_sell ? <Tag color="gold">Sell</Tag> : report.is_transfer ?
							<Tag color="blue">Transfered</Tag>
							:
							report.is_reconcilation
								? <Tag color="blue">Reconcilation</Tag>
								:
								<Tag color="green">Buy</Tag>
						}
					</>
				);
			},
			sorter: (r1, r2) => r1.is_sell - r2.is_sell
		},
		{
			title: <span className="text-center">Profit $</span>,
			dataIndex: 'profit',
			key: 'profit',

			render: profit => (
				<span className="text-center">
					{(Math.round(parseFloat(profit) * 100000) / 100000).toLocaleString()}
				</span>
			),
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.profit),
					p2 = parseFloat(r2.profit);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			}
		},
		{
			title: <span className="text-center">Actions</span>,
			render: (_, report) =>
				report.invoice ?
					<>
						<Tooltip placement="right" title="View Invoice">
							<AuditOutlined style={{ fontSize: '19px', color: '#016590' }} onClick={viewInvoice(report.invoice)}></AuditOutlined>
						</Tooltip>
						<ReportAction report={report}></ReportAction>
					</>
					:
					<Tooltip placement="right" title="View History">
						<FieldTimeOutlined style={{ fontSize: '19px', color: '#016590' }} onClick={() => {return showHistory(report.id)}}></FieldTimeOutlined>
					</Tooltip>
		}
	];

	if (showShopName) {
		columns.splice(columns.length - 1, 0, {
			title: 'Shop',
			dataIndex: 'invoice.shop.name',
			key: 'invoice.shop.name',
			render: (shop, record) => <span>{record.invoice?.data.shop.name}</span>,
			sorter: (r1, r2) => {
				let s1 = r1.invoice?.data.shop.name,
					s2 = r2.invoice?.data.shop.name;
				return s1 > s2 ? 1 : s2 > s1 ? -1 : 0;
			}
		});
	}

	return (
		<div
			style={{
				backgroundColor: '#ffffff'
			}}
		>
			{Object.keys(reports)
				.sort()
				.map(function (date) {
					console.log(date)
					let dayReports = reports[date];
					return <ReportsDayList reports={dayReports} date={date} columns={columns} />;
				})}
			{showInvoiceModal && (
				<InvoiceModal
					key={date}
					visible={showInvoiceModal}
					invoice={selectedInvoice}
					onCancel={() => {
						setMoalVisible(false);
						setSelectedInvoice(null);
					}}
				/>
			)}
			<Modal
				visible={historyVisible}
				title="Transaction History"
				onOk={onCancelHistory}
				onCancel={onCancelHistory}
				onClose={onCancelHistory}
				confirmLoading={historyLoading}
				>
				{
					transHistory.map((item) => {
						return (<Row style={{borderBottom:'1px dashed #112233'}}>
							<Col span={4}>{item.origin_type}</Col>
							<Col span={8}>{item.stock_user}</Col>
							<Col span={6}>{item.amount}</Col>
							<Col span={6}>{item.date}</Col>
						</Row>);
					})
				}	
			</Modal>
		</div>
	);
}
