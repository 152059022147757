export default {
	shopsReadHierarchy: 'shops.read.hierarchy',
	shopsReadAll: 'shops.read.all',
	shopsWriteHierarchy: 'shops.write.hierarchy',
	shopsWriteAll: 'shops.write.all',
	employeesReadHierarchy: 'employees.read.hierarchy',
	employeesReadAll: 'employees.read.all',
	employeesWriteHierarchy: 'employees.write.hierarchy',
	employeesWriteAll: 'employees.write.all',

	customersReadHierarchy: 'customers.read.hierarchy',
	customersReadAll: 'customers.read.all',
	customersWriteHierarchy: 'customers.write.hierarchy',
	customersWriteAll: 'customers.write.all',

	reconcilationReadHierarchy: 'reconcilation.read.hierarchy',
	reconcilationReadAll: 'reconcilation.read.all',
	reconcilationWriteHierarchy: 'reconcilation.write.hierarchy',
	reconcilationWriteAll: 'reconcilation.write.all',

	stocksReadHierarchy: 'stocks.read.hierarchy',
	stocksReadAll: 'stocks.read.all',
	stocksWriteHierarchy: 'stocks.write.hierarchy',
	stocksWriteAll: 'stocks.write.all',

	slabsReadAll: 'slabs.read.all',
	slabsWriteAll: 'slabs.write.all',

	feesReadAll: 'fees.read.all',
	feesWriteAll: 'fees.write.all',

	rolesReadAll: 'roles.read.all',
	rolesWriteAll: 'roles.write.all',

	reportsReadAll: 'reports.read.all',
	reportsReadHierarchy: 'reports.read.hierarchy',

	brokersReadAll: 'brokers.read.all',
	brokersWriteAll: 'brokers.write.all',

	currenciesWriteAll: 'currencies.write.all',

	operationCanChangeFees: 'operation.canchange.fee',
	operationCanChangeRates: 'operation.canchange.rate',

	operationCanChangeMaxCount: 'operation.canchange.maxcount',
	operationCanChangeInHand: 'operation.canchange.inhand'
};
