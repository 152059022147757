import styled from 'styled-components';

const ConvertCurrencyDiv = styled.div`
	padding: 0 50px;
	& > div {
		border-radius: 15px;
		background: rgba(0, 0, 0, 0.1);
		overflow: hidden;
		padding: 20px 35px;
		margin-bottom: 50px;
	}&
	.agent-info{
		background: white;
		margin-bottom: 5px;
		border-radius: 15px;
		padding: 9px;
	}
`;

export default ConvertCurrencyDiv;
