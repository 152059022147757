import ResourceManager from './resource-manager';
import config from '../utils/config';

class CustomerService extends ResourceManager {
	async getCustomer(customerId) {
		return await this.getResource(config.URIs.get_customer.replace(/:custoemrId/g, customerId));
	}

	async getCustomers() {
		return await this.getResource(config.URIs.get_customers);
	}

	async editCustomer(id, data) {
		return await this.putResource(config.URIs.edit_customer.replace(/:id/g, id), data);
	}

	async createCustomer(data) {
		return await this.postResource(config.URIs.create_customer, data);
	}

	async updateCustoemr(customerId, data) {
		return await this.putResource(config.URIs.update_customer.replace(/:customerId/g, customerId), data);
	}

	async searchCustomers(searchKeys) {
		return await this.getResource(config.URIs.search_customers + '?' + new URLSearchParams(searchKeys).toString());
	}

	async deleteCustomer(id) {
		return await this.deleteResource(config.URIs.delete_customer.replace(/:id/g, id));
	}
}

export default new CustomerService();
