import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './DashboardSidebar.css';

import {
	faTachometerAlt,
	faSlidersH,
	faCog,
	faWrench,
	faFolder,
	faWallet,
	faUsers,
	faStore,
	faBalanceScale,
	faFunnelDollar,
	faUserLock,
	faCashRegister,
	faCoins,
} from '@fortawesome/free-solid-svg-icons';
import ComponentGuard from '../ComponentGuard/ComponentGuard';
import permissions from '../../constants/permissions';

const dashboardSidebar = props => {
	return (
		// <div id="wrapper">
		<ul className="navbar-nav vh-100" id="accordionSidebar">
			<a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
				<div className="sidebar-brand-text mx-3">AX Exchange</div>
			</a>

			<hr className="sidebar-divider my-0" />

			<li className="nav-item active">
				<Link className="nav-link" to={`${process.env.PUBLIC_URL}/dashboard/control`} data-link="Dashboard">
					<FontAwesomeIcon className="icons" icon={faTachometerAlt} />
					<span>Dashboard</span>
				</Link>
			</li>

			<hr className="sidebar-divider" />
			<li className="nav-item">
				<a
					className="nav-link collapsed"
					href="#"
					data-toggle="collapse"
					data-target="#collapseTwo"
					aria-expanded="true"
					aria-controls="collapseTwo"
				>
					<FontAwesomeIcon className="icons" icon={faStore} />
					<span>Shops</span>
				</a>
				<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
					<div className="bg-white py-2 collapse-inner rounded">
						<ComponentGuard permissions={[permissions.shopsReadAll, permissions.shopsReadHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Shops"
								to={`${process.env.PUBLIC_URL}/dashboard/list-shops`}
							>
								List Shops
							</Link>
						</ComponentGuard>
						<ComponentGuard permissions={[permissions.shopsWriteAll, permissions.shopsWriteHierarchy]}>
							<Link
								className="collapse-item"
								data-link="NewShop"
								to={`${process.env.PUBLIC_URL}/dashboard/add-new-shop`}
							>
								Add New Shop
							</Link>
						</ComponentGuard>
					</div>
				</div>
			</li>
			<hr className="sidebar-divider" />

			<li className="nav-item">
				<a
					className="nav-link collapsed"
					href="#"
					data-toggle="collapse"
					data-target="#collapseSlabs"
					aria-expanded="true"
					aria-controls="collapseSlabs"
				>
					<FontAwesomeIcon className="icons" icon={faBalanceScale} />
					<span>Rate Slabs</span>
				</a>
				<div
					id="collapseSlabs"
					className="collapse"
					aria-labelledby="headingSlabs"
					data-parent="#accordionSidebar"
				>
					<div className="bg-white py-2 collapse-inner rounded">
						<ComponentGuard permissions={[permissions.slabsReadAll]}>
							<Link
								className="collapse-item"
								data-link="Roles"
								to={`${process.env.PUBLIC_URL}/dashboard/list-slabs`}
							>
								List Rate Slabs
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.slabsWriteAll]}>
							<Link
								className="collapse-item"
								data-link="NewRole"
								to={`${process.env.PUBLIC_URL}/dashboard/add-new-slab`}
							>
								Add New SLab
							</Link>
						</ComponentGuard>
					</div>
				</div>
			</li>

			<hr className="sidebar-divider" />

			<li className="nav-item">
				<a
					className="nav-link collapsed"
					href="#"
					data-toggle="collapse"
					data-target="#collapseFees"
					aria-expanded="true"
					aria-controls="collapseFees"
				>
					<FontAwesomeIcon className="icons" icon={faFunnelDollar} />
					<span>Fees Slabs</span>
				</a>
				<div
					id="collapseFees"
					className="collapse"
					aria-labelledby="headingFees"
					data-parent="#accordionSidebar"
				>
					<div className="bg-white py-2 collapse-inner rounded">
						<ComponentGuard permissions={[permissions.feesReadAll]}>
							<Link
								className="collapse-item"
								data-link="Roles"
								to={`${process.env.PUBLIC_URL}/dashboard/list-fees`}
							>
								List Fees Slabs
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.feesWriteAll]}>
							<Link
								className="collapse-item"
								data-link="NewRole"
								to={`${process.env.PUBLIC_URL}/dashboard/add-new-fees`}
							>
								Add New Fee
							</Link>
						</ComponentGuard>
					</div>
				</div>
			</li>

			<hr className="sidebar-divider" />

			<li className="nav-item">
				<a
					className="nav-link collapsed"
					href="#"
					data-toggle="collapse"
					data-target="#collapseRoles"
					aria-expanded="true"
					aria-controls="collapseRoles"
				>
					<FontAwesomeIcon className="icons" icon={faUserLock} />
					<span>Roles</span>
				</a>
				<div
					id="collapseRoles"
					className="collapse"
					aria-labelledby="headingRoles"
					data-parent="#accordionSidebar"
				>
					<div className="bg-white py-2 collapse-inner rounded">
						<ComponentGuard permissions={[]}>
							<Link
								className="collapse-item"
								data-link="Roles"
								to={`${process.env.PUBLIC_URL}/dashboard/list-roles`}
							>
								List Roles
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.rolesWriteAll]}>
							<Link
								className="collapse-item"
								data-link="NewRole"
								to={`${process.env.PUBLIC_URL}/dashboard/add-new-role`}
							>
								Add New Role
							</Link>
						</ComponentGuard>
					</div>
				</div>
			</li>

			<hr className="sidebar-divider" />

			<li className="nav-item">
				<a
					className="nav-link collapsed"
					href="#"
					data-toggle="collapse"
					data-target="#collapseUtilities"
					aria-expanded="true"
					aria-controls="collapseUtilities"
				>
					<FontAwesomeIcon className="icons" icon={faUsers} />
					<span>Stakeholder</span>
				</a>
				<div
					id="collapseUtilities"
					className="collapse"
					aria-labelledby="headingUtilities"
					data-parent="#accordionSidebar"
				>
					<div className="bg-white py-2 collapse-inner rounded">
						<ComponentGuard
							permissions={[permissions.employeesReadAll, permissions.employeesReadHierarchy]}
						>
							<Link
								className="collapse-item"
								data-link="Employees"
								to={`${process.env.PUBLIC_URL}/dashboard/list-employees`}
							>
								List Employees
							</Link>
						</ComponentGuard>

						<ComponentGuard
							permissions={[permissions.employeesWriteAll, permissions.employeesWriteHierarchy]}
						>
							<Link
								className="collapse-item"
								data-link="NewEmployee"
								to={`${process.env.PUBLIC_URL}/dashboard/add-new-employee`}
							>
								Add New Employee
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.brokersReadAll]}>
							<Link
								className="collapse-item"
								data-link="Brockers"
								to={`${process.env.PUBLIC_URL}/dashboard/list-brokers`}
							>
								List Brokers
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.brokersWriteAll]}>
							<Link
								className="collapse-item"
								data-link="NewBrocker"
								to={`${process.env.PUBLIC_URL}/dashboard/add-new-broker`}
							>
								Add New Broker
							</Link>
						</ComponentGuard>

						<ComponentGuard
							permissions={[permissions.customersReadAll, permissions.customersReadHierarchy]}
						>
							<Link
								className="collapse-item"
								data-link="Customers"
								to={`${process.env.PUBLIC_URL}/dashboard/list-customers`}
							>
								List Customers
							</Link>
						</ComponentGuard>
					</div>
				</div>
			</li>

			<hr className="sidebar-divider" />

			<li className="nav-item">
				<a
					className="nav-link collapsed"
					href="#"
					data-toggle="collapse"
					data-target="#collapsePages"
					aria-expanded="true"
					aria-controls="collapsePages"
				>
					<FontAwesomeIcon className="icons" icon={faCoins} />
					<span>Currencies</span>
				</a>
				<div
					id="collapsePages"
					className="collapse"
					aria-labelledby="headingPages"
					data-parent="#accordionSidebar"
				>
					<div className="bg-white py-2 collapse-inner rounded">
						<h6 className="collapse-header">Currency Services:</h6>

							<Link
								className="collapse-item"
								data-link="Currencies"
								to={`${process.env.PUBLIC_URL}/dashboard/list-currencies`}
							>
								List Currencies
							</Link>

						<ComponentGuard permissions={[permissions.currenciesWriteAll]}>
							<Link
								className="collapse-item"
								data-link="NewCurrency"
								to={`${process.env.PUBLIC_URL}/dashboard/add-new-currency`}
							>
								Add New Currency
							</Link>
						</ComponentGuard>
					</div>
				</div>
			</li>

				<hr className="sidebar-divider d-none d-md-block" />
				
			<li className="nav-item">
				<a
					className="nav-link collapsed"
					href="#"
					data-toggle="collapse"
					data-target="#collapseStocks"
					aria-expanded="true"
					aria-controls="collapseStocks"
				>
					<FontAwesomeIcon className="icons" icon={faCashRegister} />
					<span>Cash Register</span>
				</a>
				<div
					id="collapseStocks"
					className="collapse"
					aria-labelledby="headingStocks"
					data-parent="#accordionSidebar"
				>
					<div className="bg-white py-2 collapse-inner rounded">
						<h6 className="collapse-header">Currency Services:</h6>
						<ComponentGuard permissions={[permissions.stocksReadAll, permissions.stocksReadHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Stocks"
								to={`${process.env.PUBLIC_URL}/dashboard/list-stocks`}
							>
								List Stocks
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.stocksWriteAll, permissions.stocksWriteHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Stocks"
								to={`${process.env.PUBLIC_URL}/dashboard/insert-stocks`}
							>
								Insert Stocks
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.stocksWriteAll, permissions.stocksWriteHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Stocks"
								to={`${process.env.PUBLIC_URL}/dashboard/insert-stocks-by-file`}
							>
								Insert Stocks By File
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.stocksWriteAll, permissions.stocksWriteHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Stocks"
								to={`${process.env.PUBLIC_URL}/dashboard/exchange-between`}
							>
								Stock Transfer
							</Link>
						</ComponentGuard>
					</div>
				</div>
			</li>

			<hr className="sidebar-divider d-none d-md-block" />

			<li className="nav-item">
				<a
					className="nav-link collapsed"
					href="."
					data-toggle="collapse"
					data-target="#collapseReports"
					aria-expanded="true"
					aria-controls="collapseReports"
				>
					<FontAwesomeIcon className="icons" icon={faTachometerAlt} />
					<span>Reports</span>
				</a>
				<div
					id="collapseReports"
					className="collapse"
					aria-labelledby="headingReports"
					data-parent="#accordionSidebar"
				>
					<div className="bg-white py-2 collapse-inner rounded">
						<h6 className="collapse-header">Reports:</h6>

						<ComponentGuard permissions={[permissions.reportsReadAll, permissions.reportsReadHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Stocks"
								to={`${process.env.PUBLIC_URL}/dashboard/transactions-reports`}
							>
								Transaction Reports
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.reportsReadAll, permissions.reportsReadHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Stocks"
								to={`${process.env.PUBLIC_URL}/dashboard/profit-reports`}
							>
								Profit Reports
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.reportsReadAll, permissions.reportsReadHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Stocks"
								to={`${process.env.PUBLIC_URL}/dashboard/totals-reports`}
							>
								Totals Reports
							</Link>
						</ComponentGuard>

						<ComponentGuard permissions={[permissions.reportsReadAll, permissions.reportsReadHierarchy]}>
							<Link
								className="collapse-item"
								data-link="Stocks"
								to={`${process.env.PUBLIC_URL}/dashboard/details-reports`}
							>
								Details Reports
							</Link>
						</ComponentGuard>
						<ComponentGuard permissions={[permissions.reportsReadAll, permissions.reportsReadHierarchy]}>
							<Link
								className="collapse-item"
								data-link="FiatReport"
								to={`${process.env.PUBLIC_URL}/dashboard/fiat-reports`}
							>
								Fiat Report
							</Link>
						</ComponentGuard>
					</div>
				</div>
			</li>
			<hr className="sidebar-divider d-none d-md-block" />
			<li className="nav-item">
				<div className="py-2 collapse-inner rounded">
					<Link
						className="nav-link collapsed"
						data-link="System Settings"
						to={`${process.env.PUBLIC_URL}/dashboard/system-settings`}
					>
						<FontAwesomeIcon className="icons" icon={faSlidersH} />
						System Settings
					</Link>
				</div>
			</li>
			<hr className="sidebar-divider" />

			{/* <div className="text-center d-none d-md-inline">
				<button className="rounded-circle border-0" id="sidebarToggle" data-id="sidebarToggle"></button>
			</div> */}
		</ul>
	);
};

export default dashboardSidebar;
