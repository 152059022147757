import React, { useState, useEffect } from 'react';
import { Collapse , Button, Alert} from 'antd';
import { AppstoreAddOutlined, SaveOutlined } from '@ant-design/icons';
import AgenSlabObject from './AgentSlabObject';

const EditAgentSlabs = props => {

    const { Panel } = Collapse;

	const [LocalAgentCurrencySlabs, getAgentCurrencySlabs] = useState(props.AgentCurrencySlabs);
	const [errorMessage] = useState('');
	const [saveLoading, setSaveLoading] = useState(false);
	const [updatingLoading, setUpdatingLoading] = useState(false);
	const [CurrencySlabs, getCurrencySlabs] = useState(props.StartupStabs);
	
	const [keyStep, setKeyStep] = useState(0);

    const callbackFunction = () => {
        setUpdatingLoading(false)        
        console.log(LocalAgentCurrencySlabs['7']);
    }

    useEffect(() => { callbackFunction() }, [LocalAgentCurrencySlabs]);

    const getSlabs = (id) =>
    {
    	const slabbArray = props.AgentCurrencySlabs[id] ? props.AgentCurrencySlabs[id] : [] ;
        getCurrencySlabs(slabbArray)
    }

    const addNewSlabObject = (id) => {
        const newSlabObj = [...CurrencySlabs];
        newSlabObj.push(
            {
                boundary:0,
                fees_buy_value: 0,
                fees_sell_value: 0,
                rate_buy_value: 0,
                rate_sell_value: 0,
                fees_buy_type: 'ratio',
                fees_sell_type: 'ratio',
                rate_buy_type: 'ratio',
                rate_sell_type: 'ratio'
            });
        regenerateSlabs(newSlabObj,id);
    }

    const saveAllNewSlabObject = async (id) => {
        const data = LocalAgentCurrencySlabs[id];
        setSaveLoading(true)
        await props.onUpdateCurrencySlabs({currency_id:id,data:data}).then((result) => {
            setSaveLoading(false)
        }).catch((err) => {
            setSaveLoading(false)
        });
    }

    const removeSlabItem = (data) => {
    	setUpdatingLoading(true)
        const newSlabObj = CurrencySlabs.filter((slab_object, index_in) => {
            return index_in != data.idx;
        })

        const tStep = keyStep+1
        setKeyStep(tStep);

        regenerateSlabs(newSlabObj,data.id);
    }

    const updateSlabObject = (data) => {
        setUpdatingLoading(true)
        const newSlabObj =  CurrencySlabs.map((item,index_in)=>{
            if(data.idx === index_in) 
                item = data.data;

            return item;
        });

        regenerateSlabs(newSlabObj,data.id);
    }

    const regenerateSlabs = (newSlabObj,id) =>{
    	getCurrencySlabs(newSlabObj);
        const newAgentCurrencySlabs = { ...LocalAgentCurrencySlabs };
        newAgentCurrencySlabs[id] =  [...newSlabObj];
        getAgentCurrencySlabs(newAgentCurrencySlabs);
        console.log(LocalAgentCurrencySlabs['7']);
    }

	return (
		<Collapse
			accordion
            onChange={getSlabs}
		>
		{
			props.currencies.map((item, index) => {
				if (!CurrencySlabs)
                    CurrencySlabs =
                    [
                        {
                            boundary:0,
                            fees_buy_value:0,
                            fees_sell_value:0,
                            rate_buy_value:0,
                            rate_sell_value:0,
                            fees_buy_type:'ratio',
                            fees_sell_type:'ratio',
                            rate_buy_type:'ratio',
                            rate_sell_type:'ratio'
                        }
                    ]
                let img = (props.AgentCurrencySlabs[item.id]) ? '/on.png' : '/off.png';
                const showCurrency = props.allToShow || item.active;

                return showCurrency ? <Panel style={{ backgroundImage: 'url(' + item.flag + ')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 34 }} header={`${item.name} (${item.code})`} key={item.id} extra={<img width={19} src={process.env.PUBLIC_URL + img} />}>

					<Button style={{ width: '25%'}} className="d-block" type="primary" icon={<AppstoreAddOutlined />} onClick={()=>addNewSlabObject(item.id)}></Button>
					<span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center border-left">UP TO</span>
					<span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center border-left">Buy Fees</span>
					<span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center border-left">Sell Fees</span>
					<span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center border-left">Buy Rate</span>
					<span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center border-left">Sell Rate</span>
                    <span style={{ width: '5%' }} className="d-inline-block bg-info text-white text-center border-left">Delete</span>
                    {
                        errorMessage
                        ?<Alert
                            showIcon
                            message={errorMessage}
                            type="error"
                        />
                        :''
                    }
					{
					    CurrencySlabs
					    ?
                            CurrencySlabs.map((slab_object, index_in) => {
							   return <AgenSlabObject key={`slab_obj_${index_in+'_'+keyStep}`} data={slab_object} removeSlabItem={removeSlabItem} updateSlabObject={updateSlabObject} id={item.id} idx={index_in}/>
							})
						: ''
					}
                    <Button style={{ width: '100%' }} loading={ saveLoading||updatingLoading} type="primary" icon={<SaveOutlined />} onClick={()=>saveAllNewSlabObject(item.id)}></Button>
                </Panel>
                    :
                    ''
			})
		}
		</Collapse>
    )
};

export default EditAgentSlabs;