import ResourceManager from './resource-manager';
import config from '../utils/config';

class ReportsService extends ResourceManager {
	async getReports(params = {}, options = {}) {
		return await this.getResource(config.URIs.get_reports, { ...options, params });
	}

	async getProfitReports(params = {}, options = {}) {
		return await this.getResource(config.URIs.get_profit_reports, { ...options, params });
	}

	async getTotalsReports(params = {}, options = {}) {
		return await this.getResource(config.URIs.get_totals_reports, { ...options, params });
	}

	async getDetailsReports(params = {}, options = {}) {
		return await this.getResource(config.URIs.get_details_reports, { ...options, params });
	}

	async getFiatReports(params = {}, options = {}) {
		return await this.getResource(config.URIs.get_fiat_reports, { ...options, params });
	}

	async exportTotalsReports( data = {},params = {}) {
		return await this.postBlobResource(config.URIs.export_totals_reports, data);
	}

	async exportDetailsReports( data = {},params = {}) {
		return await this.postBlobResource(config.URIs.export_details_reports, data);
	}

	async exportFiatReports( data = {},params = {}) {
		return await this.postBlobResource(config.URIs.export_fiat_reports, data);
	}

	async sendTotalsToShopEmail( data = {},params = {}) {
		return await this.postResource(config.URIs.send_totals_reports, data);
	}
}

export default new ReportsService();
