
import React from 'react';
import {capitalizeFirstLetter} from '../../utils/Helpers'  
export default function ALertList(props) 
{
    let AllAlerts = [];
    let all_alerts = props.all_alerts;
    
    for(let idx in all_alerts)
    {
        let alert = props.all_alerts[idx];
        AllAlerts.push(
        <a className="dropdown-item d-flex align-items-center" onClick={() =>props.onSelectedAlertAction(alert.request_type)} href="#">
        <div className="mr-3">
            <div className="icon-circle bg-success">
                {alert.cnt}
            </div>
        </div>
        <div>
            Requested {capitalizeFirstLetter(alert.request_type.replace('_',' '),true)} <br />
            <sub>click for details</sub>
        </div>
        </a> 
        );
    }

    return (
        AllAlerts
    );
}