import React from 'react';

import { Table, message, Spin, Row, Col, DatePicker, Button, Select } from 'antd';
import { fixAutocomplete } from '../../utils/dom';
import reportsService from '../../services/reports.service';
import ReportsList from '../../components/ReportsList/ReportsList';
import currencies from '../../constants/currencies';
import shopService from '../../services/shop.service';
import { titlize } from '../../utils/strings';

const filterOpts = (input, option) => option.props.key.toLowerCase().startsWith(input.toLowerCase());
const filterOptsShop = (input, option) => option.props.children.toLowerCase().startsWith(input.toLowerCase());

export default class ProfitReportContainer extends React.Component {
	state = {
		data: {},
		display: {},
		loading: false,
		ready: false,
		filter: {
			fromDate: '',
			toDate: ''
		}
	};
	columns = [
		{
			title: 'Currency',
			dataIndex: 'currency',
			key: 'currency',
			sorter: (r1, r2) => {
				let c1 = r1.currency,
					c2 = r2.currency;
				return c1 > c2 ? 1 : c2 > c1 ? -1 : 0;
			}
		},
		{
			title: 'Sell',
			dataIndex: 'total_sell',
			key: 'total_sell',
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.total_sell),
					p2 = parseFloat(r2.total_sell);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				parseFloat(record.total_sell).toLocaleString()
			)
		},
		{
			title: 'Buy',
			dataIndex: 'total_buy',
			key: 'total_buy',
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.total_buy),
					p2 = parseFloat(r2.total_buy);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				parseFloat(record.total_buy).toLocaleString()
			)
		},
		{
			title: 'Total Buy Transaction',
			dataIndex: 'buy_transactions_count',
			key: 'buy_transactions_count',
			sorter: (r1, r2) => {
				return r1.buy_transactions_count - r2.buy_transactions_count;
			}
		},
		{
			title: 'Total Sell Transaction',
			dataIndex: 'sell_transactions_count',
			key: 'sell_transactions_count',
			sorter: (r1, r2) => {
				return r1.sell_transactions_count - r2.sell_transactions_count;
			}
		},
		{
			title: 'Profit',
			dataIndex: 'sell_profit',
			key: 'sell_profit',
			render: (text, record) => (
				parseFloat(record.sell_profit).toLocaleString()
			),
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.sell_profit),
					p2 = parseFloat(r2.sell_profit);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			}
		}
		// {
		// 	title: 'Pay Rate',
		// 	dataIndex: 'invoice.data.exchange_rate',
		// 	key: 'invoice.data.exchange_rate',
		// 	render: (text, record) => <span>{record.invoice.data.exchange_rate}</span>
		// },
		// {
		// 	title: 'Spot Rate',
		// 	dataIndex: 'invoice.data.market_rate',
		// 	key: 'invoice.data.market_rate',
		// 	render: (text, record) => <span>{record.invoice.data.market_rate || 'NA'}</span>
		// },
		// {
		// 	title: 'Type',
		// 	dataIndex: 'is_sell',
		// 	key: 'is_sell',
		// 	render: (_, report) => (report.is_sell ? <Tag color="gold">Sell</Tag> : <Tag color="green">Buy</Tag>),
		// 	sorter: (c1, c2) => c1 > c2
		// },
		// {
		// 	title: <span className="text-center">Profit $</span>,
		// 	dataIndex: 'profit',
		// 	key: 'profit',
		// 	sorter: (c1, c2) => parseFloat(c1) > parseFloat(c2),
		// 	render: profit => <span className="text-center">{Math.round(parseFloat(profit) * 100000) / 100000}</span>
		// },
		// {
		// 	title: <span className="text-center">Action</span>,
		// 	render: (_, report) =>
		// 		report.invoice && (
		// 			<Button onClick={viewInvoice(report.invoice)} type="primary">
		// 				View Invoice
		// 			</Button>
		// 		)
		// }
	];

	componentDidUpdate() {
		fixAutocomplete();
	}

	onSearch = async () => {
		this.setState({ loading: true, ready: false });
		let data;
		let { fromDate, toDate } = this.state.filter;

		try {
			data = await reportsService.getProfitReports({
				from: fromDate ? fromDate.format('YYYY-MM-DD') : undefined,
				to: toDate ? toDate.format('YYYY-MM-DD') : undefined
				// withInvoices: 1
			});
			if (!data.success) throw new Error(data.message);
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}
		data = Object.keys(data.data).map(key => data.data[key]);
		console.log(data);

		this.setState({
			data: data,
			display: data,
			ready: true,
			loading: false,
			filter: {
				type: null,
				currency: null,
				shop: null
			}
		});
	};

	filterByType = type => report => report.is_sell === (type.toLowerCase() === 'sell' ? 1 : 0);

	filterByCurrency = currency => report => report.invoice.data.currency.code === currency;

	filterByShop = shop => report => report.invoice.shop_id === shop;

	filterReports = filters => {
		return Object.keys(this.state.data)
			.sort()
			.reduce((agg, date) => {
				let dayInvoices = this.state.data[date];
				console.log(filters);
				Object.keys(filters).forEach(filter => {
					let func = this[`filterBy${titlize(filter)}`];
					console.log(func);
					if (!func || filters[filter] === null) return dayInvoices;
					func = func.bind(this);
					dayInvoices = dayInvoices.filter(func(filters[filter]));
				});
				return { ...agg, [date]: dayInvoices };
			}, {});
	};

	render() {
		return (
			<Spin spinning={this.state.loading}>
				<Row className="p-2">
					<span className="mr-5">
						<span style={{ fontWeight: '500' }}> From </span>
						<DatePicker
							value={this.state.filter.fromDate}
							format="YYYY-M-D"
							onChange={val => {
								if (!val) return this.setState({ ready: false });
								let toDate = this.state.filter.toDate;
								if (toDate && toDate <= val) {
									message.error('From date must be before to date.');
									val = '';
								}
								this.setState({ filter: { ...this.state.filter, fromDate: val } });
							}}
						/>
					</span>
					<span className="mr-5">
						<span style={{ fontWeight: '500' }}> To </span>
						<DatePicker
							value={this.state.filter.toDate}
							format="YYYY-M-D"
							onChange={val => {
								if (!val) return this.setState({ ready: false });
								let fromDate = this.state.filter.fromDate;
								if (fromDate && val <= fromDate) {
									message.error('To date must be after from date.');
									val = '';
								}
								this.setState({ filter: { ...this.state.filter, toDate: val } });
							}}
						/>
					</span>
					<span className="mr-5">
						<Button
							type="primary"
							onClick={this.onSearch}
							disabled={!(this.state.filter.toDate && this.state.filter.fromDate)}
						>
							Search
						</Button>
					</span>
				</Row>
				{/* {this.state.ready && (
					<Row className="my-3">
						<Col xs={8}>
							<span>Type: </span>
							<Select
								placeholder="Select report type"
								allowClear
								className="w-75"
								onChange={val => {
									if (val === 0 || !val) {
										this.setState({ display: this.state.data });
									} else
										this.setState({
											display: this.filterReports({
												...this.state.filter,
												type: val === 1 ? 'Sell' : 'Buy'
											}),
											filter: {
												...this.state.filter,
												type: val === 1 ? 'Sell' : 'Buy'
											}
										});
								}}
							>
								<Select.Option value={0}>All</Select.Option>
								<Select.Option value={1}>Sell</Select.Option>
								<Select.Option value={2}>Buy</Select.Option>
							</Select>
						</Col>
						<Col xs={8}>
							<span>Currency: </span>
							<Select
								allowClear
								onChange={val => {
									this.setState({
										display: val
											? this.filterReports({
													...this.state.filter,
													currency: val
											  })
											: this.state.data,
										filter: {
											...this.state.filter,
											currency: val
										}
									});
									// setFromCurrency(e.target.value);
								}}
								className="w-75"
								placeholder="Select Currency"
								showSearch
								filterOption={filterOpts}
							>
								{Object.keys(currencies).map(c => (
									<Select.Option value={c} key={currencies[c]}>
										<span>{c}</span>
									</Select.Option>
								))}
							</Select>
						</Col>
						{this.props.dashboard && (
							<Col xs={8}>
								<span>Shop: </span>
								<Select
									allowClear
									onChange={val => {
										this.setState({
											display: val
												? this.filterReports({
														...this.state.filter,
														shop: val
												  })
												: this.state.data,
											filter: {
												...this.state.filter,
												shop: val
											}
										});
										// setFromCurrency(e.target.value);
									}}
									className="w-75"
									placeholder="Select Shop"
									showSearch
									filterOption={filterOptsShop}
								>
									{this.state.shops.map(shop => (
										<Select.Option value={shop.id} key={shop.id}>
											{shop.name}
										</Select.Option>
									))}
								</Select>
							</Col>
						)}
					</Row>
				)} */}
				<div
					style={{
						minHeight: '500px',
						textAlign: 'center'
					}}
				>
					{this.state.ready ? (
						<Table columns={this.columns} dataSource={this.state.display} />
					) : (
						<p style={{ fontSize: '18px', fontWeight: '700', padding: '1rem 0' }}>
							Please select time range
						</p>
					)}
				</div>
			</Spin>
		);
	}
}
