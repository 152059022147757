
import React from 'react';
import config from '../../config/config';
import {  Select} from 'antd';

const CurrencyChooser = (probs) => {
	let Currencies = probs.currencies;
	return (
		<Select
			// type="text"
			disabled={probs.loading_spot}
			size="large"
			className="w-100 rounded h-100 country-selection-object"
			onChange={val => {
				probs.SetSelectedFactor(null);
				if(probs.currency!='USD')
					probs.setInputs({ ...probs.inputs, otherCurrency: val});
				probs.onFromCurrencyChange && probs.onFromCurrencyChange(probs.inputs, probs.reset);
			}}
			placeholder="Select Currency"
			//disabled={probs.currency=='USD'}
			value={probs.currency}
			showSearch
			filterOption={probs.filterOpts}
		>
			{Object.keys(Currencies).map(c => (
				<Select.Option value={Currencies[c]['code']} key={Currencies[c]['code']} meta={Currencies[c]['name']} className="w-60">
					<img
						className="list-flag-rounded"
						src={`${config.development.base_url}/flags/${Currencies[c]['code'].substr(0, 2).toLowerCase()}.svg`}
					/>
					<span>{Currencies[c]['code']}<br/><small>{Currencies[c]['name']}</small></span>
				</Select.Option>
			))}
		</Select>
	);
}

export default CurrencyChooser;