import React, { useEffect, useState } from 'react';

const StarterPage = props => {
	useEffect(() => {
		// Update the document title using the browser API
		window.localStorage.clear();
		console.log(localStorage, 'cleared');
		var d = new Date();
		var timeStamp = d.getTime();
		window.chrome.webview &&
			window.chrome.webview.postMessage({ showMessage: 'exchange_login', index: 99, responseTime: timeStamp });
	});

	return <div>DONE</div>;
};

export default StarterPage;
