import ResourceManager from './resource-manager';
import config from '../utils/config';

class SystemRequstsService extends ResourceManager {

	async getSystemRequests() {
		return await this.getResource(config.URIs.get_system_requests);
    }

	async getSystemRequestType(request_type) {
		return await this.getResource(config.URIs.get_system_request_type.replace(/:request_type/g,request_type ));
    }

	async getSystemRequestItem(item_id,item_type) {
		return await this.getResource(config.URIs.get_system_request_item.replace(/:item_id/g,item_id).replace(/:item_type/g,item_type));
	}

	async approveSystemRequestItem(data) {
		const id = data.id;
		return await this.putResource(config.URIs.approve_system_request_item.replace(/:item_id/g,id),data);
	}

	async rejectSystemRequestItem(data) {
		const id = data.id;
		return await this.putResource(config.URIs.reject_system_request_item.replace(/:item_id/g,id),data);
	}

	async getSystemSettings() {
		return await this.getResource(config.URIs.get_system_settings);
	}

	async setSystemSettings(data) {
		return await this.putResource(config.URIs.set_system_settings,data);
	}
}

export default new SystemRequstsService();
