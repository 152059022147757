import ResourceManager from './resource-manager';
import config from '../utils/config';

class BrokersService extends ResourceManager {
	async addNewBrocker(data) {
		return await this.postResource(config.URIs.add_new_broker, data);
	}
	async getBrokers() {
		return await this.getResource(config.URIs.get_brokers);
	}

	async getBroker(id) {
		return await this.getResource(config.URIs.get_broker.replace(/:id/g, id));
	}

	async editBroker(id, data) {
		return await this.putResource(config.URIs.edit_broker.replace(/:id/g, id), data);
	}

	async deleteBroker(brokerID) {
		return await this.deleteResource(config.URIs.delete_broker.replace(/:id/g, brokerID));
	}

	async toggleActivate(brokerID) {
		return await this.getResource(config.URIs.toggle_active_broker.replace(/:id/g, brokerID));
	}
}

export default new BrokersService();
