import React, { useState} from 'react';
import EmployeeService from '../../services/employee.service';

import 'antd/dist/antd.css';
import ContextMenu from '../ContextMenu/ContextMenu'
import { Tree} from 'antd';
import { BankOutlined, DownOutlined,UserOutlined, TeamOutlined  } from '@ant-design/icons';

import './AgentTree.css';
  
    function find(source, id)
    {
        for(let k in source)
        {
            var item = source[k];
            if (item.key == id)
                return item;
            
            if (item.children)
            {
                var subresult = find(item.children, id);
                if (subresult)
                    return subresult;
            }
        }
        return null;
    }
    
    let SelectedagentID = 0;
    let LoadingEmployees = false;
    let LoadedEmployees =  false;
    
    function getAllChildren(shopNode_id,treeData)
    {
        const subTreeData = [];
            
        const shopChild = treeData.filter((node) =>  node.parent_shop_id==shopNode_id);
        if(shopChild.length>0)
            subTreeData.push({
                title: 'Employees',
                psudoAgent: true,
                key: 'ag_'+shopNode_id,
                icon:  <TeamOutlined />,
                children: []
            });
        shopChild.forEach(shopNode => {
            subTreeData.push({
                title: shopNode.name,
                key: 'agc_'+shopNode.id,
                children: getAllChildren(shopNode.id,treeData)
            }); 
        });
        return subTreeData;
    }
      
    function getStartupTree(source)
    {
        let TreeData = [];
        source.treeData.forEach(shopNode => {
            if(shopNode.parent_shop_id==null)
            {
                TreeData.push({
                    title: shopNode.name,
                    key: shopNode.id,
                    children: getAllChildren(shopNode.id,source.treeData)
                })
            }
        });
        return TreeData;
    }
      
function AgentTree(props)
{
   
    const [treeData, setTreeData] = useState(getStartupTree(props));

    const [startupContext, setContext] = useState({data:null,visible: false,x: 0, y: 0});

	const fetchEmployees = async (shop_id) => {
        let resp;
        LoadingEmployees = true;
        try
        {
            let currTreeData = [...treeData];
            let newnode = find(currTreeData,shop_id); 
            let expandedKeys =[];
            let selectedShopId = (newnode.psudoAgent) ? shop_id.replace('ag_','') : shop_id ;
            resp = await EmployeeService.getShopEmployees(selectedShopId);
            resp.data.forEach(newEmp => {
               let  newItem =
                {
                    title: newEmp.full_name,
                    key: 'emp_'+shop_id+'_'+newEmp.id,
                    icon: <UserOutlined />,
                    isLeaf : true
                }
                expandedKeys.push('emp_'+newEmp.id);
                newnode.children.push(newItem);
            });
            currTreeData.expandedKeys = expandedKeys;
            setTreeData(currTreeData);
        }
        catch (e)
        {
            return;
        }
        finally
        {
            LoadingEmployees = false;
            LoadedEmployees = true;
        }
	};

    function onLoadData({ key, children })
    {
        return new Promise((resolve) => {
            if (children && children.length>0) {
                resolve();
                return;
            }
            if(!LoadingEmployees){
                LoadedEmployees = false;
                fetchEmployees(key);
            }
            let waitingLoad = setInterval(function(){
                 if(LoadedEmployees){
                    clearInterval(waitingLoad);
                    resolve();
                }
            }, 500);
        });
    }

    const onSelect = (key,item)=>
    {
        SelectedagentID = item.node.key;
        if(!item.node.isLeaf)
        {
            if(item.node.psudoAgent)
                return;
            props.onSelectedShop(item.node.key);
        }
        else
        {
            let employee_id = SelectedagentID.split('_');
            props.onSelectedEmployee(employee_id[2]);
        }
    }

    let showContextMenu = ClickedEvent => {
        let node = ClickedEvent.node;
        if(node.psudoAgent)
                return;
       if (!startupContext.visible) {

          document.addEventListener(`click`, function onClickOutside() {
            setContext({visible: false});
            document.removeEventListener(`click`, onClickOutside)
          });
        }
        setContext({
            node,
            visible: true,
            x: ClickedEvent.event.clientX - ClickedEvent.event.pageX,
            y: ClickedEvent.event.clientY =  ClickedEvent.event.pageY
        })
      }

    return (treeData.length>0) ?
             <div className="mainTreeContainer">
                <Tree loadData={onLoadData} icon={<BankOutlined />} onRightClick={showContextMenu} showIcon switcherIcon={<DownOutlined />} treeData={treeData} onSelect={onSelect}/>
                <ContextMenu onSelectedEmployee={props.onSelectedEmployee} onSelectedShop={props.onSelectedShop}  node={startupContext}/>
            </div>
             :
             '' ;
};

export default AgentTree;
