import styled from 'styled-components';

export const SummaryListContainer = styled.div`
	width: 100%;
	overflow: hidden;
	overflow-y: auto;
	box-shadow: 0px 0px 21px #f1f0f0;
	border-radius: 20px;
	margin: 12px 0 1px;
	padding: 5px;
	background-color: #fff;
	height: 347px;
`;

export const TransactionSummaryContainer = styled.div`
	width: 100%;
	border-bottom: 1px solid #7b9ba9;
	padding: 0.9rem 0.3rem 0 0;
	position: relative;
	-webkit-transition: background-color 0.3s ease-in-out;
	transition: background-color 0.3s ease-in-out;
	margin-top: 3%;

	& .close-icon {
		position: absolute;
		top: -9%;
		right: 0;
		cursor: pointer;
	}

	& .amount-to-pay {
		background: yellow;
		display: block !important;
		padding: 1px 5px;
	}

	& .summary-content {
		width: 100%;
	}
	& .summary-content div{
		width: 100%;
		display: block;
	}
	& .bill-item{
		border-bottom: 1px dotted #cccccc;
	}
	& .bill-amount{
		float: right;
	}
	&:hover {
		background-color: #f7f6f6;
	}
`;
