import React, { Fragment, useEffect, useState } from 'react';
import systemRequestsService from '../../services/system_requests.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';//<i class="fab fa-creative-commons-nc"></i>
import { faStamp } from '@fortawesome/free-solid-svg-icons';//<i class="fas fa-stamp"></i>
import {  Tooltip, Table,  Modal, Button,message } from 'antd';
import { MinusCircleOutlined, CloseCircleOutlined,InfoCircleOutlined } from '@ant-design/icons';

const EditSystemRequests = props => {
	const [errors, setErrors] = useState({});
	const [visible, setVisible] = useState(false);
	const [selected_id,setSelectedId] = useState(0);
	const [loading, setLoading] = useState(false);
	const [RequestList, setReuestList] = useState([]);
	const [RequestItem, setReuestItem] = useState({});

	useEffect(() => {
		async function fetchData() {
			let resp = await systemRequestsService.getSystemRequestType(props.match.params.request_type);
			let RequestResult = resp.data;
			setReuestList(RequestResult);
		}
		fetchData();
	}, []);

	const showDetails = async (record) => {
		setSelectedId(record.id);
		let resp = await systemRequestsService.getSystemRequestItem(record.trx_id,record.trx_type);
		const RequestItem = resp.data;
		setReuestItem(RequestItem)
		setVisible(true);
	};

	const onApprove = async () => {
		setLoading(true);
		let resp = await systemRequestsService.approveSystemRequestItem({id:selected_id,request_type:'reverse_transaction'});
		let RequestResult = resp.data;
		setReuestList(RequestResult);
		success('Approval');
		onCancel();
	}
	
	const onReject = async () => {
		setLoading(true)
		let resp = await systemRequestsService.rejectSystemRequestItem({id:selected_id,request_type:'reverse_transaction'});
		let RequestResult = resp.data;
		setReuestList(RequestResult);
		success('Rejection');
		onCancel();
	}

	const onCancel = () => {
		setVisible(false);
		setLoading(false);
	}

	const columns = [		
		{
			title: 'Request Note',
            render: (text, record) => {
                const notebuff = JSON.parse(record.request_info)['note'];
                return <span>
					{atob(notebuff)}
				</span>
            }
		},
		{
			title: 'Request Date',
			render: (text, record) => (
				<span>
					{record.date}
				</span>
			)
		},
		{
			title: 'Type',
			render: (text, record) => (
				<span>
					{record.trx_type == 1
						? 'Sell'
						: 'Buy'}
				</span>
			)
		},
		{
			title: <span className="text-center">Actions</span>,
			render: (_, report) =>
					<>
						<Tooltip placement="right" title="Details">
                            <InfoCircleOutlined style={{ fontSize: '19px', color: '#016590' }} onClick={(e) =>{showDetails(report)}} />
						</Tooltip>
					</>
		}
    ];
    
	const convertDate = (sql_datestring) => {
		const date = new Date(sql_datestring);
		const year = date.getFullYear();
		const month = date.getMonth()+1;
		const dt = date.getDate();
		return month+'/' + dt + '/'+year;
	}

	const success = (action) => {
		message.success({
		  content: action+' was Successfully executed',
		  className: 'custom-class',
		  style: {
			marginTop: '20vh',
		  },
		});
	};
	
	return (
		<Fragment>
			<h3 className="h3">Manage Request</h3>
			<Table
				dataSource={RequestList}
				columns={columns}
				size="middle"
				locale={{
					emptyText: 'No Requests'
                }}
			></Table>
			<Modal
				visible={visible}
				title="Title"
				onOk={onApprove}
				onCancel={onCancel}
				footer={[
					<Button key="back" type="Danger" loading={loading} onClick={onApprove}>
						<FontAwesomeIcon type="left" className="icons" icon={faStamp} />Approve
					</Button>,
					<Button key="submit" type="Success" icon={<MinusCircleOutlined />} loading={loading} onClick={onReject}>
						Reject
					</Button>,
					<Button key="link" type="primary" icon={<CloseCircleOutlined />} onClick={onCancel}>
						Cancel
					</Button>,
				]}
			>
				<p>USD Amount: ${ parseFloat(RequestItem.usd_amount).toLocaleString() }</p>
				<p>Forign Amount: {parseFloat(RequestItem.foreign_amount).toLocaleString() } {(RequestItem.currency)? RequestItem.currency.code : ''}</p>
				<p>Date: { RequestItem.date }</p>
				<p>Fees: { RequestItem.fees }</p>
				<p>Rate: { RequestItem.exchange_rate }</p>
				<p>Profit: { parseFloat(RequestItem.profit).toFixed(2) }</p>
			</Modal>
		</Fragment>
	);
};

export default EditSystemRequests;