import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const DayReportsContainer = styled.div`
	width: 100%;
`;

export const DateHeader = ({ date }) => (
	<div className="d-flex justify-content-between">
		<DateBox className="w-100">{date}</DateBox>
	</div>
);
const DateBox = styled.div`
	background-color: #f2f2f2;
	color: #000000;
	border: 1px solid black;
	text-align: center;
	font-size: 18px;
	font-weight: 700;
	padding: 0.5rem;
	border-radius: 1px;
`;

export const ListHeader = styled(Row)`
	background-color: #fafafa;
	padding: 0;
	font-size: 15px;
	font-weight: 500;
	transition: 0.4s;
`;

export const ListHeaderItem = styled(Col)`
	padding: 0.4rem 0;

	&:hover {
		background-color: #f2f2f2;
	}
`;
