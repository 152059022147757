import React, { useState,setState } from 'react';

import * as S from './style';
import './style.css';
import { Radio, Button, Select, Row, Col, Form, Input, DatePicker, message, Spin,Switch } from 'antd';
import moment from 'moment';
import { transfereObject } from '../../utils/objects';
import IDTYPES from '../../constants/id_types';
import ID2TYPES from '../../constants/id2_types';
import BusinessCustomer from './BusinessCustomer';
import Countries from '../../constants/country_states';

const dateFormat = 'M/D/YYYY';
const formItemLayoutThird = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 18 }
	}
};

const formItemLayoutHalf = {
	labelCol: {
		span: 0
		// xs: { span: 24 },
		// sm: { span: 6 },
		// xl: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 18 },
		xl: { span: 22 }
	}
};

const formItemLayoutLong = {
	labelCol: {
		span: 0
		// xs: { span: 24 },
		// sm: { span: 2 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 22 }
	}
};
const CustomerInfoForm = ({
	onSearchKeyChange,
	onCustomerSearch,
	onCustomerTypeChange,
	onNewCustomer,
	onCustomerSelect,
	onCustomerCreate,
	onCustomerUpdate,
	onOperationSubmit,
	searchKey,
	showNewCustomerForm,
	selectedCustomer,
	customers = [],
	fetching,
	FirstIdNeeded = false,
	SecondIdNeeded = false
}) => {
	
	const [IsBusiness, showBusiness] = useState(false);
	const [IsIndividual, showIndividual] = useState(true);
	
	let onShowBusiness = () => {
		let newCType = !IsBusiness;
		onCustomerTypeChange(newCType);
		showBusiness(newCType);
	}

	let onNewCustomerClick = () => {
		showIndividual(true);
		showBusiness(false);
		onNewCustomer();
	}

	return (
		<S.formDiv className="w-100 pb-3">
			<div>
				<div className="col-sm-12 header">
					<h4 style={{ color: '#fff' }}>{IsBusiness ? "Business Customer" : "Customer"}</h4>
				</div>
				<Row gutter={4}>
					<Col md={{ span: 5}} style={{textAlign: 'center'}}>
						<Select
							showSearch
							value={selectedCustomer && selectedCustomer.id}
							style={{ width: '100%'}}
							className="rounded"
							size={'large'}
							bordered
							placeholder="Search for customers"
							optionFilterProp="children"
							onSearch={onCustomerSearch}
							notFoundContent={fetching ? <Spin size="small" />:'non found'}
							defaultValue="Search for customers"
							onChange={onCustomerSelect}
							filterOption={(input, option) =>
								option.props.data && input != '' && option.props.data.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{customers.map(customer => (
								<Select.Option
									key={customer.id}
									value={customer.id}
									data={customer[searchKey]}
								>{IsBusiness ? `${customer.name}` : `${customer.first_name} ${customer.last_name}`}</Select.Option>
							))}
						</Select>
					</Col>
					<Col md={{ span: 5}} style={{textAlign: 'center'}}>
						<Radio.Group
							size="middle"
							onChange={onSearchKeyChange}
							value={searchKey}
							buttonStyle="solid"
							defaultValue="Mobile"
							style={{
								borderRadius: '7px',
								overflow: 'hidden',
								border: '1px solid #e0e0e0',
								textAlign: 'center'
							}}
						>
							<Radio.Button value="mobile" style={{width:'75px'}}>Mobile</Radio.Button>
							<Radio.Button value={IsBusiness ? 'tax_id' : 'id_number'} style={{width:'75px'}}>{IsBusiness ? 'TAX ID' : 'ID'}</Radio.Button>
						</Radio.Group>
					</Col>
					<Col md={{ span: 7}}>
					{
						IsBusiness	
						?  ''
						: <Button className="text-center form-submit col-sm-12 w-100" onClick={onNewCustomer}>
							New Customer
						</Button>
					}
					</Col>
					<Col md={{ span: 7}}>
						<Switch className="toggle col-sm-12 "
									checkedChildren="Business"
									unCheckedChildren="Individual"
									onChange={onShowBusiness}
									checked={IsBusiness}
								>
						</Switch>
					</Col>
				</Row>

				{showNewCustomerForm && (
					<NewCustomerForm
						edit={selectedCustomer}
						customer={selectedCustomer}
						onCustomerCreate={onCustomerCreate}
						onCustomerUpdate={onCustomerUpdate}
						onOperationSubmit={onOperationSubmit}
						idTypes={IDTYPES}
						id2Types={ID2TYPES}
						onShowBusiness={onShowBusiness}
						IsBusiness={IsBusiness}
						FirstIdNeeded={FirstIdNeeded}
						SecondIdNeeded={SecondIdNeeded}
					/>
				)}
			</div>
		</S.formDiv>
	);
};

export default CustomerInfoForm;

const NewCustomerForm = ({
	edit = false,
	customer,
	onOperationSubmit,
	onCustomerCreate,
	onCustomerUpdate,
	idTypes,
	id2Types,
	IsBusiness = false,
	FirstIdNeeded = false,
	SecondIdNeeded = false
}) => {
	const [form] = Form.useForm();
	const [formDirty, setDirty] = React.useState(false);
	const [selectedCountry, setCountry] = React.useState(customer?.country);
	const [issuingCountry, setIssuingCountry] = React.useState(customer?.country);
	const [idIssuingPlaceVisible, showIdIssuingPlace] = React.useState(customer?.id_issuing_place || false);
	const [id2TypeValue, showId2TypeValue] = React.useState(false);
	const [errors, setErrors] = useState({});

  const createMask = (str)=>{
	console.log(str)
	  return str.replace(/(\d{2})(\d{2})(\d{4})/,"$1/$2/$3");
  }
  
  const destroyMask =(str) =>{
	console.log(str)
	  return str.replace(/\D/g,'').substring(0, 8);
  }
	
	React.useEffect(() => {
		let formData = {
			first_name: '',
			name: '',
			zipcode: '',
			tax_id: '',
			middle_name: '',
			last_name: '',
			email: '',
			telephone: '',
			mobile: '',
			id_type: '',
			id_number: '',
			id_expiry_date: '',
			id_start_date: '',
			id_issuing_country: '',
			id_issuing_place: '',
			zip: '',
			city: '',
			country: '',
			state: '',
			address: '',
			birth_date: ''
		};

		if (customer) {
			let data = transfereObject(formData, customer);
			data.id_expiry_date = data.id_expiry_date ? moment(moment(data.id_expiry_date).format(dateFormat)) : '';
			data.id_start_date = data.id_start_date ? moment(moment(data.id_start_date).format(dateFormat)) : '';
			data.birth_date = data.birth_date ? moment(moment(data.birth_date).format(dateFormat)) : '';
			form.setFieldsValue(data);
		} else form.setFieldsValue(formData);
	}, [customer]);

	return (
		<div className="forms">
			<div className="col-sm-12 header">
				<h4 style={{ color: '#fff' }}>
					{!IsBusiness
						? 'Customer Info'
						: 'Business Info'
					}
				</h4>
			</div>
			<div className="inputData">
				<Form
					// className="form-inline"
					form={form}
					labelCol={{
						span: 6
					}}
					wrapperCol={{
						span: 18
					}}

					onValuesChange={(changed, all) => {
						if (changed.country) {
							all = { ...all, city: '' };
							setCountry(changed.country);
						}
						if (changed.id_issuing_country) {
							all = { ...all, id_issuing_place: '' };
							setIssuingCountry(changed.id_issuing_country);
						}
						if (changed.id_type) {
							if (['DRIVING_LICENSE', 'STATE_ID'].indexOf(changed.id_type) >= 0) showIdIssuingPlace(true);
							else showIdIssuingPlace(false);
						}
						form.setFieldsValue(all);
						setDirty(true);
					}}

					onFinish={async values => {
						values = {
							...values,
							birth_date: (IsBusiness || !values.birth_date) ? '' : values.birth_date.format('YYYY-MM-DD'),
							id_expiry_date: (IsBusiness || !values.id_expiry_date) ? '' : values.id_expiry_date.format('YYYY-MM-DD'),
							id_start_date: (IsBusiness || !values.id_start_date) ? '' : values.id_start_date.format('YYYY-MM-DD')
						};
						try {
							if (!edit && !IsBusiness) {
								let success = onCustomerCreate && (await onCustomerCreate(values));
								if (success) onOperationSubmit && (await onOperationSubmit());
							} else {
								if (formDirty && onCustomerUpdate && !IsBusiness)
								{
									let success = await onCustomerUpdate(customer.id, values);
									if (success) onOperationSubmit && (await onOperationSubmit());
								}
								else
								{
									onOperationSubmit && (await onOperationSubmit());
								}
							}
							setDirty(false);
						} catch (e) {
							message.error(e.message);
							console.error(e);
							setErrors(e.errors || {});
							return;
						}
					}}

					initialValues={{
						first_name: '',
						middle_name: '',
						last_name: '',
						email: '',
						telephone: '',
						mobile: '',
						id_type: '',
						id_number: '',
						name: '',
						zipcode: '',
						tax_id: '',
						id_expiry_date: '',
						id_start_date: '',
						id_issuing_country: '',
						id_issuing_place: '',
						zip: '',
						city: '',
						country: 'USA',
						state: '',
						address: '',
						birth_date: '',
						IsBusiness: false
					}}
				>
					
					{IsBusiness && edit ? <BusinessCustomer/>:''}					
					{IsBusiness 
					? ''
					:
					<>
						<Row gutter={8}>
							<Col className=" form-group" sm={24} lg={12} xxl={8}>
								<Form.Item
									{...formItemLayoutHalf}
									label={<label>First Name</label>}
									name="first_name"
									rules={[{ required: true, message: 'Please enter customer first name' }]}
									validateStatus={errors.first_name ? 'error' : null}
									help={errors.first_name ? errors.first_name.map(err => <p>{err}</p>) : null}
								>
									<Input className="form-control col-sm-12" placeholder="First Name" />
								</Form.Item>
							</Col>
							<Col className=" form-group" sm={24} lg={12} xxl={8}>
								<Form.Item
									{...formItemLayoutHalf}
									label={<label>Middle Name</label>}
									name="middle_name"
									validateStatus={errors.middle_name ? 'error' : null}
									help={errors.middle_name ? errors.middle_name.map(err => <p>{err}</p>) : null}
								>
									<Input className="form-control col-sm-12" placeholder="Middle Name" />
								</Form.Item>
							</Col>
							<Col className=" form-group" sm={24} lg={12} xxl={8}>
								<Form.Item
									{...formItemLayoutHalf}
									label={<label>Last Name</label>}
									name="last_name"
									rules={[{ required: true, message: 'Please enter customer last name' }]}
									validateStatus={errors.last_name ? 'error' : null}
									help={errors.last_name ? errors.last_name.map(err => <p>{err}</p>) : null}
								>
									<Input className="form-control col-sm-12" placeholder="Last Name" />
								</Form.Item>
							</Col>
							<Col className=" form-group" sm={24} lg={12} xxl={8}>
								<Form.Item
									{...formItemLayoutHalf}
									label={<label className="col-form-label">Mobile</label>}
									name="mobile"
									rules={[{ required: true, message: 'Please enter customer mobile' }]}
									validateStatus={errors.mobile ? 'error' : null}
									help={errors.mobile ? errors.mobile.map(err => <p>{err}</p>) : null}
								>
									<Input className="form-control col-sm-12" placeholder="Mobile" />
								</Form.Item>
							</Col>
						</Row>						
						{
							!FirstIdNeeded
							? ''
							:
							<>
								<Row gutter={8}>
									<Col className=" form-group" sm={24} lg={12}>
										<Form.Item
											{...formItemLayoutHalf}
											// labelCol={{ span: 24 }}/
											label={<label>E-mail</label>}
											name="email"
											rules={[{ type: 'email', message: 'Not valid email' }]}
											validateStatus={errors.email ? 'error' : null}
											help={errors.email ? errors.email.map(err => <p>{err}</p>) : null}
										>
											<Input className="form-control col-sm-12" placeholder="Email" />
										</Form.Item>
									</Col>
									<Col className="form-group" sm={24} lg={12}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label>Day of Birth</label>}
											name="birth_date"
											rules={[{ required: true, message: 'Please enter customer birth date' }]}
											validateStatus={errors.birth_date ? 'error' : null}
											help={errors.birth_date ? errors.birth_date.map(err => <p>{err}</p>) : null}
										>
											<DatePicker
												format={dateFormat}
												className="form-control"
												placeholder="Date of Birth"
												style={{ borderRadius: '10px' }}
											//onKeyDown={() =>alert(111)}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col className="form-group" sm={24} lg={24}>
										<Form.Item
											{...formItemLayoutLong}
											label={<label className="col-form-label col-sm-4">Address</label>}
											name="address"
											rules={[{ required: true, message: 'Please enter customer address' }]}
											validateStatus={errors.address ? 'error' : null}
											help={errors.address ? errors.address.map(err => <p>{err}</p>) : null}
										>
											<Input className="form-control col-sm-20" placeholder="Address" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col className="form-group" sm={24} lg={10}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label className="col-form-label">Country</label>}
											name="country"
											rules={[{ required: true, message: 'Please enter customer country' }]}
											validateStatus={errors.country ? 'error' : null}
											help={errors.country ? errors.country.map(err => <p>{err}</p>) : null}
											initialValue={{ value: 'United States' }}
										>
											<Select
												className="rounded w-100"
												placeholder="Country"
												showSearch
												filterOption={(input, option) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
												defaultValue='United States'
											>
												{Object.keys(Countries).map(c => (
													<Select.Option value={c} key={c}>
														{c}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col className="form-group" sm={24} lg={{ span: 10, offset: 4 }}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label className="col-form-label">State</label>}
											name="state"
											rules={[{ required: true, message: 'Please enter customer state' }]}
											validateStatus={errors.state ? 'error' : null}
											help={errors.state ? errors.state.map(err => <p>{err}</p>) : null}
										>
											<Select
												className="rounded w-100"
												showSearch
												filterOption={(input, option) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{selectedCountry &&
													Countries[selectedCountry].map(s => (
														<Select.Option value={s} key={s}>
															{s}
														</Select.Option>
													))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col className=" form-group" sm={24} lg={10}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label className="col-form-label">City</label>}
											name="city"
											rules={[{ required: true, message: 'Please enter customer city' }]}
											validateStatus={errors.city ? 'error' : null}
											help={errors.city ? errors.city.map(err => <p>{err}</p>) : null}
										>
											<Input className="form-control col-sm-12" name="city" placeholder="City" />
										</Form.Item>
									</Col>
									<Col className="form-group" sm={24} lg={{ span: 10, offset: 4 }}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label className="col-form-label">Zip</label>}
											name="zip"
											rules={[{ required: true, message: 'Please enter customer zip' }]}
											validateStatus={errors.zip ? 'error' : null}
											help={errors.zip ? errors.zip.map(err => <p>{err}</p>) : null}
										>
											<Input className="form-control col-sm-12" placeholder="Zip" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col className=" form-group" sm={24} lg={10}>
										<Form.Item
											{...formItemLayoutHalf}
											label={
												<label className="col-form-label" name="id_type">
													ID Type
												</label>
											}
											name="id_type"
											rules={[{ required: true, message: 'Please enter customer id type' }]}
											validateStatus={errors.id_type ? 'error' : null}
											help={errors.id_type ? errors.id_type.map(err => <p>{err}</p>) : null}
										>
											<Select
												showSearch
												filterOption={(input, option) =>
													option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
												className="rounded w-100"
												size="middle"
											>
												{idTypes.map(type => (
													<Select.Option value={type} key={type}>
														{type}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col className="form-group" sm={24} lg={{ span: 10, offset: 4 }}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label className="col-form-label">ID Number</label>}
											name="id_number"
											rules={[{ required: true, message: 'Please enter customer id number' }]}
											validateStatus={errors.id_number ? 'error' : null}
											help={errors.id_number ? errors.id_number.map(err => <p>{err}</p>) : null}
										>
											<Input className="form-control col-sm-12" placeholder="ID Number" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col className=" form-group" sm={24} lg={10}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label className="col-form-label">ID Issuing Country</label>}
											name="id_issuing_country"
											defaultValue="United States"
											rules={[{ required: true, message: 'Please enter customer id issuing country' }]}
											validateStatus={errors.id_issuing_country ? 'error' : null}
											help={
												errors.id_issuing_country
													? errors.id_issuing_country.map(err => <p>{err}</p>)
													: null
											}
										>
											<Select
												className="rounded w-100"
												placeholder="ID Issuing Country"
												showSearch
												filterOption={(input, option) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{Object.keys(Countries).map(c => (
													<Select.Option value={c} key={c}>
														{c}
													</Select.Option>
												))}
											</Select>
											{/* <Input className="form-control col-sm-12" placeholder="ID Issuing Country" /> */}
										</Form.Item>
									</Col>

									<Col className="form-group" sm={24} lg={{ span: 10, offset: 4 }}>
										{idIssuingPlaceVisible && (
											<Form.Item
												{...formItemLayoutHalf}
												labelCol={{ span: 7 }}
												wrapperCol={{ span: 17 }}
												label={<label className="col-form-label">ID Issuing Place</label>}
												name="id_issuing_place"
												rules={[{ required: true, message: 'Please enter customer id issuing place' }]}
												validateStatus={errors.id_issuing_place ? 'error' : null}
												help={
													errors.id_issuing_place
														? errors.id_issuing_place.map(err => <p>{err}</p>)
														: null
												}
											>
												<Select
													className="rounded w-100"
													showSearch
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
													placeholder="ID Issuing Place"
												>
													{issuingCountry &&
														Countries[issuingCountry].map(s => (
															<Select.Option value={s} key={s}>
																{s}
															</Select.Option>
														))}
												</Select>
												{/* <Input className="form-control col-sm-12" placeholder="ID Issuing Place" /> */}
											</Form.Item>
										)}
									</Col>
								</Row>

								<Row gutter={8}>
									<Col className=" form-group" sm={24} lg={10}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label className="col-form-label">ID Start Date</label>}
											name="id_start_date"
											rules={[{ required: true, message: 'Please enter customer id start date' }]}
											validateStatus={errors.id_start_date ? 'error' : null}
											help={errors.id_start_date ? errors.id_start_date.map(err => <p>{err}</p>) : null}
										>
											<DatePicker
												format={dateFormat}
												className="form-control col-sm-12"
												placeholder="ID Start Date"
												style={{ borderRadius: '10px' }}
											/>
										</Form.Item>
									</Col>
									<Col className="form-group" sm={24} lg={{ span: 10, offset: 4 }}>
										<Form.Item
											{...formItemLayoutHalf}
											label={<label className="col-form-label">ID Expiry Date</label>}
											name="id_expiry_date"
											rules={[
												{
													required: true,
													message: 'Please enter customer id expiry date'
												},
												{
													validator: (rule, value, callback) => {
														if (moment(new Date()).isAfter(value))
															return callback('Expiry date must be in the future');
														return callback();
													}
												}
											]}
											validateStatus={errors.id_expiry_date ? 'error' : null}
											help={errors.id_expiry_date ? errors.id_expiry_date.map(err => <p>{err}</p>) : null}
										>
											<DatePicker
												format={dateFormat}
												className="form-control col-sm-12"
												placeholder="ID Expiry Date"
												style={{ borderRadius: '10px' }}
											/>
										</Form.Item>
									</Col>
								</Row>
								{
									!SecondIdNeeded
									? ''
									:
									<>					
										<Row gutter={8}>
											<Col className=" form-group" sm={24} lg={10}>
												<Form.Item
													{...formItemLayoutHalf}
													label={
														<label className="col-form-label" name="id_type">
															ID 2 Type
														</label>
													}
													name="id2_type"
													rules={[{ required: true, message: 'Please enter customer Second id type' }]}
													validateStatus={errors.id2_type ? 'error' : null}
													help={errors.id_type ? errors.id2_type.map(err => <p>{err}</p>) : null}
												>
													<Select
														showSearch
														filterOption={(input, option) =>
															option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
														}
														onChange={(e) => {
															showId2TypeValue(e !== 'SSN')
														}}
														className="rounded w-100"
														size="middle"
													>
														{id2Types.map(type => (
															<Select.Option value={type} key={type}>
																{type}
															</Select.Option>
														))}
													</Select>
												</Form.Item>
											</Col>
											<Col className="form-group" sm={24} lg={{ span: 10, offset: 4 }}>
												<Form.Item
													{...formItemLayoutHalf}
													label={<label className="col-form-label">ID 2 Number</label>}
													name="id2_number"
													rules={[{ required: true, message: 'Please enter Second customer id number' }]}
													validateStatus={errors.id2_number ? 'error' : null}
													help={errors.id2_number ? errors.id2_number.map(err => <p>{err}</p>) : null}
												>
													<Input className="form-control col-sm-12" placeholder="Second IDNumber" />
												</Form.Item>
											</Col>
											<Col className="form-group" sm={24} lg={{ span: 10, offset: 4 }}>
																							
											</Col>
										</Row>
										{
											(!id2TypeValue)
											? ''
											:
											<Row gutter={8}>
												<Col className=" form-group" sm={25} lg={8}>
													<Form.Item
														{...formItemLayoutHalf}
														label={<label className="col-form-label">Issuing Country</label>}
														name="id2_issuing_country"
														rules={[{ required: true, message: 'Please enter Second id issuing country' }]}
														validateStatus={errors.id2_issuing_country ? 'error' : null}
														help={
															errors.id2_issuing_country
																? errors.id2_issuing_country.map(err => <p>{err}</p>)
																: null
														}
													>
														<Select
															className="rounded w-100"
															placeholder="Second ID Issuing Country"
															showSearch
															defaultValue="United States"
															filterOption={(input, option) =>
																option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
															}
														>
															{Object.keys(Countries).map(c => (
																<Select.Option value={c} key={c}>
																	{c}
																</Select.Option>
															))}
														</Select>
													</Form.Item>
												</Col>
												<Col className="form-group" sm={24} lg={{ span: 10, offset: 4 }}>
													<Form.Item
														{...formItemLayoutHalf}
														label={<label className="col-form-label">ID 2 Expiry</label>}
														name="id2_expiry_date"
														rules={[
															{
																required: true,
																message: 'Please enter Second id expiry date'
															},
															{
																validator: (rule, value, callback) => {
																	if (moment(new Date()).isAfter(value))
																		return callback('Expiry date must be in the future');
																	return callback();
																}
															}
														]}
														validateStatus={errors.id2_expiry_date ? 'error' : null}
														help={errors.id2_expiry_date ? errors.id2_expiry_date.map(err => <p>{err}</p>) : null}
													>
														<DatePicker
															format={dateFormat}
															className="form-control col-sm-12"
															placeholder="Second ID Expiry Date"
															style={{ borderRadius: '10px' }}
														/>
													</Form.Item>
												</Col>
											</Row>
										}
									</>
								}
							</>
						}
					</>	
				}
					<Row className="justify-content-sm-center">
						<Col sm={5}>
							<div>
								<button type="submit" className="text-center form-submit">
									Submit Operation
								</button>
							</div>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};
