export function formatDashedNumber(num) {
	return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function titlize(str) {
	let charArr = str.split('');
	return charArr[0].toUpperCase() + charArr.slice(1).join('');
}

export function makePinCode(length) {
	var result = '';
	var characters = '0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}
