import { createContext } from 'react';
import { useContext } from 'react';

const AuthenticationContext = createContext({ authenticated: false });

export default AuthenticationContext;

export function useAuth() {
	return useContext(AuthenticationContext);
}
