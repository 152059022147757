import React, {  useState } from 'react';
import { Input,  Select, } from 'antd';
import { DeleteOutlined} from '@ant-design/icons';


const AgentSlabObject = props => {

    
	const [SlabObjectData, setSlabObjectDataSource] = useState(props.data);

    const selectAfter = (data) => {
        return(
            <Select
                defaultValue={data.val} onChange={(e) => {
                    setSlabObjectData({ var: data.var, val: e });
                    }
                }
                className="select-after"
            >
          <Select.Option className="bluelight" value="ratio"> % </Select.Option>
          <Select.Option className="greenlight" value="fixed">+/-</Select.Option>
        </Select>)
    };
    
    const setSlabObjectData = (data) =>
    {
        const tempSlabObjectData = {...SlabObjectData};
        tempSlabObjectData[data.var] = data.val;
        setSlabObjectDataSource(tempSlabObjectData);
        updateItem(tempSlabObjectData);
    }

    const removeItem = () => {
        props.removeSlabItem({id:props.id, idx:props.idx});
    }

    const updateItem = (tempSlabObjectData) => {
        const newData = {
            id: props.id,
            idx: props.idx,
            data:
            {
                boundary: tempSlabObjectData.boundary,
                fees_buy_value: tempSlabObjectData.fees_buy_value,
                fees_sell_value: tempSlabObjectData.fees_sell_value,
                rate_buy_value: tempSlabObjectData.rate_buy_value,
                rate_sell_value: tempSlabObjectData.rate_sell_value,
                fees_buy_type: tempSlabObjectData.fees_buy_type,
                fees_sell_type: tempSlabObjectData.fees_sell_type,
                rate_buy_type: tempSlabObjectData.rate_buy_type,
                rate_sell_type: tempSlabObjectData.rate_sell_type
            }
        }
        props.updateSlabObject(newData)
    }

    return (
        <div>
            <style>{`
                .ant-select-item-option-active.greenlight,.ant-select-item-option.greenlight,.ant-select-selector.greenlight{
                    background-color: #bdf4cd;
                }
                .ant-select-item-option-active.bluelight,.ant-select-item-option.bluelight,.ant-select-selector.bluelight{
                     background-color: #e2eff6;
                }
            `}</style>
            <span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center"><Input value={SlabObjectData.boundary} addonBefore="$" onChange={(e) => { setSlabObjectData({var:'boundary',val:e.target.value});}} className="d-inline-block"/></span>
            <span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center"><Input value={SlabObjectData.fees_buy_value} onChange={(e) => { setSlabObjectData({var:'fees_buy_value',val:e.target.value});}} addonAfter={selectAfter({val:SlabObjectData.fees_buy_type,var:'fees_buy_type'})} className="d-inline-block"/></span>
            <span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center"><Input value={SlabObjectData.fees_sell_value} onChange={(e) => { setSlabObjectData({var:'fees_sell_value',val:e.target.value});}} addonAfter={selectAfter({val:SlabObjectData.fees_sell_type,var:'fees_sell_type'})} className="d-inline-block" /></span>
            <span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center"><Input value={SlabObjectData.rate_buy_value} onChange={(e) => { setSlabObjectData({var:'rate_buy_value',val:e.target.value});}} addonAfter={selectAfter({val:SlabObjectData.rate_buy_type,var:'rate_buy_type'})} className="d-inline-block"/></span>
            <span style={{ width: '19%' }} className="d-inline-block bg-info text-white text-center"><Input value={SlabObjectData.rate_sell_value} onChange={(e) => { setSlabObjectData({var:'rate_sell_value',val:e.target.value});}} addonAfter={selectAfter({val:SlabObjectData.rate_sell_type,var:'rate_sell_type'})} className="d-inline-block" /></span>
            <span style={{ width: '5%' }} className="d-inline-block text-white text-center border-left"><DeleteOutlined style={{ cursor: 'pointer' }} onClick={removeItem} className="bg-danger text-white text-center p-1"/></span>
        </div>
    )
};

export default AgentSlabObject;