import React from 'react';
import { InvoiceCopy } from './InvoiceCopy';

export function Invoice({ invoice }) {
	return (
		<html>
			<head>
				<link href="/css/all.min.css" rel="stylesheet" type="text/css" />
				<style>
					{`
					.currency-image
					{
						float:right;
					}

					table img {display: block !important;}

					@media print
					{
						@page {
							font-size:10pt;font-family:arial;
							margin: 3px;
						}
						html {margin:0;font-size:10pt;font-family:arial;}
						table {page-break-after:always;font-size:11pt;font-family:arial;}
						table td{font-size:10pt;font-family:arial;}
					}`
					}
				</style>
			</head>
			<body>
				<InvoiceCopy invoice={invoice} terms="no" version="1"/>
				<hr style={{width:'247px',border:'0',borderTop:'dashed #5555ff 1px',margin:'27px 0'}}/>
				<InvoiceCopy invoice={invoice} terms="no" version="2"/>
			</body>
		</html>
	);
}
