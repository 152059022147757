import React from 'react';

import { message, Spin, Row, DatePicker, Button, notification } from 'antd';
import reportsService from '../../services/reports.service';
import { FileExcelTwoTone  } from '@ant-design/icons'
import '../ProfitReport/style.css';


export default class FiatReportContainer extends React.Component {
	
	state = {
		data: {},
		loading: false,
		loadingButton: false,
		ready: false,
		quarter: ''
	};
	    
	onSearch = async () => {
		this.setState({ loading: true, ready: false });
		let data;
		const quarter = this.state.quarter.split('-');
		const year = quarter[0];
		const Q = (quarter[1].substring(2, 1));
		try {
			data = await reportsService.getFiatReports({year,Quarter:Q});
			if (!data.success) throw new Error(data.message);
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}
		data = data.data;

		this.setState({
			data: data,
			display: data,
			ready: true,
			loading: false
		});
	};

	onSelectQuarter = (date, dateString) => {
		this.setState({ quarter: dateString },this.onSearch);
	}

    onExport = async () => {
		this.setState({ loading: true, ready: false });
		const quarter = this.state.quarter.split('-');
		const year = parseInt(quarter[0]);
		const Q = parseInt(quarter[1].substring(2, 1));
		try {
			reportsService.exportFiatReports({year,Quarter:Q})
            .then(()=>{
                this.openNotificationWithIcon('success','Exported Successfuly','bottomRight')
                this.setState({loading : false });
            });
		} catch (e) {
            this.openNotificationWithIcon('error',e.message,'bottomRight')
			message.error(e.message);
			console.error(e);
			return this.setState({loading : false });
		}
    };

    openNotificationWithIcon = (type,msg,placement) => {
        notification[type]({
            message: 'Execution Result',
            description: msg,
            placement
        });
    };
    
	stateRows = () => {

		const StateRows = [''];
		this.state.data.forEach((row) => {
			StateRows.push(
				<div>
					<div>row.state</div>
					<div>ST 150</div> <div># of currency transactions completed in-state</div> <div>row.cnt</div>
					<div>ST 160</div> <div>Amount of currency transactions completed in-state</div> <div>row.amount</div>
					<div>ST 170</div> <div>Amount of fees collected in-state</div> <div>row.fees</div>
				</div>);
		});
		return StateRows;
	}

	render() {
		return (
            <Spin spinning={this.state.loading}>
                <Row className="p-2">
                    <span className="mr-5">
                        <span style={{ fontWeight: '500' }}> Date Range </span>
                        <DatePicker size="small" onChange={this.onSelectQuarter} picker="quarter" />
                    </span>
					<span className="mr-5">
						<Button
							size="small"
							type="primary"
							onClick={this.onSearch}
						>
							Search
						</Button> 
						<Button size="small" type="warning" style={{ minWidth: '215px' }} icon={<FileExcelTwoTone />} htmlType="button" loading={this.loadingButton} onClick={this.onExport}>
                            Export to Excel
                        </Button>
					</span>
                </Row>
				<div
					style={{
						minHeight: '500px',
						textAlign: 'center'
					}}
				>
				{this.state.ready ?
					this.state.data.map((item, index) => {
						return (<div className="fial-list-block" >
							<div className="fial-list-header">{item.state}</div>
							<div>
								<div className="fial-list-item"><span>{item.state === 'All States' ? 'TA' : 'ST'} 150</span> <span># of currency transactions completed {item.state === 'All States' ? '' : 'in-state '} </span> <span>{item.cnt}</span></div>
								<div className="fial-list-item"><span>{item.state === 'All States' ? 'TA' : 'ST'} 160</span> <span>Amount of currency transactions completed {item.state === 'All States' ? '' : 'in-state '}in-state</span> <span>{item.amount}</span></div>
								<div className="fial-list-item"><span>{item.state === 'All States' ? 'TA' : 'ST'} 170</span> <span>Amount of fees collected {item.state === 'All States' ? '' : 'in-state '}in-state</span> <span>{item.fees}</span></div>
							</div>
						</div>);
					})
					: (
					<p style={{ fontSize: '18px', fontWeight: '700', padding: '1rem 0' }}>
							Please select a Quarter
					</p>
					)
				}
				</div>
			</Spin>
		);
	}
}