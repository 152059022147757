import ResourceManager from './resource-manager';
import config from '../utils/config';

class StockService extends ResourceManager {
	async getShopStock() {
		return await this.getResource(config.URIs.get_shop_available_stock);
	}

	async updateStockStatus(params)
	{
		return await this.postResource(config.URIs.set_shop_available_stock,params);
	}

}

export default new StockService();
