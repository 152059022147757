import React, { useState, useEffect } from 'react';
import roleService from '../../services/role.service';
import { useHistory, useParams } from 'react-router-dom';

import { Form, Input, Button, Select, message, Spin } from 'antd';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

const RoleForm = props => {
	let { id: roleId } = useParams();

	const [form] = Form.useForm();
	const [role, setRole] = useState();
	const [unmounted, setUnmounted] = useState(false);
	let history = useHistory();
	const [permission, setPermission] = useState([]);
	const [loading, setLoading] = useState(!!roleId);
	const [errors, setErrors] = useState({});

	const fetchRole = async id => {
		if (id) {
			setLoading(true);
			try {
				var resp = await roleService.getRole(id);
				if (!resp.key) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}
			setRole(resp.data);
			form.setFieldsValue(resp.data);
			setLoading(false);
		}
	};

	const fetchPermissions = async () => {
		try {
			var resp = await roleService.getPermissions();
			if (!resp.key) throw new Error('There is an error please try again!');
		} catch (e) {
			message.error(e.message);
			console.error(e);
			setErrors(e.errors || {});
			return;
		}
		setPermission(resp.data);
	};

	useEffect(() => {
		if (!unmounted) fetchPermissions();
		return () => setUnmounted(true);
	}, []);

	useEffect(() => {
		if (!unmounted) fetchRole(roleId);
	}, [roleId]);

	const permissions = permission.map(permission => {
		return <Option value={permission.id}>{permission.display_name}</Option>;
	});

	const onFinish = async values => {
		setErrors({});
		if (roleId) {
			try {
				let resp = await roleService.editRole(roleId, values);
				if (!resp.key) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}
			message.success('role was updated successfully');
		} else {
			try {
				let resp = await roleService.addNewRole(values);
				if (!resp.key) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error('there is an error please try again');
				console.log(e);
				setErrors(e.errors || {});
				return;
			}
			message.success('Role was Added successfully!');
			// form.resetFields();
		}
		return history.push({
			pathname: '/dashboard/list-roles',
			data: role,
			state: { data: role, id: roleId }
		});
	};

	const onReset = () => {
		setErrors({});
		form.resetFields();
	};

	return (
		<Spin spinning={loading}>
			{roleId ? <h3 className="h3">Edit Role</h3> : <h3 className="h3">Add New Role</h3>}
			<Form
				{...layout}
				form={form}
				name="control-hooks"
				onFinish={onFinish}
				initialValues={{
					name: role ? role.name : null,
					description: role ? role.description : null,
					display_name: role ? role.display_name : null,
					permissions: role ? role.permissions : []
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors.name ? 'error' : null}
					help={errors.name ? errors.name.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="display_name"
					label="Display Name"
					validateStatus={errors.display_name ? 'error' : null}
					help={errors.display_name ? errors.display_name.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="description"
					label="Discription"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors.description ? 'error' : null}
					help={errors.description ? errors.description.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="permissions"
					label="Permissions"
					rules={[{ required: true, message: 'Please select the Permissions!', type: 'array' }]}
					validateStatus={errors.permissions ? 'error' : null}
					help={errors.permissions ? errors.permissions.map(err => <p>{err}</p>) : null}
				>
					<Select
						mode="multiple"
						placeholder="Please select Permissions"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) > -1
						}
					>
						{permissions}
					</Select>
				</Form.Item>

				<Form.Item {...tailLayout}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
					<Button htmlType="button" onClick={onReset}>
						Reset
					</Button>
				</Form.Item>
			</Form>
		</Spin>
	);
};

export default RoleForm;
