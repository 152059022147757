import React from "react"

import {UserAddOutlined,UsergroupAddOutlined,TrademarkCircleOutlined} from '@ant-design/icons';
import './ContextMenu.css'

const contextMenu = (props) => 
{
  let record, visible, x, y;
  record = props.node.node;
  x = props.node.x;
  y = props.node.y;
  visible = props.node.visible;

  let editLinck = () =>
  {

    let SelectedagentID = record.key;
    if(!record.isLeaf)
    {
        props.onSelectedShop(SelectedagentID);
    }
    else
    {
        let employee_id = SelectedagentID.split('_');
        props.onSelectedEmployee(employee_id[2]);
    }
  }



  return (visible ?
  <ul className="popup" style={{left: `${x}px`, top: `${y}px`}}>
    <li><TrademarkCircleOutlined /> {record.title} </li>
    <li onClick={editLinck}><UserAddOutlined /> Edit </li>
  </ul> : '' );
}

/** FUTURE USAGE
<ul className="popup" style={{left: `${x}px`, top: `${y}px`}}>
    <li><TrademarkCircleOutlined /> {record.title} </li>
    <li><UserAddOutlined /> Add Employee</li>
    <li><UsergroupAddOutlined /> Add Agent</li>
  </ul>
*/

export default contextMenu