import React, { useState,useEffect } from 'react';

import * as S from './styles';
import { InputNumber, Spin, Tooltip, Switch } from 'antd';
import { LoadingOutlined,CheckSquareOutlined,CloseSquareOutlined } from '@ant-design/icons';

import CurrencyService from '../../services/currency.service';

import authService from '../../services/auth.service';
import permissions from '../../constants/permissions';
import CurrencyChooser from './CurrencyChooser';
import NumberFormat from 'react-number-format'

const filterOpts = (input, option) =>
	option.props.meta.toLowerCase().startsWith(input.toLowerCase()) ||
	option.props.value.toLowerCase().startsWith(input.toLowerCase());

const ConvertAmountForm = ({
	user,
	fromCurrency,
	toCurrency,
	onConvert,
	showResult,
	onAmountChange,
	onFromCurrencyChange,
	onResetPresets,
	onFessIncludedChange,
	onInHandChange,
	onToCurrencyChange,
	onAddNewOperation,
	onChangeFactor,
	editFactorShow,
	SetSelectedFactor,
	onFactorCancel,
	onFactorSubmit,
	feesIncluded,
	amountInHand,
	exchangeRate,
	spot_rate,
	rate_calc,
	loading_spot,
	specified,
	can_edit_rate,
	isSell,
	...restProps
}) => {
	const [inputs, setInputs] = useState({
		amount: '',
		sell_amount: '',
		buy_amount: '',
		specified: false,
		otherCurrency: user.shop_info.default_currency,
		isSell: isSell,
		amountInUSD: true
	});
	
	const [currencies, currencyData] = useState([]);
	
	useEffect(() => {
		async function fetchData() {
			let currencyResult = await CurrencyService.getCurrencies();
			currencyData(currencyResult.data);
		}
		fetchData();
	}, []);

	const reset = () => {
		setInputs({ amount: '',sell_amount: '',buy_amount: '', otherCurrency: user.shop_info.default_currency, isSell: true, amountInUSD: true,specified: false});
	};

	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	return (
		<S.convertAmountDiv>
			<div className="form-container">
				<div className={inputs.isSell ? "inputs col-sm-12" : 'inputs col-sm-12 buy'}>
					<form
						onSubmit={e => {
							e.preventDefault();
							SetSelectedFactor(null);
							onConvert(inputs, reset);
						}}
					>	
						<div className="row col-lg-12 align-middle"><div className="col-lg-6 align-middle white">FROM</div><div className="col-lg-6 align-middle white">THE CUSTOMER GETS</div></div>
						<div className="row col-lg-12">
							<div className="col-lg-5 amount-currency-bg country-select">
								<div className="col-lg-12 amount-input-block">
									<Tooltip title="Amount In">
										<NumberFormat  
											thousandSeparator={true}
											prefix={''}
											min={1}
											size="large"
											style={{ borderRadius: '9px' }}
											// type="text"
											onChange={val => {
												const inputdata = val.currentTarget.value.replace(/,/g,'')
												let buy_amount = inputs.isSell ?  '' : inputdata ;
												let sell_amount = inputs.isSell ? inputdata : '';
												setInputs({ ...inputs, amount: inputdata, buy_amount: buy_amount, sell_amount: sell_amount, amountInUSD: inputs.isSell });
												onResetPresets();
											}}
											value = {inputs.isSell ?  inputs.sell_amount :inputs.buy_amount}
											className="ant-select ant-select-lg w-100 rounded ant-input-number-input ant-select-show-arrow ant-select-disabled ant-select-show-search amount-input"
										/>
									</Tooltip>
								</div>								
								<CurrencyChooser 
									key="FromCurrencySelect"
									loading_spot={loading_spot} 
									SetSelectedFactor={SetSelectedFactor}  
									currency={inputs.isSell ? 'USD' : inputs.otherCurrency} 
									setInputs={setInputs}
									inputs ={inputs}
									reset ={reset}
									otherCurrency ={inputs.otherCurrency}
									value ={inputs.isSell ? inputs.sell_amount : inputs.buy_amount  }
									filterOpts ={filterOpts}
									currencies={currencies}/>
							</div>
							<div className="col-lg-1 middle-block">
								<Tooltip title="Flip boxes">
									<a 
										disabled={loading_spot}
										onClick={() => {
											setInputs({ ...inputs, isSell: !inputs.isSell });
										}}
									>
										<img alt="" src={require('../../assets/leftArrow.svg').default} />
										<img alt="" src={require('../../assets/rightArrow.svg').default} />
									</a>
								</Tooltip>
								{
									authService.hasPermissions(permissions.operationCanChangeInHand)
										?
										<Tooltip title="Amount to be inserted directly in to vault (In hand)">
											<Switch
												disabled={loading_spot}
												checkedChildren="IN"
												unCheckedChildren="NOT"
												className={(inputs.isSell) ? 'col-lg-12 hidden' : 'col-lg-12'}
												onChange={e => {
													onInHandChange && onInHandChange(e);
												}}
												checked={amountInHand}
											>
											</Switch>
										</Tooltip>
										:
										''
								}
							</div>
							<div className="col-lg-5 country-select">
								<div className="col-lg-12 amount-input-block">
									<Tooltip title="Amount Out">
									<NumberFormat  
										thousandSeparator={true}
										prefix={''}
										min={1}
										size="large"
										style={{ borderRadius: '9px' }}
										// type="text"
										onChange={val => {
											const inputdata = val.currentTarget.value.replace(/,/g,'')
											console.log(val);
											let buy_amount = inputs.isSell ? inputdata: '';
											let sell_amount = inputs.isSell ?  '' : inputdata ;
											setInputs({ ...inputs, amount: inputdata, buy_amount:buy_amount , sell_amount:sell_amount,amountInUSD:!inputs.isSell});
											onResetPresets();
										}}
										value = {inputs.isSell ?  inputs.buy_amount :inputs.sell_amount}
										className="ant-select ant-select-lg w-100 rounded ant-input-number-input ant-select-show-arrow ant-select-disabled ant-select-show-search amount-input"
									/>
									</Tooltip>
								</div>
								<CurrencyChooser 
									key="ToCurrencySelect"
									loading_spot={loading_spot} 
									SetSelectedFactor={SetSelectedFactor}  
									currency={inputs.isSell ? inputs.otherCurrency : 'USD'} 
									setInputs={setInputs}
									inputs ={inputs}
									reset ={reset}
									otherCurrency ={inputs.otherCurrency}
									value ={inputs.isSell ?inputs.buy_amount : inputs.sell_amount  }
									filterOpts ={filterOpts}
									currencies={currencies}
								/>
							</div>
							<div className="col-lg-1">
								<div className="col-lg-12 checkbox-fees">
									<Tooltip title="Include Fees">										
										<Switch
											disabled={loading_spot}
											checkedChildren="Yes"
											unCheckedChildren="No"
											className={(false) ? 'col-lg-12' : 'col-lg-12 hidden'} //!inputs.isSell
											onChange={e => {
												onFessIncludedChange && onFessIncludedChange(e);
											}}
											checked={feesIncluded}
										>
										</Switch>
									</Tooltip>
								</div>
								<button type="submit" className={loading_spot ? "submit disabled" : 'submit'} disabled={loading_spot}>
									<span className="operation-type">{(inputs.isSell) ? 'SELL' : 'BUY'}</span>
									{
									loading_spot
									? <Spin indicator={antIcon} />
									: 
										<img alt="" src={require('../../assets/Arrow.svg').default} />
									}
								</button>									
							</div>
						</div>
							<div className="col-lg-12">
							{
								loading_spot
								? <Spin indicator={antIcon} />
								: 	<div className={(toCurrency) ? 'rate-hint' : 'hidden'}>
										<span className="spot-rate inline-block col-4">Spot Rate:<br/> {spot_rate}</span>											
										<span className="customer-rate  inline-block col-4">Customer Rate: <br/>{exchangeRate}</span>									
										<span className="factor-block inline-block col-4">
											{can_edit_rate 
											? <Switch
												disabled={loading_spot}
												onChange={e => { 
													setInputs({ ...inputs, specified: !inputs.specified})
													if(inputs.specified)
													{
														SetSelectedFactor(null);
														onConvert(inputs,reset);
													}
												}}
												className="toggle"
												checkedChildren="Specified Margin:"
												unCheckedChildren="Preset Margin:"
												checked={inputs.specified}
											/>
											: "Preset Margin:"
											}
											<br/>
											<span className={(can_edit_rate && inputs.specified) ? "factor-button" : "factor"}
												onClick={
													(e) =>{
														if(inputs.specified)
															onChangeFactor(reset);
													}
												}>
												{
													rate_calc.amount
												}
												{
													rate_calc.is_fixed? '+':'%'
												}
											</span>
										</span>
										{
											editFactorShow && can_edit_rate
											?   <span className="main-factor-edit">
													<InputNumber className="factor-input" value={rate_calc.amount} 
														onChange={e => {
															SetSelectedFactor && SetSelectedFactor(e);
														}}
														onKeyDown={e => {
															if (e.keyCode === 13) {
																e.preventDefault();
																onFactorSubmit(reset);
															}
														}}
													/>
													<span className="factor-icon-button" onClick={(e) =>{onFactorSubmit(reset)}}>
														<CheckSquareOutlined className="green" />
													</span>
													<span className="factor-icon-button " onClick={onFactorCancel}>
														<CloseSquareOutlined  className="red"/>
													</span>
												</span> 
											:
											''
									}
									</div>
							}
						</div>
					</form>
				</div>
			</div>
		</S.convertAmountDiv>
	);
};

export default ConvertAmountForm;
