import React from 'react';

import { Table, message, Spin,Popover, Tag, Modal,Button } from 'antd';
import {CheckSquareOutlined,CloseSquareOutlined,  } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';//<i class="fab fa-creative-commons-nc"></i>
import {
	faStamp,
	faTimesCircle
} from '@fortawesome/free-solid-svg-icons';//<i class="fas fa-stamp"></i>
// npm install --save-dev @iconify/react @iconify-icons/fa-brands


import { InvoiceModal } from '../../components/Invoices/InvoiceModal';


import { formatDashedNumber } from '../../utils/strings';
import transactionService from '../../services/transaction.service';
import moment from 'moment';
import PendingFilter from './PendingFilter'
import TitleSearch from './TitleSearch'


export default class PendingOpsTable extends React.Component {
	state = {
		data: [],
		loading: false,
		invoice: null,
		showInvoice: false,
		invoice_type:'ongoing'
	};

	hide = () => {
		this.setState({
			trx_status: true,
		});
	  };

	columns = [
		{
			title: 'Invoice',
			dataIndex: 'invoice_id',
			key: 'invoice_id',
			sorter: (c1, c2) => c1 > c2,
			render: (text, record) => (
				'#'+record.invoice_id
			)
		},
		{
			title: 'Currency',
			dataIndex: 'currency',
			key: 'currency',
			sorter: (c1, c2) => c1 > c2
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			sorter: (c1, c2) => c1 > c2,
			render: text => formatDashedNumber(text)
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			key: 'customer',
			sorter: (c1, c2) => c1 > c2
		},
		{
			title: 'Phone',
			dataIndex: 'mobile',
			key: 'mobile',
			sorter: (c1, c2) => c1 > c2
		},
		{
			title: 'Rate',
			dataIndex: 'exchange_rate',
			key: 'exchange_rate',
			sorter: (c1, c2) => c1 > c2,
			render: text => formatDashedNumber(text)
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			sorter: (c1, c2) => c1 > c2,
			render: text => (text ? <Tag color="success">Ready</Tag> : <Tag color="volcano">Not Yet</Tag>)
		},
		{
			title: 'Date',
			dataIndex: 'created_at',
			key: 'created_at',
			sorter: (c1, c2) => c1 > c2,
			render: text => moment(new Date(text.slice(0, -2))).format('MM/DD/YYYY')
		},
		{
			title: 'Actions',
			render: (_, op) =>
				op.status ? (
					<>
						<Popover 
						//visible={this.state.trx_status}
						content={
							<div className="refund-amount-block-full">
								<div className="refund-amount">Remaining Amount: ${(op['total'])-(op['recieved_payment'])}</div>
								<div className="refund-amount">Amount For Customer:  {parseFloat(op['amount'])} {op['currency']}</div>
								<Button loading={this.state.loading_spot} onClick={()=> this.onFinishOperation(op,1)} type="primary"><FontAwesomeIcon type="left" className="icons" icon={faStamp} /> Confirm Receiving Full Amount</Button>
							</div>
						}
							title="Receiving"
							trigger="click"
						>
							<CheckSquareOutlined style={{ fontSize: '23px',color:'#52c41a'}}/>
						</Popover>						
						<Popover 
						//visible={this.state.trx_status}
						content={
							<>
								<div className="refund-amount-block">
									<div className="refund-amount">Payment to refund: ${op['recieved_payment']}</div>
									<Button loading={this.state.loading_spot} onClick={()=> this.onFinishOperation(op,3)} type="primary"><FontAwesomeIcon type="left" className="icons" icon={faTimesCircle} /> Return Without Fees</Button>
								</div>
								{
									(JSON.parse(op['invoice_data']).length>0) 
									?
									<div className="refund-amount-block">
										<div className="refund-amount">Payment to refund: ${parseFloat(op['recieved_payment'])+parseFloat(op['total_fees'])}</div>
										<Button onClick={()=> this.onFinishOperation(op,1)} type="primary"><FontAwesomeIcon type="left" className="icons" icon={faTimesCircle} /> Return With Fees</Button>
									</div>
									: ''
								}
							</>
						}
							title="Cancel And Refund"
							trigger="click"
						>
							<CloseSquareOutlined  style={{ fontSize: '23px',marginLeft:'5px',color:'#b10000'}} />
						</Popover>
					</>
				)
				: 	<Popover 
					//visible={this.state.trx_status}
					content={
						<>
							<div className="refund-amount">Payment to refund: ${op['recieved_payment']}</div>
							<Button onClick={()=> this.onFinishOperation(op,1)} type="primary"> Return With Fees </Button>
						</>
					}
					title="Cancel And Refund"
					trigger="click"
					>
					<CloseSquareOutlined  style={{ fontSize: '23px',marginLeft:'7px',color:'#b10000'}} />
				</Popover>
					
		}
	];

	async componentDidMount() {
		this.setState({ loading: true });
		let data;
		try {
			data = await transactionService.getOngoingOps();
			if (!data) throw new Error(data.message);
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}

		this.setState({
			data: data.data,
			originalData: data.data,
			filterOption:'1',
			loading: false
		});
	}

	handleFilter = key => {
		const selected = parseInt(key);
		if (selected === 5) {
		  	return this.setState({
				data: this.state.originalData
			});
		}
		else
		return this.setState({
			filterOption: key
		});
	}
	
	handleSearch = (searchText) => {
				
		let toSearch =[...this.state.originalData];

		if(searchText.length < 1)
			return;
		const filteredEvents = toSearch.filter(( record ) => {
			let valueToCompare = false;
			switch (this.state.filterOption)
			{
				case '1':	valueToCompare = record.customer.full_name.includes(searchText); break;
				case '2':	valueToCompare = record.customer.mobile === searchText; break;
				case '3':	valueToCompare = record.currency.code.toUpperCase().includes(searchText.toUpperCase()) || record.currency.name.toUpperCase().includes(searchText.toUpperCase()); break;
				case '4': valueToCompare = record.amount === searchText;  break;
				default: valueToCompare = record.customer.full_name.includes(searchText);
			}
		  
			return valueToCompare;

		});
		this.setState({
			data: filteredEvents
		});
	 };

	onFinishOperation =async (ongoing_obj,action) => {
		this.setState({ loading_spot: true});
		let invoices = JSON.parse(ongoing_obj['invoice_data']);	
		let id = ongoing_obj.id;
		let current_invoice = invoices.operations.filter(op => op.invoicable_id === id);
		current_invoice['shop'] = invoices.shop;
		current_invoice['operations'] = invoices.operations;
		current_invoice['customer'] = invoices.customer;
		ongoing_obj['data'] = invoices;
		ongoing_obj['invoice_type'] = (action ===1 ) ?'Recieve':'Refund';
		ongoing_obj['invoice_fees'] = ongoing_obj.total_fees;
		let new_page_data = this.state.data.filter(op => op.id !== id);
        
		this.setState({ 
			new_page_data: new_page_data,
			invoice: ongoing_obj,
		});

		let resp;
		try 
		{
			resp = await transactionService.updateOngoingOp(id,action);
			if (!resp) throw new Error('Something went wrong');
			Modal.destroyAll();
			this.setState({ 
				showInvoice: true ,	
				loading_spot: false ,				
				data: this.state.new_page_data,
			});
		}
		catch (e)
		{
			console.error(e.message);
			message.error(e.message);
			return;
		}
		message.success('Operation marked as delivered to customer');
	}

	render() {
		let {
			showInvoice,
			invoice
		} = this.state;

		return (
			<Spin spinning={this.state.loading}>
				 <section style={{paddingTop: '20px',paddingRight: '20px'}}>
					<header style={{display: 'flex',justifyContent: 'flex-end',alignItems: 'center',marginBottom: 10}}>
						<PendingFilter
							filterBy={this.handleFilter}
							style={{paddingLeft: 20}}
						/>
						<TitleSearch onSearch={this.handleSearch} style={{paddingLeft: 20}}/>
					</header>
					<Table
						dataSource={this.state.data}
						columns={this.columns}
						style={{ minHeight: '300px' }}
						size="small"
					/>
				</section>
				{showInvoice && (
					<InvoiceModal
						visible={showInvoice}
						
						invoice={{fees:invoice.invoice_fees, data: invoice.data, id: invoice.id, created_at: invoice.created_at,type:this.state.invoice_type }}
						onCancel={() => this.setState({ showInvoice: false })}
					/>
				)}
			</Spin>
		);
	}
}