import React, { useEffect, useState } from 'react';

import { Form, Input, Button, message } from 'antd';

import { apiClient } from '../../utils/http-client';

import * as S from './loginStyle';
import { useAuth } from '../../contexts/authentication.context';
import { useLocation, useHistory } from 'react-router-dom';

const LoginForm = props => {
	let [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [id_show_limites, setLimits] = useState([]);
	const [formInvalidMsg, setFormInvalidMsg] = useState(false);
	let { setAuthenticated } = useAuth();
	let { setUnAuthenticated } = useAuth();
	let location = useLocation();
	let history = useHistory();

	let cred = localStorage && localStorage.getItem('cred') ? JSON.parse(localStorage.getItem('cred')) : null;

	const onFinish = async values => {

		setLoading(true);
		let options = {
			method: 'POST',
			url: '/auth/login',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8'
			},
			data: values
		};

		try {
			let response = await apiClient(options);
			setLoading(false);
			let responseOK = response && Math.round(response.status / 100) === 2 && response.data.key;
			if (!responseOK) {
				message.error(response && response.data
					? response.data.msg
					: 'Something went wrong. Please contact support for help.');
				throw new Error(
					response && response.data
						? response.data.msg
						: 'Something went wrong. Please contact support for help.'
				);
			}

			setLimits([response.data.data.first_id_limit, response.data.data.second_id_limit]);
			
			setAuthenticated(true, response.data.token.access_token, response.data.data);
			
			//window.chrome.webview && window.chrome.webview.postMessage({'showMessage': 'exchange_login','index': 99,'responseTime':timeStamp});

			const referer = (location.state && location.state.referer) || '/';
			return history.push(referer);
		} catch (e) {
			setUnAuthenticated();
			var d = new Date();
			var timeStamp = d.getTime();
			window.chrome.webview &&
				window.chrome.webview.postMessage({
					showMessage: 'exchange_login',
					index: -1,
					responseTime: timeStamp
				});
			return;
		}
	};

	useEffect(() => {
		// Update the document title using the browser API
		var d = new Date();
		var timeStamp = d.getTime();
		window.chrome.webview &&
			window.chrome.webview.postMessage({ showMessage: 'exchange_login', index: 1, responseTime: timeStamp });
	});

	return (
		<div className="container">
			<div className="row justify-content-sm-center">
				<S.loginDiv className="col-sm-12 col-md-10">
					<div className="login">
						<div className="loginForm">
							<h3>
								<img src={require('../../assets/login.svg').default} alt="LogIn" /> Log in
							</h3>
							<Form
								form={form}
								// name="control-hooks"
								onFinish={onFinish}
								// onReset={onReset}
								initialValues={{
									username: cred?.username,
									password: cred?.password,
									pin_code: cred?.pin_code
								}}
							>
								{formInvalidMsg ? <p className="ta-form__invalid-msg">{formInvalidMsg}</p> : null}
								<Form.Item
									name="username"
									rules={[
										{
											required: true
										}
									]}
									id="usernameItem"
								>
									<Input className="w-100" placeholder="Username" id="username" autoComplete="off" />
								</Form.Item>

								<Form.Item
									name="password"
									// label="Amount"
									rules={[
										{
											required: true
										}
									]}
									id="password-item"
								>
									<Input.Password
										className="w-100"
										placeholder="Password"
										id="password"
										autoComplete="off"
									/>
								</Form.Item>

								<Form.Item
									name="pin_code"
									className="pinCode-item"
									rules={[
										{
											required: true
										}
									]}
								>
									<Input placeholder="Pin Code" id="pin_code" autoComplete="off" />
								</Form.Item>

								<Button id="login_button" htmlType="submit">
									 {loading ? <svg style={{marginLeft: '15px'}}viewBox="0 0 105 105" fill="currentColor" width="15" role="progressbar"><circle cx="12.5" cy="12.5" r="12.5"><animate attributeName="fillOpacity" begin="0s" dur="1s" values="1,.2,1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="12.5" cy="52.5" r="12.5" fillOpacity="0.5"><animate attributeName="fillOpacity" begin="100ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="12.5" r="12.5"><animate attributeName="fillOpacity" begin="300ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="52.5" r="12.5"><animate attributeName="fillOpacity" begin="600ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="12.5" r="12.5"><animate attributeName="fillOpacity" begin="800ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="52.5" r="12.5"><animate attributeName="fillOpacity" begin="400ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="12.5" cy="92.5" r="12.5"><animate attributeName="fillOpacity" begin="700ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="92.5" r="12.5"><animate attributeName="fillOpacity" begin="500ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="92.5" r="12.5"><animate attributeName="fillOpacity" begin="200ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle></svg>  : "Log in"}
								</Button>
							</Form>
						</div>
					</div>
				</S.loginDiv>
			</div>
		</div>
	);
};

export default LoginForm;
