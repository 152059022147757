import React from 'react';
import 'antd/dist/antd.css';

import AuhenticationContext from './contexts/authentication.context';
import * as auth from './utils/token';
import './App.css';

import Main from './routes';

class App extends React.Component {
	constructor(props) {
		super(props);
		let token = auth.getAccessToken();
		let authenticated = auth.isAuthenticated(token);
		let user;
		if (authenticated) user = auth.getUserData();
		this.state = {
			authenticated,
			token,
			activated: user && user.is_active,
			user
		};
	}

	setAuthenticated = (state, token = null, profile = null) => {
		if (token) auth.setAccessToken(token);
		if (profile) auth.setUserData(profile);
		this.setState({
			authenticated: state,
			token: token || this.state.token,
			user: profile || this.state.user,
			activated: token ? profile && profile.is_active : this.state.activated
		});
	};

	setUser = profile => this.setState({ user: profile });

	render() {
		return (
			<AuhenticationContext.Provider
				value={{
					authenticated: this.state.authenticated,
					user: this.state.user,
					setAuthenticated: this.setAuthenticated,
					setUnAuthenticated: () => this.setAuthenticated(false, null)
				}}
			>
				<Main app={(this.state.authenticated, this.state.activated)} />
			</AuhenticationContext.Provider>
		);
	}
}

// function App() {
// 	let [authenticated, setAuthenticated] = useState(false);
// 	let [user, setUser] = useState(null);
// 	let [activated, setActivated] = useState(false);

// 	useEffect(() => {
// 		let token = auth.getAccessToken();
// 		let authenticated = auth.isAuthenticated(token);
// 		setActivated(authenticated);
// 		setActivated(authService.isActivated());
// 		setUser(authenticated ? auth.getUserData() : null);
// 	}, []);

// 	const setAuthenticatedFn = (state, token, profile = null) => {
// 		setAuthenticated(state);
// 		if (state) {
// 			auth.setAccessToken(token);
// 			profile && auth.setUserData(profile);
// 			profile && setUser(profile);
// 		} else {
// 			auth.logout();
// 		}
// 	};

// 	return (
// 		<AuhenticationContext.Provider
// 			value={{
// 				authenticated,
// 				user,
// 				setAuthenticated: setAuthenticatedFn,
// 				setUnAuthenticated: () => setAuthenticatedFn(false, null)
// 			}}
// 		>
// 			<Main app={(authenticated, activated)} />
// 		</AuhenticationContext.Provider>
// 	);
// }

export default App;
