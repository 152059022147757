import ResourceManager from './resource-manager';
import config from '../utils/config';

class TransactionsService extends ResourceManager {
	async getXhchnageInfo(data) {
		return await this.postResource(config.URIs.get_xchange_info, data);
	}

	async getTransactionInfo(data) {
		return await this.postResource(config.URIs.get_transaction_info, data);
	}

	async getOngoingOps() {
		return await this.getResource(config.URIs.get_ongoing_ops);
	}
	async commitOperation(commit) {
		return await this.postResource(config.URIs.commit_transaction, {
			...commit,
			status: 1
		});
	}

	async commitTransaction(customerId,totalFees,customer_type, transactions)
	{
		return await this.postResource(config.URIs.commit_transaction_multi, {
			action_type: 1,
			totalFees: totalFees,
			customer_id: customerId,
			transactions,
			customer_type
		});
	}

	async reverseTransaction(transactions_id)
	{
		return await this.postResource(config.URIs.reverse_transaction, transactions_id);
	}

	async cancelOperation(commit) {
		return await this.postResource(config.URIs.cancel_transaction, {
			...commit,
			status: 0
		});
	}

	async cancelTransaction(transactions) {
		if (!Array.isArray(transactions)) transactions = [transactions];
		return await this.postResource(config.URIs.cancel_transactions_multi, {
			// user_id: 1,
			action_type: 0,
			transactions
		});
	}

	async cancelOngoingTransaction(ongoingId) {
		return await this.postResource(config.URIs.cancel_ongoing_op.replace(/:ongoingId/g, ongoingId));
	}

	async updateOngoingOp(ongoingId,withorwithout) {
		return await this.postResource(config.URIs.update_ongoing_op,{id:ongoingId,type: withorwithout});
	}
}

export default new TransactionsService();