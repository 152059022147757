import React from 'react';
import * as S from './MenuStyle';
import { useState } from 'react';
import ReconcilationFormModal from '../ReconcilationFormModal/ReconcilationFormModal';
import CtrCounter from './CtrCounter';

const Menu = props =>
{
	let [reconcilationModalVisible, setReconcilationModalVisible] = useState(false);
	
	return (
		<S.Menu>
			<div>
				<ul>
					<li>
						<img src={require('../../assets/services.svg').default} />
						<span>Services</span>
					</li>
					<li>
						<S.MenuLink to="/">
							<img src={require('../../assets/Exchange.svg').default} />
							<span>Exchange</span>
						</S.MenuLink>
					</li>
					<li>
						<S.MenuLink to="/pending-ops">
							<img  src={require('../../assets/wait.svg').default} />
							<span>Pending Operations</span>
						</S.MenuLink>
					</li>
					{/* <li>
						<S.MenuLink to="/messages">
							<img src={require('../../assets/messages.svg')} />
							<span>Messages</span>
						</S.MenuLink>
					</li> */}
					<li>
						<S.MenuLink to="/stocks">
							<img  src={require('../../assets/stock.svg').default} />
							<span>Stock</span>
						</S.MenuLink>
					</li>
					<li>
						<img src={require('../../assets/reports.svg').default} />
						<span>Reports</span>
						<div style={{padding:'0 0 0 9px',minHeight:'59px',width:'90px'}}>
							<S.MenuLink to="/reports">
								<img alt="sub" style={{ width: 15,marginleft:'15'}} src={require('../../assets/reports.svg').default} />
								<span>Daily</span>
							</S.MenuLink>
							<S.MenuLink to="/total-reports">
								<img alt="sub" style={{width:15,marginleft:'15'}} src={require('../../assets/reports.svg').default} />
								<span>Totals</span>
							</S.MenuLink>
						</div>
					</li>
					<li>
						<S.MenuLink
							 to="/"
							onClick={e => {
								e.preventDefault();
								setReconcilationModalVisible(true);
							}}
						>
							<img src={require('../../assets/reconciliation.svg').default} />
							<span>Add Reconcilation</span>
						</S.MenuLink>
					</li>
					<li>
						<S.MenuLink to="/ctr">
							<img src={require('../../assets/treasury_logo.svg').default} />
							<span>Needed CTR <CtrCounter /> </span>
						</S.MenuLink>
					</li>
				</ul>
				{reconcilationModalVisible && (
					<ReconcilationFormModal
						visible={reconcilationModalVisible}
						onCancel={() => setReconcilationModalVisible(false)}
					/>
				)}
			</div>
		</S.Menu>
	);
};

export default Menu;
