import { decode as decodeToken } from 'jsonwebtoken';
import config from '../utils/config';

const accessTokenField = config.access_token_field_name || 'access_token';
const refereshTokenField = config.referesh_token_field_name || 'referesh_token';

export function isTokenExpired(token) {
	try {
		const decoded = decodeToken(token);
		if (decoded.exp < Date.now() / 1000) {
			// Checking if token is expired. N
			return true;
		} else return false;
	} catch (err) {
		return false;
	}
}

export function logout() {
	// Clear user token and profile data from localStorage
	localStorage.removeItem(accessTokenField);
}

export function getAccessToken() {
	return localStorage.getItem(accessTokenField) || undefined;
}

export function setAccessToken(token) {
	localStorage.setItem(accessTokenField, token);
}

export function getRefreshToken() {
	return localStorage.getItem(refereshTokenField) || undefined;
}

export function getDecodedToken(token = null) {
	return decodeToken(token || getAccessToken());
}
export function getUserData() {
	return JSON.parse(localStorage.getItem('profile'));
}

export function setUserData(data) {
	//store profile data in local storage until we integrate with redux
	localStorage.setItem('profile', JSON.stringify(data));
}

export function isAuthenticated(token = null) {
	token = token || getAccessToken();
	return !!token && !isTokenExpired(token);
}
