import React from 'react';
import { TransactionSummaryContainer } from './SummaryStyle';
import { CloseCircleFilled } from '@ant-design/icons';
import { Tag} from 'antd';

const TransactionSummary = ({
	fromAmount,
	toAmount,
	fromCurrency,
	toCurrency,
	is_sell,
	rate,
	onGoing,
	onGoingCustomerPayment,
	onRemove,
}) => {


    let Amount1 = (is_sell) ? fromAmount : toAmount;
    let Amount2 =  (is_sell) ? toAmount : fromAmount;
    let Currency1 =  fromCurrency ;
    let Currency2 =  toCurrency ;

	return (
		<TransactionSummaryContainer className="justify-content-between summary-content">
			<div className="summary-item-block">
				<div className="bill-item">
					<span className="bill-title">In:</span> <span className="bill-amount">{Amount1} {Currency1}</span>
				</div>
				<div className="bill-item">
					<span className="bill-title">Out:</span> <span className="bill-amount">{Amount2} {Currency2}</span>
				</div>
			</div>
			<div className="summary-item-block" style={{marginBottom: '3px'}}>
				<span className="bill-title">Rate:</span> <span className="bill-amount">{rate}</span>
			</div>
			{onGoing && (
				<div>
					<Tag color="orange">Pending </Tag>
					<span>
						Deposit: <span style={{ fontWeight: 700 }}>{onGoingCustomerPayment} $</span>
					</span>
				</div>
			)}
			<span className="close-icon" onClick={onRemove}>
				<CloseCircleFilled style={{ color: '#FF141E', fontSize: '19px' }} />
			</span>
		</TransactionSummaryContainer>
	);
};

export default TransactionSummary;
