import { apiClient,apiBlobClient } from '../utils/http-client';

export default class ResourceManager {
	async getResource(url, opts = {}) {
		let response = await apiClient.get(url, opts);
		if (response.status === 500) return null;
		if (response.key && response.key === 0)
		{
			window.location.href ='login'
			return;
		}
		return response.data;
	}

	async postResource(url, data) {
		let response = await apiClient.post(url, data);
		if (response.status === 500) return null;
		if (response.key && response.key === 0)
		{
			window.location.href ='login'
			return;
		}
		return response.data;
	}

	async postBlobResource(url, data) {

		apiBlobClient({
			method: 'POST',
			url: url,
			responseType: 'blob',
			data:data
		})
		.then(blob => {
                const link = document.createElement("a");
            link.target = "_blank";
            link.download = "RESULT_FILE_EXCEL.xlsx"
            link.href = URL.createObjectURL(blob.data)
            link.click();
        })
	}

	async putResource(url, data) {
		let response = await apiClient.put(url, data);
		if (response.status === 500) return null;
		if (response.key && response.key === 0)
		{
			window.location.href ='login'
			return;
		}
		return response.data;
	}

	async deleteResource(url) {
		let response = await apiClient.delete(url);
		if (response.status === 500) return null;
		if (response.key && response.key === 0)
		{
			window.location.href ='login'
			return;
		}
		return response.data;
	}
}
