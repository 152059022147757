import { Tooltip } from 'antd';
import React from 'react';
import authService from '../../services/auth.service';

export function Disabled({ disabled, children, tooltipTitle }) {
	console.log('tooltip', tooltipTitle);
	if (disabled) {
		return (
			<Tooltip title={tooltipTitle}>
				<div>
					<div style={{ opacity: 0.5, pointerEvents: 'none' }} disabled>
						{children}
					</div>
				</div>
			</Tooltip>
		);
	}
}
const ComponentGuard = ({
	roles = [],
	permissions = [],
	children,
	fallback: Fallback = Disabled,
	notAuthorizedTooltip = 'Not Authorized'
}) => {
	return authService.hasRoles(...roles) || authService.hasPermissions(...permissions) ? (
		children
	) : Fallback ? (
		<Fallback disabled tooltipTitle={notAuthorizedTooltip}>
			{children}
		</Fallback>
	) : null;
};

export default ComponentGuard;
