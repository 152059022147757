import React, { Fragment, useEffect, useState } from 'react';

import { Form, Input, Button, Select, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import shopService from '../../services/shop.service';
import BrockerService from '../../services/broker.service';
import StockService from '../../services/stock.service';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

const InsertStockByFile = () => {
	const [form] = Form.useForm();
	const [shop, shopData] = useState([]);
	const [brocker, setBrocker] = useState([]);

	useEffect(() => {
		async function fetchData() {
			let shopsResult = await shopService.getShops(0,0,true);
			shopData(shopsResult.data);
			let brockerResult = await BrockerService.getBrokers();
			setBrocker(brockerResult.data);
		}
		fetchData();
	}, []);

	const shops = shop.map(shop => {
		return <Option value={shop.id}>{shop.name}</Option>;
	});

	const brockers = brocker.map(data => {
		return <Option value={data.id}>{data.name}</Option>;
	});

	const onFinish = async values => {
		// values.user_id = 2;
		if (values.file) values.file = values.file[0].originFileObj;
		let fd = new FormData();
		Object.keys(values).forEach(k => values[k] && fd.append(k, values[k]));
		try {
			let result = await StockService.addStockByFile(fd);
			if (result.key == 0) {
				message.error(result.data.errors[0]);
				return;
			}
			message.success('Stock was added successfully!');
			form.resetFields();
		} catch (error) {
			message.error(error);
			console.log(error);
		}
	};

	const onReset = () => {
		form.resetFields();
	};

	const normFile = e => {
		console.log('Upload event:', e);
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	return (
		<Fragment>
			<h3 className="h3">Insert Stock By File</h3>
			<Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
				<Form.Item
					name="shop_id"
					label="Shop"
					hasFeedback
					rules={[{ required: true, message: 'Please select the Shop!' }]}
				>
					<Select
						showSearch
						placeholder="Please select the shop!"
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{shops}
					</Select>
				</Form.Item>

				<Form.Item
					name="broker_id"
					label="Brocker"
					hasFeedback
					rules={[{ required: true, message: 'Please select the Brocker!' }]}
				>
					<Select
						showSearch
						placeholder="Please select the brocker!"
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{brockers}
					</Select>
				</Form.Item>

				<Form.Item
					name="file"
					label="Currency File"
					valuePropName="fileList"
					getValueFromEvent={normFile}
					rules={[{ required: true }]}
				>
					<Upload name="file" beforeUpload={() => false} multiple={false}>
						<Button>
							<UploadOutlined /> Click to upload
						</Button>
					</Upload>
				</Form.Item>

				<Form.Item {...tailLayout}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
					<Button htmlType="button" onClick={onReset}>
						Reset
					</Button>
				</Form.Item>
			</Form>
			<a href="../static/media/template.xls">Download Template</a>
			<div className="globe-bg">
				<img src="../static/media/import.png" />
			</div>
		</Fragment>
	);
};

export default InsertStockByFile;
