import React from 'react';

import { Table, message, Spin, Popover,Tag, Row, Col,Button,Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimesCircle,faVoteYea,faStamp} from '@fortawesome/free-solid-svg-icons';
import stockService from '../../services/stock-service';
import { formatDashedNumber } from '../../utils/strings';
import authService from '../../services/auth.service';
import permissions from '../../constants/permissions';
import { titlize } from '../../utils/strings';
import 'antd/dist/antd.css';

const filterOpts = (input, option) => option.props.key.toLowerCase().startsWith(input.toLowerCase());

export default class StockTable extends React.Component {
	state = {
		Currencies: [],
		data: [],
		display: [],
		loading: false
	};

	parseRecordDetails = (details_data) => {
		const columns = [
		  { title: 'ID', dataIndex: 'id', key: 'id' },
		  { title: 'Amount', dataIndex: 'amount', key: 'amount' },
		  { title: 'Date', dataIndex: 'date', key: 'date' },
		  {
			title: 'Action',
			dataIndex: 'operation',
			key: 'operation',
			render: (_,op) => (
				<Popover 
					content={
						authService.hasPermissions(permissions.operationCanChangeInHand)
						?
						<>
							<div className="refund-amount-block">
								<Button loading={this.state.loading} onClick={()=> this.onCancelStock(op)} type="primary" danger><FontAwesomeIcon type="center" className="icons" icon={faTimesCircle} /> Cancel Transaction</Button>
							</div>
							<div className="refund-amount-block">
								<Button loading={this.state.loading} onClick={()=> this.onConfirmReceive(op)} type="primary"><FontAwesomeIcon type="left" className="icons" icon={faStamp} /> Confirm Receiving </Button>
							</div>
						</>
						:
						''
					}
					title="Receive/Cancle"
					trigger="click"
				>
					<Button loading={this.state.loading} color="volcano" type="info"><FontAwesomeIcon type="left" className="icons" icon={faVoteYea} /> Select Action </Button>
							
				</Popover>
			),
		  },
		];
	
		const inHandData = [];
		details_data.forEach(element => {
			inHandData.push({
			id: element.id,
			amount: element.amount,
			date: element.date
		  });
		});

		return <Table columns={columns} dataSource={inHandData} pagination={false} />;
	};

	columns = [
		{
			title: 'Currency',
			dataIndex: 'currencyName',
			key: 'currencyName',
			sorter: (c1, c2) => c1 > c2
		},
		{
			title: 'Currency Code',
			dataIndex: 'currencyCode',
			key: 'currencyCode',
			sorter: (c1, c2) => c1 > c2
		},
		{
			title: 'Available Amount',
			dataIndex: 'amount',
			key: 'amount',
			sorter: (c1, c2) => c1 > c2,
			render: text => formatDashedNumber(text)
		},
		{
			title: 'Status',
			dataIndex: 'in_hand',
			key: 'in_hand',
			sorter: (c1, c2) => c1 > c2,
			render: (text, op) => {
				return (text == '1')
					? <Tag color="green">IN HAND</Tag>
					: <Tag color="gold">IN PROCESS</Tag>
			}
		}
	];
	async componentDidMount() {
		this.setState({ loading: true });
		let data;
		let Currencies = [];
		try {
			data = await stockService.getShopStock();
			if (!data.success) throw new Error(data.message);
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}
		console.log(data);

		data.data.forEach((item) => {
			const curr = item.currencyCode
			if (!Currencies.includes(curr))
				Currencies.push(curr);
		});

		Currencies.sort();

		this.setState({
			Currencies: Currencies,
			data: data.data,
			display: data.data,
			loading: false
		});
	}

	 onCancelStock = (params) =>{
		this.updateStockStatus({id:params.id,cmd:'delete',currency_id:params.currency_id})
	}

	onConfirmReceive= (params) =>{
		this.updateStockStatus({id:params.id,cmd:'update',currency_id:params.currency_id})
	}

	updateStockStatus = async (params) => {
		this.setState({ loading: true });
		let resp;
		try 
		{
			resp = await stockService.updateStockStatus(params);
			if (!resp) {
				this.setState({ 
					loading: false
				});
				throw new Error('Something went wrong');
			}
			this.setState({ 
				loading: false ,				
				data: resp.data,
				display: resp.data
			});
		}
		catch (e)
		{
			console.error(e.message);
			message.error(e.message);
			return;
		}
		message.success('Operation marked as delivered to customer');

	}

	filterByCurrency = currency => report => report.currencyCode === currency;
	
	filterReports = filters => {
		return this.state.data
			.sort()
			.reduce((agg, date) => {
				let AllReport = this.state.data;
				Object.keys(filters).forEach(filter => {
					let func = this[`filterBy${titlize(filter)}`];
					if (!func || filters[filter] === null) return AllReport;
					func = func.bind(this);
					AllReport = AllReport.filter(func(filters[filter]));
				});
				return AllReport;
			}, []);
	};
	
	SetCurrencyFilter = (val) => {

		const newDisplay = this.filterReports({...this.state.filter,currency: val})

		this.setState({
			display: newDisplay,
			filter: {
				...this.state.filter,
				currency: val
			}
		});	
	}

	render() {
		return (
			<Spin spinning={this.state.loading}>
				<Row className="my-3">
					<Col xs={8}>
						<span>Currency: </span>
						<Select
							allowClear
							onChange={val => {
								if (val === 0 || !val)
								{
									this.setState({ display: this.state.data });
								}
								else
								{
									this.SetCurrencyFilter(val)									
								}
							}}
							className="w-75"
							placeholder="Select Currency"
							showSearch
							filterOption={filterOpts}
						>
							{this.state.Currencies.map((c,idx) => (
								<Select.Option value={c} key={`curr${idx}`}>
									<span>{c}</span>
								</Select.Option>
							))}
						</Select>
					</Col>
				</Row>
				<Table
					className="components-table-demo-nested"
					dataSource={this.state.display}
					columns={this.columns}
					expandable={{
						expandedRowRender: record => { return this.parseRecordDetails(record.details) },
						rowExpandable: record => record.in_hand !== 1,
					  }}
					style={{ minHeight: '300px' }}
					size="small"
				/>
			</Spin>
		);
	}
}
