import ResourceManager from './resource-manager';
import config from '../utils/config';

class ShopsService extends ResourceManager {
	async getShops(withParent = 0,ofParent =0,shop_page =0) {
		console.log(new URLSearchParams({ withParent,ofParent,shop_page}).toString());
		return await this.getResource(config.URIs.get_shops + '?' + new URLSearchParams({withParent,ofParent,shop_page}).toString());
	}

	async getShop(shopId) {
		return await this.getResource(
			config.URIs.get_shop.replace(/:shopId/g, shopId)
		);
	}

	async getAllShopsLimits() {
		return await this.getResource(
			config.URIs.get_all_shops_limit
		);
	}

	async deleteShop(shopId) {
		return await this.deleteResource(config.URIs.delete_shop.replace(/:shopId/g, shopId));
	}

	async addNewShop(data) {
		return await this.postResource(config.URIs.add_new_shop, data);
	}

	async editShop(data, shopId) {
		return await this.postResource(config.URIs.edit_shop.replace(/:shopId/g, shopId), data);
	}

	async updateShopCurrencySlabs(data) {
		return await this.postResource(config.URIs.update_shop_slabs, data);
	}

	async editShopLimits(data) {
		return await this.postResource(config.URIs.edit_shop_limit, data);
	}

	async activateShop(shopId) {
		return await this.getResource(config.URIs.activate_shop.replace(/:shopId/g, shopId));
	}

	async deactivateShop(shopId) {
		return await this.getResource(config.URIs.deactivate_shop.replace(/:shopId/g, shopId));
	}
}

export default new ShopsService();
