export default [
	'PASSPORT',
	'SSN',
	'DRIVING_LICENSE',
	'STATE_ID',
	'INTERNATIONAL_DRIVING_LICENSE',
	'GOVERNMENT_ID',
	'ALIEN_ID',
	'TAX_ID'
];
