import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Menu = styled.div`
	padding: 0;
	& div {
		background-color: #fff;
		border-radius: 15px;
		padding: 14px;
		margin: 0;
		min-height: 359px;
	}
	& ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	& li {
		padding: 9px;
		padding-left: 11px;
		border-bottom: 1px solid #ccc;
		display: flex;
		flex-wrap: wrap;
		position:relative;
	}
	& li:first-child{
		padding-left: 0px;
	}
	& li:last-of-type {
		border: 0;
	}
	& img {
		margin-right: 5%;
		width:35px;
	}
	& li span {
		color: #18357e;
		font-weight: 500;
		font-size: 17px;
		flex-grow: 1;
	}
	& li button {
		width: 105px;
		background-color: #18357e;
		border: 0;
		outline: 0;
		color: #fff;
		padding: 1% 5%;
		border-radius: 17px;
		font-weight: 300;
	}
	& .ctr-count {
		color: red;
		font-size: 11px;
		position: absolute;
		top: 7px;
		padding: 3px;
		font-weight: bold;
		border: 1px solid #a20000;
		width: 25px;
		height: 25px;
		text-align: center;
		margin: 0px 7px;
		line-height: 0px;
		border-radius: 50%;
		left: calc(100% - 76px);
		transform: rotate(-33deg);
	}
`;

export const MenuLink = styled(Link)`
	width: 100%;
	display:block;
`;
