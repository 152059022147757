import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import brockerService from '../../services/broker.service';
import states from '../../assets/states.json';
import './styles.css';
import { Form, Input, Button, Select, Checkbox, DatePicker, Upload, message, Tooltip, InputNumber, Spin } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import currencyService from '../../services/currency.service';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 4 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 20 }
	}
};
const formItemLayoutWithOutLabel = {
	wrapperCol: {
		xs: { span: 24, offset: 0 },
		sm: { span: 20, offset: 4 }
	}
};

const BrokerForm = props => {
	let { id: brokerId } = useParams();
	const [form] = Form.useForm();
	const [broker, setBroker] = useState();
	const [unmounted, setUnmounted] = useState(false);
	const [currencies, setCurrencies] = useState([]);
	const [loading, setLoading] = useState(!!brokerId);
	const [errors, setErrors] = useState({});
	let history = useHistory();
	const fetchCurrencies = async () => {
		let resp;
		try {
			resp = await currencyService.getCurrencies();
			if (!resp.key) throw new Error('Error while fetching currencies');
		} catch (e) {
			console.error(e);
			message.error(e.message);
			return;
		}
		setCurrencies(resp.data);
	};

	const fetchBroker = async id => {
		if (id) {
			setLoading(true);
			let resp;
			try {
				resp = await brockerService.getBroker(id);
				if (!resp.key) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}
			setBroker(resp.data);
			form.setFieldsValue(resp.data);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!unmounted) fetchCurrencies();
		return () => setUnmounted(true);
	}, []);

	useEffect(() => {
		if (!unmounted) fetchBroker(brokerId);
	}, [brokerId]);
	// const shops = shop.map(shop => {
	// 	return <Option value={shop.id}>{shop.name}</Option>;
	// });

	const statesData = states.map(data => {
		return <Option value={data.abbreviation}>{data.name}</Option>;
	});

	const onFinish = async values => {
		setErrors({});
		if (brokerId) {
			try {
				let resp = await brockerService.editBroker(broker.id, values);
				if (!resp.key) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}
			message.success('The broker was edited successfully');
		} else {
			try {
				let resp = await brockerService.addNewBrocker(values);
				if (!resp.key) throw new Error('There is an error please try again!');
			} catch (e) {
				message.error(e.message);
				console.error(e);
				setErrors(e.errors || {});
				return;
			}
			message.success('Broker was added Successfully');
		}
		return history.push({
			pathname: '/dashboard/list-brokers',
			state: broker
		});
	};

	const onReset = () => {
		setErrors({});
		form.resetFields();
	};

	// const normFile = e => {
	// 	console.log('Upload event:', e);
	// 	if (Array.isArray(e)) {
	// 		return e;
	// 	}
	// 	return e && e.fileList;
	// };

	return (
		<Spin spinning={loading}>
			{brokerId ? <h3 className="h3">Edit Broker</h3> : <h3 className="h3">Add New Broker</h3>}
			<Form
				{...layout}
				form={form}
				name="control-hooks"
				onFinish={onFinish}
				initialValues={{
					name: broker ? broker.name : '',
					is_active: broker ? broker.is_active : true,
					mobile: broker ? broker.mobile : '',
					email: broker ? broker.email : '',
					address: broker ? broker.address : '',
					state: broker ? broker.state : null,
					city: broker ? broker.city : null,
					id_type: '1',
					add_fees: '0',
					exchange_rates: broker ? broker.address : [],
					default_exchange: broker ? parseFloat(broker.default_exchange) : ''
				}}
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors.name ? 'error' : null}
					help={errors.name ? errors.name.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="tax_id"
					label="Tax ID"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors.tax_id ? 'error' : null}
					help={errors.tax_id ? errors.tax_id.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="email"
					label="Email"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors.email ? 'error' : null}
					help={errors.email ? errors.email.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>
				{/* 
				<Form.Item name="start_working" label="Start Working">
					<DatePicker />
				</Form.Item> */}

				<Form.Item
					name="address"
					label="Address"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors.address ? 'error' : null}
					help={errors.address ? errors.address.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="state"
					label="State"
					hasFeedback
					rules={[{ required: true, message: 'Please select the state!' }]}
					validateStatus={errors.state ? 'error' : null}
					help={errors.state ? errors.state.map(err => <p>{err}</p>) : null}
				>
					<Select placeholder="Please select a state">{statesData}</Select>
				</Form.Item>

				<Form.Item
					name="city"
					label="City"
					rules={[{ required: true, message: 'Please select the city!' }]}
					validateStatus={errors.city ? 'error' : null}
					help={errors.city ? errors.city.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="zipcode"
					label="Zipcode"
					rules={[{ required: true, message: 'Please select the zipcode!' }]}
					validateStatus={errors.zipcode ? 'error' : null}
					help={errors.zipcode ? errors.zipcode.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="mobile"
					label="Mobile"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors.mobile ? 'error' : null}
					help={errors.mobile ? errors.mobile.map(err => <p>{err}</p>) : null}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Default Exchange:"
					name="default_exchange"
					style={{ width: '100%' }}
					validateTrigger={['onChange', 'onBlur']}
					rules={[
						{
							required: true,
							message: 'Please input default exchange rate.'
						}
					]}
					validateStatus={errors.default_exchange ? 'error' : null}
					help={errors.default_exchange ? errors.default_exchange.map(err => <p>{err}</p>) : null}
				>
					<InputNumber style={{ width: '100%' }} placeholder="default exchange rate" />
				</Form.Item>

				<Form.List
					name="exchange_rates"
					validateStatus={errors.exchange_rates ? 'error' : null}
					help={errors.exchange_rates ? errors.exchange_rates.map(err => <p>{err}</p>) : null}
				>
					{(fields, { add, remove }) => {
						// console.log(fields);
						return (
							<>
								{fields.map((field, index) => (
									<Form.Item
										{...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
										label={index === 0 ? 'Exchange Rates' : ''}
										required={false}
										key={field.key}
									>
										<Form.Item
											{...field}
											name={[field.name, 'currency']}
											fieldKey={[field.fieldKey, 'currency']}
											validateTrigger={['onChange', 'onBlur']}
											rules={[
												{
													required: true,
													message: 'Please select currency.'
												}
											]}
											noStyle
										>
											<Select
												placeholder="currency"
												style={{ width: '30%', marginRight: '5px' }}
												// onChange={value => {
												// 	let values = [...form.getFieldValue('exchange_rates')];
												// 	values[field.name] = { ...values[field.name], currency: value };
												// 	form.setFieldsValue({
												// 		external_rates: values
												// 	});
												// 	console.log(form.getFieldValue('exchange_rates'));
												// }}
												showSearch
												filterOption={(input, option) =>
													option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
														0 ||
													option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
												}
											>
												{currencies.map(currency => (
													<Option
														key={currency.id}
														value={currency.code}
														name={currency.name}
													>
														{currency.code}
													</Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item
											{...field}
											name={[field.name, 'rate']}
											fieldKey={[field.fieldKey, 'rate']}
											validateTrigger={['onChange', 'onBlur']}
											rules={[
												{
													required: true,
													message: 'Please input exchange rate or delete this field.'
												}
											]}
											noStyle
										>
											<InputNumber
												placeholder="rate"
												style={{ width: '60%' }}
												// onChange={value => {
												// 	let values = [...form.getFieldValue('exchange_rates')];
												// 	values[field.name] = { ...values[field.name], rate: value };
												// 	form.setFieldsValue({
												// 		external_rates: values
												// 	});
												// 	console.log(form.getFieldValue('exchange_rates'));
												// }}
											/>
										</Form.Item>
										{fields.length > 1 ? (
											<MinusCircleOutlined
												className="dynamic-delete-button"
												style={{ margin: '0 8px' }}
												onClick={() => {
													remove(field.name);
												}}
											/>
										) : null}
									</Form.Item>
								))}
								<Form.Item {...formItemLayoutWithOutLabel}>
									<Button
										type="dashed"
										onClick={() => {
											add();
										}}
										style={{ width: '60%' }}
									>
										<PlusOutlined /> Add Exchange Rate
									</Button>
								</Form.Item>
							</>
						);
					}}
				</Form.List>

				<Form.Item {...formItemLayoutWithOutLabel} name="is_active" valuePropName="checked">
					<Checkbox>Is Active</Checkbox>
				</Form.Item>

				<Form.Item {...tailLayout}>
					{broker ? (
						<Button type="primary" htmlType="submit">
							Edit
						</Button>
					) : (
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					)}
					<Button htmlType="button" onClick={onReset}>
						Reset
					</Button>
				</Form.Item>
			</Form>
		</Spin>
	);
};

export default BrokerForm;
