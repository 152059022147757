import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import ProtectedRoute, { AuthRoute } from '../components/protected-route';
import AppLayout from '../layouts/app-layout';
// import DashboardLayout from './dashboard-layout';
import AuthenticationContext from '../contexts/authentication.context';
import auth from '../services/auth.service';
import { DashboardLayout } from '../layouts/dashboard-layout';
import Login from '../containers/login/login';
import StarterPage from '../containers/login/starter';
import authService from '../services/auth.service';

export default function Main(props) {
	return (
		<AuthenticationContext.Consumer>
			{authProps => (
				<Router basename={'/xchange'}>
					<Switch>
						<Route
							path={`${process.env.PUBLIC_URL}/starter`}
							render={props => {
								return <StarterPage />;
							}}
						/>
						<ProtectedRoute
							path={`${process.env.PUBLIC_URL}/login/`}
							exact
							render={props => {
								return <Login />;
							}}
							isAllowed={!auth.isAuthenticated()}
						/>

						<AuthRoute
							path={`${process.env.PUBLIC_URL}/dashboard`}
							// roles={['company_admin', 'company_user']}
							authenticated={authService.isAuthenticated}
							notAuthorizedCallback={authProps.setUnAuthenticated}
							// checkActivation
							render={rprops => <DashboardLayout {...rprops} />}
							notAuthenticatedRedirect="/login"
						/>

						{/* <Route
							path="/dashboard"
							render={props => {
								return <DashboardLayout />;
							}}
						/> */}
						{/* <AuthRoute
							path="/dashboard"
							roles={['manager']}
							authenticated={auth.isAuthenticated()}
							notAuthorizedCallback={authProps.setUnAuthenticated}
							checkActivation
							render={rprops => <h1>dashboard</h1>}
						/> */}
						<AuthRoute
							path={`${process.env.PUBLIC_URL}/`}
							// roles={['company_admin', 'company_user']}
							authenticated={authService.isAuthenticated}
							notAuthorizedCallback={authProps.setUnAuthenticated}
							// checkActivation
							render={rprops => <AppLayout {...rprops} />}
							notAuthenticatedRedirect="/login"
						/>

						{/* <Route path="/" render={rprops => <AppLayout state={props.app} {...rprops} />} /> */}
					</Switch>
				</Router>
			)}
		</AuthenticationContext.Consumer>
	);
}
