import config from '../utils/config';
import { apiClient } from '../utils/http-client';
import { getAccessToken, isAuthenticated, getDecodedToken, getUserData, setUserData } from '../utils/token';

class AuthService {
	constructor(httpClient) {
		this.httpClient = httpClient;
	}

	async getUserProfile() {
		let response = await this.httpClient.get(config.URIs.get_user_info);
		if (!response.data) {
			throw new Error(response.error.data.message);
		} else return response.data;
	}

	async updateProfile(data) {
		let response = await this.httpClient.put(config.URIs.update_user_profile, data);
		if (!response.data) {
			throw new Error(response.error.data.message);
		} else if (response.data.success) {
			let profile = { ...response.data.profile };
			profile.company = getUserData().company;
			setUserData(profile);
			return response.data.profile;
		}
		throw new Error(
			'Well, Thats wierd!. Apparently something went wrong, Please contact support to help you getting through this'
		);
	}

	async changePassword(data) {
		let response = await this.httpClient.put(config.URIs.change_password, data);
		if (!response.data) {
			throw new Error(response.error.data.message);
		} else if (response.data.success) {
			return true;
		}
		throw new Error(
			'Well, Thats wierd!. Apparently something went wrong, Please contact support to help you getting through this'
		);
	}

	hasRoles(...roles) {
		if (!roles) return true;
		let token = getAccessToken();
		if (!isAuthenticated(token)) return false;
		let user = getDecodedToken(token);
		let userRole = user && user.role;
		if (userRole && roles.includes(userRole)) return true;
		return false;
	}

	hasPermissions(...permissions) {
		if (!permissions || permissions.length === 0) return true;
		let user = getUserData();
		// if (!isAuthenticated(token)) return false;
		// let user = getDecodedToken(token);
		let userRole = user && user.role;
		if (!userRole) return false;
		for (let permission of permissions) {
			if (userRole.permissions.find(p => p.name === permission)) return true;
		}
		return false;
	}

	hasAllPermissions(...permissions) {
		if (!permissions) return true;
		let user = getUserData();
		// if (!isAuthenticated(token)) return false;
		// let user = getDecodedToken(token);
		let userRole = user && user.role;
		if (!userRole) return false;
		for (let permission of permissions) {
			if (!userRole.permissions.find(p => p.name === permission)) return false;
		}
		return true;
	}

	async verifyToken() {
		if (!getAccessToken()) return false;
		let response = await this.httpClient.get(config.URIs.verify_token_uri);
		if (!response.data) return false;
		else if (response.data.verified) return true;
		return false;
	}

	isAuthenticated(token = null) {
		return isAuthenticated(token);
	}
}

export default new AuthService(apiClient);
