import ResourceManager from './resource-manager';
import config from '../utils/config';

class RolesService extends ResourceManager {
	async addNewRole(data) {
		return await this.postResource(config.URIs.add_new_role, data);
	}
	async getRoles(params) {
		return await this.getResource(config.URIs.add_new_role, {
			params
		});
	}
	async deleteRole(id) {
		return await this.deleteResource(config.URIs.delete_role.replace(/:id/g, id));
	}
	async editRole(id, data) {
		return await this.postResource(config.URIs.edit_role.replace(/:id/g, id), data);
	}
	async getPermissions() {
		return await this.getResource(config.URIs.get_permissions);
	}

	async getRole(id) {
		return await this.getResource(config.URIs.get_role.replace(/:id/g, id));
	}

	async getRolePermission(id) {
		return await this.getResource(config.URIs.get_role_permission.replace(/:id/g, id));
	}
}

export default new RolesService();
