import React from 'react';
import * as S from './styles';
import { Button, Row,Col } from 'antd';

const ConvertOpeationChooser = ({ onChoose }) => 
{
    return (
        <S.convertAmountDiv>
			<div className="form-container">
                <div className="inputs col-sm-12" style={{ width: '100%', justifyContent: 'center', textAlign: 'center', Color:'#ffffff'}}>
                    <Row>
                        <Col span={24}>
                            <h4  style={{ width: '100%', justifyContent: 'center', textAlign: 'center', color:'#ffffff'}} >
                                Please Choose The Operation Type
                            </h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Button onClick={()=>{onChoose(true)}} type="primary" style={{background:'#5a9d47',height: '89px', borderRadius:9,fontSize:19,padding:'3px 4px 4px 4px',width:'100%'}} size="large">
                                <img alt="sell" style={{width:25,marginRight:10}} src={require('../../assets/income.svg').default} />  Sell <br/>To Customer 
                            </Button>
                        </Col>
                        <Col span={2}>
                        </Col>
                        <Col span={11}>
                            <Button onClick={() => { onChoose(false) }} type="primary" style={{background:'#536ba1',height: '89px',borderRadius:9,fontSize:19,padding:'3px 4px 4px 4px',width:'100%'}} size="large">
                                <img alt="buy" style={{width:25,marginRight:10}} src={require('../../assets/outcome.svg').default} /> Buy <br/> From Customer
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </S.convertAmountDiv>
    );
}

export default ConvertOpeationChooser;