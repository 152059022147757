import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import currencyService from '../../services/currency.service';

import { Form, Input, Button, Select, Upload, Checkbox, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

const AddNewCurrency = props => {
	const [form] = Form.useForm();
	const [errors, setErrors] = useState({});

	const currencyData = props.location.data;
	let history = useHistory();

	const onFinish = async values => {
		setErrors({});
		if (values.flag) values.flag = values.flag[0].originFileObj;
		values.active ? (values.active = 1) : (values.active = 0);
		let fd = new FormData();
		Object.keys(values).forEach(k => values[k] && fd.append(k, values[k]));
		if (currencyData) {
			try {
				await currencyService.editCurrency(fd, currencyData.id);
				message.success('currency was edited successfully');
				history.push({
					pathname: '/dashboard/list-currencies',
					state: { id: currencyData.id }
				});
			} catch (e) {
				message.error(e.message || e.msg);
				console.error(e);
				return setErrors(e.errors);
			}
		} else {
			try {
				await currencyService.addNewCurrency(fd);
				message.success('currency was added successfully');
				form.resetFields();
			} catch (e) {
				message.error(e.message || e.msg);
				console.error(e);
				return setErrors(e.errors);
			}
		}
	};

	const onReset = () => {
		setErrors({});
		form.resetFields();
	};

	const normFile = e => {
		if (Array.isArray(e)) {
			return e;
		}

		return e && e.fileList;
	};

	return (
		<Fragment>
			{currencyData ? <h3 className="h3">Edit Currency</h3> : <h3 className="h3">Add New Currency</h3>}
			<Form
				{...layout}
				form={form}
				name="control-hooks"
				onFinish={onFinish}
				initialValues={{
					name: currencyData ? currencyData.name : null,
					code: currencyData ? currencyData.code : null,
					active: 1
				}}
			>
				<Form.Item
					name="name"
					label="Currency Name"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors?.name &&'error'}
					help={errors?.name?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="code"
					label="Currency Code"
					rules={[
						{
							required: true
						}
					]}
					validateStatus={errors?.code && 'error'}
					help={errors?.code?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="symbol"
					label="Symbol"
					rules={[
						{
							required: currencyData ? false : true
						}
					]}
					validateStatus={errors?.symbol && 'error'}
					help={errors?.symbol?.join('\n')}
				>
					<Input />
				</Form.Item>

				{/* {!currencyData ? (
					<Fragment>
						<Form.Item name="exchange_rate" label="Exchange Rate">
							<Input />
						</Form.Item>

						<Form.Item name="format" label="Format">
							<Input />
						</Form.Item>

						<Form.Item name="fees_buy" label="Fees Buy">
							<Input />
						</Form.Item>

						<Form.Item name="fees_sell" label="Fees Sell">
							<Input />
						</Form.Item>

						<Form.Item name="slab_buy" label="Slab Buy">
							<Input />
						</Form.Item>

						<Form.Item name="slab_sell" label="Slab Sell">
							<Input />
						</Form.Item>
					</Fragment>
				) : null} */}

				<Form.Item
					name="flag"
					label="Flag"
					valuePropName="fileList"
					getValueFromEvent={normFile}
					rules={[
						{
							required: currencyData ? false : true
						}
					]}
					validateStatus={errors?.flag && 'error'}
					help={errors?.flag?.join('\n')}
				>
					<Upload name="flag" beforeUpload={() => false} listType="picture" multiple={false}>
						<Button>
							<UploadOutlined /> Click to upload
						</Button>
					</Upload>
				</Form.Item>

				<Form.Item
					{...tailLayout}
					name="active"
					valuePropName="checked"
					validateStatus={errors?.active && 'error'}
					help={errors?.active?.join('\n')}
				>
					<Checkbox>Is Active</Checkbox>
				</Form.Item>

				<Form.Item {...tailLayout}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
					<Button htmlType="button" onClick={onReset}>
						Reset
					</Button>
				</Form.Item>
			</Form>
		</Fragment>
	);
};

export default AddNewCurrency;
