

	export function capitalizeFirstLetter(str, inAllWordsOfString = false){
		if (!inAllWordsOfString) {
		  //convert given string to lowercase
		  let lowerStr = str.toLowerCase();
		  // Now convert first character to upper case
		  let firstCharacter = str.charAt(0).toUpperCase();
		  // Now combine firstCharacter and lowerStr and return
		  return firstCharacter + lowerStr.slice(1);
		} else {
		  let str1 = str.split(" ");
		  let returnStr = "";
	  
		  for (let i = 0; i < str1.length; i++) {
			let lowerStr = str1[i].toLowerCase();
			returnStr =
			  returnStr + str1[i].charAt(0).toUpperCase() + lowerStr.slice(1) + " ";
		  }
		  return returnStr.trim();
		}
}
    
export function moretodo()
{
    return "todo"
}
    