import axios from 'axios';
import config from '../utils/config';
import { getAccessToken } from './token';

const DEBUG = process.env.NODE_ENV === 'development';

export const apiClient = axios.create({
	baseURL: config.api_url
});

export const apiBlobClient = axios.create({
	baseURL: config.api_url
});

apiClient.interceptors.request.use(request => {
	request.headers.authorization = `Bearer ${getAccessToken()}`;
	return request;
});

apiClient.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		if (DEBUG) console.error(error);
		if (error.response && error.response.status === 401) {
			//Anuthorized
			window.location.href ='login'
			return Promise.reject(new Error('Unahtintecated. Please try to login again'));
		}

		if (error.response && error.response.status === 403) {
			//Anuthorized
			return Promise.reject(new Error('You are unautorized to perform this action.'));
		}

		return Promise.reject((error.response && error.response.data) || error);
	}
);

apiBlobClient.interceptors.request.use(request => {
	request.headers.authorization = `Bearer ${getAccessToken()}`;
	return request;
});

apiBlobClient.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		if (DEBUG) console.error(error);
		if (error.response && error.response.status === 401) {
			//Anuthorized
			window.location.href ='login'
			return Promise.reject(new Error('Unahtintecated. Please try to login again'));
		}

		if (error.response && error.response.status === 403) {
			//Anuthorized
			return Promise.reject(new Error('You are unautorized to perform this action.'));
		}

		return Promise.reject((error.response && error.response.data) || error);
	}
);

export default axios;
