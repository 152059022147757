import React from 'react';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';

import { Table, Button, Spin, message, Modal, Row, Col, Input, Select } from 'antd';
import RoleService from '../../services/role.service';
import useFetchData from '../../utils/fetchData';
import useEditRow from '../../utils/editRow';
import { filterOpts, useFilters } from '../../hooks/filters.hook';
import roleService from '../../services/role.service';

const ListRoles = props => {
	const [data, setData, isLoading] = useFetchData(RoleService.getRoles.bind(RoleService, { withPermissions: 1 }));
	const edited = useEditRow();
	const [permissions] = useFetchData(roleService.getPermissions.bind(roleService));

	const { display, setFilterValues } = useFilters({
		data,
		filters: ['permission', 'name'],
		filterFunctions: {
			filterByName: name => item =>
				item.name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
				item.display_name.toLowerCase().indexOf(name.toLowerCase()) !== -1,
			filterByPermission: permission => item => item.permissions.find(p => p.name === permission)
		}
	});
	const columns = [
		{
			title: 'Role',
			dataIndex: 'name'
		},
		{
			title: 'Display Name',
			dataIndex: 'display_name'
		},
		{
			title: 'Description',
			dataIndex: 'description'
		},
		{
			title: 'Action',
			key: 'action',
			fixed: 'right',
			ellipsis: true,
			render: (text, record) => (
				<React.Fragment>
					<Button
						danger
						size="small"
						onClick={() => {
							Modal.confirm({
								onOk: deleteRecord.bind(this, record.id),
								title: 'Are you sure you want to deactivate this role?'
							});
						}}
					>
						Deactivate
					</Button>
					<Button type="default" size="small">
						<Link
							to={{
								pathname: `/dashboard/edit-role/${record.id}`,
								data: record
							}}
						>
							Edit
						</Link>
					</Button>
				</React.Fragment>
			)
		}
	];

	async function deleteRecord(id) {
		try {
			var resp = await RoleService.deleteRole(id);

			if (!resp.key) throw new Error('There is an error please try again!');
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return;
		}
		if (resp.msg === 'sucessfully delete role') {
			let newData = data.filter(record => record.id !== id);
			setData(newData);
			message.success('the role was deactivated successfully');
		}
	}
	return (
		<div>
			<h1 className="h3"> List Roles </h1>
			<div className="pl-2">
				{!isLoading && (
					<>
						<Row className="my-3">
							<Col xs={8}>
								<span>Name: </span>
								<Input.Search
									allowClear
									onChange={e => {
										let val = e.target.value;
										setFilterValues({
											name: val
										});
									}}
									className="w-75"
									placeholder="Search"
								/>
							</Col>
							<Col xs={{ span: 8 }}>
								<span>Permission: </span>
								<Select
									allowClear
									onChange={val => {
										setFilterValues({
											permission: val
										});
									}}
									className="w-75"
									placeholder="Select Currency"
									showSearch
									filterOption={filterOpts}
								>
									{permissions.map(p => (
										<Select.Option value={p.name} key={p.id}>
											<span>{p.display_name}</span>
										</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
					</>
				)}
			</div>
			<Spin spinning={isLoading}>
				<Table
					size="small"
					columns={columns}
					dataSource={display}
					rowClassName={(record, index) => (record.id == edited ? 'table-row-dark' : 'table-row-light')}
				/>
			</Spin>
		</div>
	);
};

export default ListRoles;
