import React from 'react';

import { Table, message, Spin, Row,Col, Tag, DatePicker, Button,Select,notification } from 'antd';
import { fixAutocomplete } from '../../utils/dom';
import shopService from '../../services/shop.service';
import reportsService from '../../services/reports.service';
import { MailTwoTone,FileExcelTwoTone  } from '@ant-design/icons'
import { titlize } from '../../utils/strings';
import moment from 'moment';
import './style.css';

const filterOpts = (input, option) => option.props.key.toLowerCase().startsWith(input.toLowerCase());

export default class TotalsReportContainer extends React.Component {
	state = {
		data: {},
		display: {},
		shops: [],
		loading: false,
		loadingButton: false,
		ready: false,
		filter: {
			shopId: 0,
			fromDate: '',
			toDate: '',
			operator:'' 
		}
	};
	total_columns = [
		{
			title: 'Currency',
			dataIndex: 'currency_name',
			key: 'currency_name'
		}
        ,
        {
			title: 'TOTAL IN',
			dataIndex: 'in',
			key: 'in',
			render: (_, report) => {
				return (
					<>
                        <Tag color="green">{report.currency}</Tag>{report.in} 
                    </>
                );
			}
		}
        ,
        {
			title: 'TOTAL OUT',
			dataIndex: 'out',
			key: 'out',
			render: (_, report) => {
				return (
					<>
                        <Tag color="volcano">{report.currency}</Tag>{report.out} 
                    </>
                );
			}
		}
        ,
        {
			title: 'TOTAL Diff',
			key: 'out',
			render: (_, report) => {
                const deff = report.in - report.out;
                const color = deff < 0 ? "volcano" : "green";
				return (
					<>
                        <Tag color={color}>{Math.abs(deff)}</Tag>
                    </>
                );
			}
		}
	];
	
	total_forign_columns = [
		{
			title: 'Currency',
			dataIndex: 'currency_name',
			key: 'currency_name'
		}
        ,
        {
			title: 'TOTAL IN',
			dataIndex: 'in',
			key: 'in',
			className: 'right-align',
			render: (_, report) => {
				return (
					<>
                        {report.in ? <Tag color="green">{report.currency} {report.in}</Tag> : <>...</>}
                    </>
                );
			}
		}
        ,
        {
			title: 'PRICE',
			dataIndex: 'in_usd',
			key: 'in_usd',
			render: (_, report) => {
				return (
					<>
                        {report.in_usd ? <Tag color="gray">${report.in_usd}</Tag> : <>...</>}
                    </>
                );
			}
		}
        ,
        {
			title: 'TOTAL OUT',
			dataIndex: 'out',
			key: 'out',
			className: 'right-align',
			render: (_, report) => {
				return (
					<>
                        {report.out ? <Tag color="volcano">{report.currency} {report.out}</Tag> : <>...</>}
                    </>
                );
			}
		}
        ,
        {
			title: 'PRICE',
			dataIndex: 'out_usd',
			key: 'out_usd',
			render: (_, report) => {
				return (
					<>
                        {report.out_usd ? <Tag color="gray">${report.out_usd}</Tag> : <>...</>}
                    </>
                );
			}
		}
        ,
        {
			title: 'FEES',
			dataIndex: 'fees',
			key: 'fees'
		}
        ,
        {
			title: 'TOTAL Diff',
			key: 'out',
			render: (_, report) => {
                const deff = report.in - report.out;
                const color = deff < 0 ? "volcano" : "green";
				return (
					<>
                        <Tag color={color}>{Math.abs(deff)}</Tag>
                    </>
                );
			}
		}
    ];

	columns = [
		{
			title: 'Currency',
			dataIndex: 'currency',
			key: 'currency',
			sorter: (r1, r2) => {
				let c1 = r1.currency,
					c2 = r2.currency;
				return c1 > c2 ? 1 : c2 > c1 ? -1 : 0;
			}
		},
		{
			title: 'USD',
			dataIndex: 'usd_amount',
			key: 'usd_amount',
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.usd_amount),
					p2 = parseFloat(r2.usd_amount);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				parseFloat(record.usd_amount).toLocaleString()
			)
		},
		{
			title: 'Foreign Amount',
			dataIndex: 'foreign_amount',
			key: 'foreign_amount',
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.foreign_amount),
					p2 = parseFloat(r2.foreign_amount);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				parseFloat(record.foreign_amount).toLocaleString()
			)
		},
		{
			title: 'Fees',
			dataIndex: 'fees',
			key: 'fees',
			sorter: (r1, r2) => {
				let p1 = parseFloat(r1.fees),
					p2 = parseFloat(r2.fees);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				parseFloat(record.fees).toLocaleString()
			)
		},
		{
			title: 'Operator',
			dataIndex: 'operator',
			key: 'operator',
			render: (text, record) => (
				record.operator
			),
			sorter: (r1, r2) => {
				let p1 = r1.operator,
					p2 = r2.operator;
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			}
		},
		{
			title: 'Shop',
			dataIndex: 'shop',
			key: 'shop',
			render: (text, record) => (
				record.shop
			),
			sorter: (r1, r2) => {
				let p1 = r1.shop,
					p2 = r2.shop;
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			}
		},
		{
			title: 'Type',
			dataIndex: 'is_sell',
			key: 'is_sell',
			render: (_, report) => {
				return (
					<>
						{report.is_sell ? <Tag color="gold">Sell</Tag> : report.is_transfer ?
							<Tag color="blue">Transfered</Tag>
							:
							report.is_reconcilation
								? <Tag color="blue">Reconcilation</Tag>
								:
								<Tag color="green">Buy</Tag>
						}
					</>
				);
			},
			sorter: (r1, r2) => r1.is_sell - r2.is_sell
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			sorter: (r1, r2) => {
				let p1 = moment(r1.date),
					p2 = moment(r2.date);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				moment(record.date).format('MM/DD/YYYY')
			)
		},
	];

	async componentDidMount() {
		if (!this.props.dashboard) {
			this.state.filter.shopId = this.props.shop_id;
			return;
		}
		this.setState({ loading: true });
		let shops;
		try {
			let resp = await shopService.getShops(0,0,this.props.dashboard);
			if (!resp) throw new Error('Something went wrong');
			shops = resp.data;
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}

		this.setState({
			shops,
			loading: false
		});
	}

	componentDidUpdate() {
		fixAutocomplete();
	}

	onSearch = async () => {
		this.setState({ loading: true, ready: false });
		let data;
		let totals;
		let totals_forign;
		let tOperators = [];
		let {shopId, fromDate, toDate } = this.state.filter;

		try {
			data = await reportsService.getTotalsReports({
				shop_id: shopId,
				from: fromDate ? fromDate.format('YYYY-MM-DD') : undefined,
				to: toDate ? toDate.format('YYYY-MM-DD') : undefined
			});
			if (!data.success) throw new Error(data.message);
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}
		totals = Object.keys(data.data.totals).map(key => data.data.totals[key]);
		totals_forign = Object.keys(data.data.totals_forign).map(key => data.data.totals_forign[key]);
		data = data.data.data;

		data.forEach((item) => {
			if (!tOperators.includes(item.operator))
				tOperators.push(item.operator);
		})

		console.log(data);

		this.setState({
			data: data,
			totals: totals,
			totals_forign: totals_forign,
			display: data,
			ready: true,
			loading: false,
			Operators: tOperators
		});
	};

    onExport = async () => {
		let { shopId,fromDate, toDate } = this.state.filter;
		const datatosend = this.state.display;
        this.setState({ loading: true});
		try {
			reportsService.exportTotalsReports({data:datatosend, shop_id:shopId, fromDate:fromDate, toDate:toDate})
            .then(()=>{
                this.openNotificationWithIcon('success','Exported Successfuly','bottomRight')
                this.setState({loading : false });
            });
		} catch (e) {
            this.openNotificationWithIcon('error',e.message,'bottomRight')
			message.error(e.message);
			console.error(e);
			return this.setState({loading : false });
		}
    };

    onSendEmail = async () => {
        this.setState({ loading : true,loadingButton: true });
		let { shopId,fromDate, toDate } = this.state.filter;
		const datatosend = this.state.display;
		try {
			reportsService.sendTotalsToShopEmail({data:datatosend, shop_id:shopId, fromDate:fromDate, toDate:toDate})
            .then(()=>{
                this.openNotificationWithIcon('success','Mail With Attachment sent Successfuly','bottomRight')
                this.setState({ loading : false,loadingButton: false });
            });
		} catch (e) {
			message.error(e.message);
            this.openNotificationWithIcon('error',e.message,'bottomRight')
            this.setState({ loading : false,loadingButton: false });
		}
    };

    openNotificationWithIcon = (type,msg,placement) => {
        notification[type]({
            message: 'Execution Result',
            description: msg,
            placement
        });
	};
	
	SetOperatorFilter = (val) => {

		const newDisplay = this.filterReports({...this.state.filter,operator: val})

		this.setState({
			display: newDisplay,
			filter: {
				...this.state.filter,
				operator: val
			}
		});	
	}
	
	filterByCurrency = currency => report => report.currency.code === currency;

	filterByOperator = (operator) => report => report.operator === operator;

	filterReports = filters => {
		return this.state.data
			.sort()
			.reduce((agg, date) => {
				let AllReport = this.state.data;
				Object.keys(filters).forEach(filter => {
					let func = this[`filterBy${titlize(filter)}`];
					if (!func || filters[filter] === null) return AllReport;
					func = func.bind(this);
					AllReport = AllReport.filter(func(filters[filter]));
				});
				return AllReport;
			}, []);
	};

	render() {
		return (
			<Spin spinning={this.state.loading}>
				<Row className="p-2">
					<span className="mr-5">
						<span style={{ fontWeight: '500' }}> From </span>
						<DatePicker
							value={this.state.filter.fromDate}
							format="YYYY-M-D"
							onChange={val => {
								if (!val) return this.setState({ ready: false });
								let toDate = this.state.filter.toDate;
								if (toDate && toDate <= val) {
									message.error('From date must be before to date.');
									val = '';
								}
								this.setState({ filter: { ...this.state.filter, fromDate: val } });
							}}
						/>
					</span>
					<span className="mr-5">
						<span style={{ fontWeight: '500' }}> To </span>
						<DatePicker
							value={this.state.filter.toDate}
							format="YYYY-M-D"
							onChange={val => {
								if (!val) return this.setState({ ready: false });
								let fromDate = this.state.filter.fromDate;
								if (fromDate && val <= fromDate) {
									message.error('To date must be after from date.');
									val = '';
								}
								this.setState({ filter: { ...this.state.filter, toDate: val } });
							}}
						/>
					</span>
					{
						this.props.dashboard ?
						<span className="mr-5">
							<span style={{ fontWeight: '500' }}> Shop </span>
							<Select
								allowClear
								onChange={val => {
									this.setState({ filter: { ...this.state.filter, shopId: val } });
								}}
								placeholder="Select Shop"
								showSearch
							>
								{this.state.shops.map(shop => (
									<Select.Option value={shop.id} key={shop.id}>
										{shop.name}
									</Select.Option>
								))}
							</Select>
						</span>
						:
						<span/>	
					}
					<span className="mr-5">
						<Button
							type="primary"
							onClick={this.onSearch}
							disabled={!(this.state.filter.shopId && this.state.filter.toDate && this.state.filter.fromDate)}
						>
							Search
						</Button>
					</span>
				</Row>
				{this.state.ready && (
					<Row className="my-3">
						<Col xs={8}>
							<span>Operator: </span>
							<Select
								allowClear
								onChange={val => {
									if (val === 0 || !val)
									{
										this.setState({ display: this.state.data });
									}
									else
									{
										this.SetOperatorFilter(val)									
									}
								}}
								className="w-75"
								placeholder="Select Operator"
								showSearch
								filterOption={filterOpts}
							>
								{this.state.Operators.map((c,idx) => (
									<Select.Option value={c} key={`test${idx}`}>
										<span>{c}</span>
									</Select.Option>
								))}
							</Select>
						</Col>
					</Row>
				)}
				<div
					style={{
						minHeight: '500px',
						textAlign: 'center'
					}}
				>
					{this.state.ready ? (
                        <div style={{marginBottom:'15px'}}>
						    <h5>Detailed Operations</h5>
						    <Table bordered pagination={{ pageSize: 100 }} columns={this.columns} dataSource={this.state.display} size="small"/>
                            <h5>Total USD</h5>
						    <Table bordered title={() => ''} pagination={{ pageSize: 100 }} columns={this.total_columns} dataSource={this.state.totals} size="small"/>
                            <h5>Totals Other Currencies</h5>
						    <Table bordered title={() => ''} pagination={{ pageSize: 100 }} columns={this.total_forign_columns} dataSource={this.state.totals_forign} size="small"/>
                            <Button type="warning" style={{minWidth:'215px'}} icon={<FileExcelTwoTone  />}  htmlType="button" loading={this.loadingButton} onClick={this.onExport}>
                                Export to Excel
                            </Button>
                             |
                            <Button  type="primary" icon={<MailTwoTone  twoToneColor="#eb2f96"/>} htmlType="button" loading={this.loadingButton} onClick={this.onSendEmail}>
                                Send Result To Shop Email
                            </Button> 
                        </div>
					) : (
						<p style={{ fontSize: '18px', fontWeight: '700', padding: '1rem 0' }}>
							Please select time range
						</p>
					)}
				</div>
			</Spin>
		);
	}
}