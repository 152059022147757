import ResourceManager from './resource-manager';
import config from '../utils/config';

class StoksService extends ResourceManager {
	async addStock(data) {
		return await this.postResource(config.URIs.add_stock, data);
	}

	async addStockByFile(data) {
		return await this.postResource(config.URIs.add_stock_by_file, data);
	}

	async listStock() {
		return await this.getResource(config.URIs.list_stock);
	}

	async listStockDetails() {
		return await this.getResource(config.URIs.list_stock_details);
	}

	async transferStock(data) {
		return await this.postResource(config.URIs.transfer_stock, data);
	}

	async getHistory(data) {
		return await this.postResource(config.URIs.stock_history, data);
	}
}

export default new StoksService();
