import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Table, Button, Spin, message, Modal, Input, Col, Row, Radio } from 'antd';
import CurrencyService from '../../services/currency.service';
import { Link } from 'react-router-dom';
import useFetchData from '../../utils/fetchData';
import useEditRow from '../../utils/editRow';
import permissions from '../../constants/permissions';
import ComponentGuard from '../ComponentGuard/ComponentGuard';

const ListCurrencies = props => {
	const [data, setData, isLoading, setLoading] = useFetchData(
		CurrencyService.getCurrencies.bind(CurrencyService, { all: true })
	);
	const [display, setDisplay] = useState(data);
	const [displayActive, setDisplayActive] = useState(true);

	useEffect(() => {
		setDisplay(data.filter(c => c.active === +displayActive));
	}, [data, displayActive]);
	const edited = useEditRow();

	const columns = [
		{
			title: 'Currency Name',
			dataIndex: 'name',
			sorter: (a, b) => a.name.localeCompare(b.name),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'Code',
			dataIndex: 'code'
		},
		{
			title: 'flag',
			dataIndex: 'flag',
			render: (_, record) => <img src={record.flag} style={{ width: '48px', height: '32px' }} />
		},
		{
			title: 'Action',
			key: 'action',
			fixed: 'right',
			render: (text, record) => (
				<ComponentGuard permissions={[permissions.currenciesWriteAll]}>
					<React.Fragment>
					<Button
						danger={displayActive}
						size="small"
						type={'primary'}
						onClick={() => {
							Modal.confirm({
								onOk: async () => {
									try {
										var resp = await CurrencyService.toggleActive(record.id);
										if (!resp || !resp.key) throw new Error('Something went wrong');
									} catch (e) {
										message.error(e.message);
										console.error(e);
										return;
									}
									setData(
										data.map(c => {
											if (c.id === record.id) c.active = +!displayActive;
											return c;
										})
									);
								},
								title: 'Are you sure you want to deactivate this currency?'
							});
						}}
					>
						{displayActive ? 'Deactivate' : 'Active'}
					</Button>
					<Button size="small">
						<Link
							to={{
								pathname: '/dashboard/edit-currency',
								data: record
							}}
						>
							Edit
						</Link>
					</Button>
				</React.Fragment>
				</ComponentGuard>
			)
		}
	];

	async function deleteRecord(id) {
		let result = await CurrencyService.deleteCurrency(id);
		if (result.msg === 'delete it sucessfull') {
			let newData = data.filter(record => record.id !== id);
			setData(newData);
			message.success('the currency was deactivated successfully');
		}
	}

	return (
		<React.Fragment>
			<h1 className="h3"> List Currencies</h1>
			<Spin spinning={isLoading}>
				<Row className="my-3">
					<Col xs={8}>
						<span>Currency: </span>
						<Input.Search
							allowClear
							onChange={e => {
								let val = e.target.value;
								setDisplay(
									data.filter(
										c =>
											c.name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
											c.code.toLowerCase().indexOf(val.toLowerCase()) > -1
									)
								);
							}}
							className="w-75"
							placeholder="Search"
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<Radio.Group
							buttonStyle="solid"
							defaultValue="active"
							onChange={e => {
								console.log(e.target.value);
								setDisplayActive(e.target.value === 'active');
							}}
						>
							<Radio.Button type="primary" value="active">
								active
							</Radio.Button>
							<Radio.Button type="primary" danger value="inactive">
								inactive
							</Radio.Button>
						</Radio.Group>
					</Col>
				</Row>
				<Table
					size="small"
					columns={columns}
					dataSource={display}
					rowClassName={(record, _) => (record.id == edited ? 'table-row-dark' : 'table-row-light')}
				/>
			</Spin>
		</React.Fragment>
	);
};

export default ListCurrencies;
