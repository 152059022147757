import React,{useState,useEffect,useMemo } from 'react';
import ctrService from '../../services/ctr-service';
import {BellTwoTone} from '@ant-design/icons';
import {  message } from 'antd';


const CtrCounter = () =>
{

    let [ctr_list, setCtrList] = useState([]);

    async function onRecievePush()
    {
        let data={};
        try
        {
            data = await ctrService.getShopNeededCtr();
            if (!data.success) throw new Error(data.message);
        }
        catch (e)
        {
            message.error(e.message);
            console.error(e);
        }

        if(data && data.data)
            setCtrList(data.data);
    }

    useEffect(() => {
        onRecievePush();
    }, []);
    
    let toShow = ctr_list.length>0  
        ? <span className="ctr-count"><BellTwoTone /></span>
        : ''
    return(toShow);
}

export default CtrCounter;