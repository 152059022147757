import React, { Fragment } from 'react';
import DashboardSidebar from '../components/DashboardSidebar/DashboardSidebar';
import AgentTree from '../components/AgentTree/AgentTree';
import { DashboardNavbar } from '../components/DashboardNavbar/DashboardNavbar';
import { Route } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import useFetchData from '../utils/fetchData';

import BrokerForm from '../components/BrokerForm/BrokerForm';
import AddNewCurrency from '../components/AddNewCurrency/AddNewCurrency';
import AddNewEmployee from '../components/AddNewEmployee/AddNewEmployee';
import RoleForm from '../components/RoleForm/RoleForm';
import AddNewShop from '../components/AddNewShop/AddNewShop';
import DashboardControl from '../components/DashboardControl/DashboardControl';
import InsertStock from '../components/InsertStock/InsertStock';
import ListBrokers from '../components/ListBrokers/ListBrokers';
import ListCurrencies from '../components/ListCurrencies/ListCurrencies';
import ListCustomers from '../components/ListCustomers/ListCustomers';
import ListEmployees from '../components/ListEmployees/ListEmployees';
import ListRoles from '../components/ListRoles/ListRoles';
import ListShops from '../components/ListShops/ListShops';
import ShopService from '../services/shop.service';
import Reports from '../components/Reports/Reports';
import ListSlabs from '../components/ListSlabs/listSlabs';
import AddNewSlab from '../components/AddNewSlab/addNewSlab';
import ListFees from '../components/ListFees/listFees';
import AddNewFees from '../components/AddNewFees/addNewFess';
import EditSystemRequests from '../components/EditSystemRequests/EditSystemRequests';
import SystemSettings from '../components/SystemSettings/SystemSettings';
import EditCustomers from '../components/EditCustomer/editCustomer';
import InsertStockByFile from '../components/InsertStockByFile/InsertStockByFile';
import ExchangeBetween from '../components/ExchangeBetween/ExchangeBetween';
import ListStock from '../components/ListStock/ListStock';
import ReportsListContainer from '../containers/ReportsList/ReportsList';
import ProfitReportContainer from '../containers/ProfitReport/ProfitReport';
import TotalsReportContainer from '../containers/ProfitReport/TotalsReportContainer';
import DetailsReportContainer from '../containers/ReportsList/DetailsReportContainer';
import FiatReportContainer from '../containers/ReportsList/FiatReportContainer';

export function DashboardLayout(props) {
	const [treeData, setTreeData, isLoading, setLoading] = useFetchData(
		ShopService.getShops.bind(ShopService, 1,0,true),
		e => {
			console.error(e.message);
		},
		['SHOPS'],
		true
	);

	const onSelectedShop = shop_id => {
		props.history.push(`/dashboard/edit-shop/${shop_id}`);
	};

	const onSelectedEmployee = user_id => {
		props.history.push(`/dashboard/edit-employee/${user_id}`);
	};

	return (
		<div id="wrapper">
			<Col span={3} className=" bg-gradient-primary sidebar sidebar-dark accordion right-side shadow">
				<DashboardSidebar key={Date.now()} />
			</Col>
			<Col span={17}>
				<div id="content-wrapper vh-100" className="d-flex flex-column">
					<div id="content">
						<DashboardNavbar props={props}/>
						<Route path="/dashboard/control" exact component={DashboardControl} />
						<Route path="/dashboard/list-shops" exact component={ListShops} />
						<Route path="/dashboard/add-new-shop" exact component={AddNewShop} />
						<Route path="/dashboard/edit-shop/:id" exact component={AddNewShop} />
						<Route path="/dashboard/list-roles" exact component={ListRoles} />
						<Route path="/dashboard/add-new-role" exact component={RoleForm} />
						{/* <Route path="/dashboard/edit-role" exact component={AddNewRoles} /> */}
						<Route path="/dashboard/edit-role/:id" exact component={RoleForm} />
						<Route path="/dashboard/list-employees" exact component={ListEmployees} />
						<Route path="/dashboard/add-new-employee" exact component={AddNewEmployee} />
						{/* <Route path="/dashboard/edit-employee" exact component={AddNewEmployee} /> */}
						<Route path="/dashboard/edit-employee/:id" exact component={AddNewEmployee} />
						<Route path="/dashboard/list-brokers" exact component={ListBrokers} />
						<Route path="/dashboard/add-new-broker" exact component={BrokerForm} />
						<Route path="/dashboard/edit-broker/:id" exact component={BrokerForm} />
						<Route path="/dashboard/list-customers" exact component={ListCustomers} />
						<Route path="/dashboard/edit-customer" exact component={EditCustomers} />
						<Route path="/dashboard/list-currencies" exact component={ListCurrencies} />
						<Route
							path="/dashboard/transactions-reports"
							exact
							render={props => (
								<Fragment>
									<h3 className="h3">Transaction Reports:</h3>
									<ReportsListContainer dashboard {...props} />
								</Fragment>
							)}
						/>

						<Route
							path="/dashboard/profit-reports"
							exact
							render={props => (
								<Fragment>
									<h3 className="h3">Profit Reports:</h3>
									<ProfitReportContainer dashboard {...props} />
								</Fragment>
							)}
						/>

						<Route
							path="/dashboard/totals-reports"
							exact
							render={props => (
								<Fragment>
									<h3 className="h3">Totals Reports:</h3>
									<TotalsReportContainer dashboard {...props} />
								</Fragment>
							)}
						/>

						<Route
							path="/dashboard/details-reports"
							exact
							render={props => (
								<Fragment>
									<h3 className="h3">Detailed Reports:</h3>
									<DetailsReportContainer dashboard {...props} />
								</Fragment>
							)}
						/>
						<Route
							path="/dashboard/fiat-reports"
							exact
							render={props => (
								<Fragment>
									<h3 className="h3">Fiat Reports:</h3>
									<FiatReportContainer dashboard {...props} />
								</Fragment>
							)}
						/>
						<Route path="/dashboard/add-new-currency" exact component={AddNewCurrency} />
						<Route path="/dashboard/edit-currency" exact component={AddNewCurrency} />
						<Route path="/dashboard/list-stocks" exact component={ListStock} />
						<Route path="/dashboard/insert-stocks" exact component={InsertStock} />
						<Route path="/dashboard/insert-stocks-by-file" exact component={InsertStockByFile} />
						<Route path="/dashboard/exchange-between" exact component={ExchangeBetween} />
						<Route path="/dashboard/list-slabs" exact component={ListSlabs} />
						<Route path="/dashboard/add-new-slab" exact component={AddNewSlab} />
						<Route path="/dashboard/edit-slab" exact component={AddNewSlab} />
						<Route path="/dashboard/list-fees" exact component={ListFees} />
						<Route path="/dashboard/add-new-fees" exact component={AddNewFees} />
						<Route path="/dashboard/edit-fees" exact component={AddNewFees} />
						<Route path="/dashboard/reports" exact component={Reports} />
						<Route path="/dashboard/system_requests/:request_type" exact component={EditSystemRequests} />
						<Route path="/dashboard/system-settings" exact component={SystemSettings} />
					</div>
				</div>
			</Col>
			<Col span={4} className="left-side shadow">
				<Row className="bg-primary nav-item dropdown no-arrow">
					<Col span={24} className="tree-header" justify="center">
						Agent Tree
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						{!isLoading ? (
							<AgentTree
								onSelectedShop={onSelectedShop}
								onSelectedEmployee={onSelectedEmployee}
								treeData={treeData}
							/>
						) : (
							<Spin></Spin>
						)}
					</Col>
				</Row>
			</Col>
		</div>
	);
}
