import config from '../config/config';

let configProxy = new Proxy(config[process.env.APP_ENV || 'development'], {
	set(target, p, v) {
		throw new Error(`Config is immutable object, can't modify config object.`);
	},
	get(target, p) {
		let url = (p && p.split('_').pop().toLowerCase() === 'url') || false;
		if (!url) return target[p];
		return target[p] || target.URIs[p] || undefined;
	}
});

export function getConfig() {
	return configProxy;
}

export default configProxy;
