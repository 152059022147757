import React, { useState } from 'react';
import styled from 'styled-components';
import { CheckSquareOutlined , CloseSquareOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import {ArrowRightOutlined  } from '@ant-design/icons';

const ConversionResultContainer = styled.div`
	& {
		position: absolute;
		bottom: 0;
		left: 2px;
		border-top: 1px solid #cccccc;
		box-shadow: 0px 0px 5px 0px rgb(6 6 6 / 81%);
		width: 100%;
		border-radius: 0 0 0 15px;
		height: 103px;
	}
	& div:nth-of-type(1) {
		font-size: 18px;
		color: #18357e;
		padding-right: 0;
	}
	& div:nth-of-type(2) {
		padding: 0;
	}
	& div:nth-of-type(2) p {
		color: #000;
		font-weight: 500;
	}
	& div p {
		margin: 0;
	}
	& div:nth-of-type(2) p:first-of-type {
		font-size: 105%;
		font-weight: 500;
		color: #080;
	}
	& div:nth-of-type(2) sub {
		color: #18357e;
	}
	& button {
		background-color: #008800;
		color: #fff;
		border: 0;
		padding: 5px 9px;
		-webkit-letter-spacing: 2px;
		-moz-letter-spacing: 2px;
		-ms-letter-spacing: 2px;
		letter-spacing: 2px;
		font-weight: 500;
		border-radius: 5px;
		font-size: 13px;
	}
	& button:hover {
		box-shadow: 0 4px 8px rgba(32, 33, 36, 0.59);
		border-color: rgba(223, 225, 229, 0);
	}
	& button:active {
		box-shadow: unset;
		border-color: rgba(223, 225, 229, 0);
	}
	& .btn-start {
		margin-top: 0px;
		width: 100%;
  		height: 100%;
	}
	& .btn-add {
		background-color: #18357e;		
		width: calc(50% - 21px);
	}
	& .btn-cancel {
		background: #b10000;		
		width: calc(50% - 21px);
	}
	& .main-price-block
	{
		padding: 0px;
		width: 187px;
		display: inline-block;
		text-align: center;
		background-image: linear-gradient(#ffffff14,#c3c3c382);
		color: black;
		margin-right:5px;
	}
	& .main-price-button
	{
		border: 1px solid #d2d2d2;
		padding: 0px;
		width: 30%;
		display: inline-block;
		text-align: center;
		border-radius: 5px;
		background-image: linear-gradient(#ffffff14,#c3c3c382);
		color: black;
		box-shadow: 0 1px 2px 0 rgb(2 1 0 / 68%);
		margin-right:5px;
	} 
	& .main-amount-icon-button 
	{
		float: right !important;
		min-width: 11% !important;
		font-size: 1.3rem;
		cursor: pointer;
		margin: 1% !important;
		line-height: 1rem;
	}
	&
	.main-amount-input 
	{
		width: fit-content !important;
	}
	& .span.main-currency {
		font-size: 25px;
	}
	& .main-amount-edit input[type="number"] {
		width: 167px;
		height: 43px;
		border: 1px solid lightblue;
		border-radius: 15px;
		text-align: center;
	} 
	& .main-amount-edit {
		position: absolute;
		top: 0;
		left: 30%;
		text-align: center;
		background-color: white;
		padding: 3px;
		height: 39px;
		width: 70%;
		box-shadow: 0 1px 6px 0 rgb(6 6 6 / 81%);
		border-radius: 5px;
	}&
	.main-amount-edit button {
		padding: 3px;
		float: none;
		width: 41%;
		height: 30px;
		background-color: #1890ff;
		margin-top: 17px;
		display: inline-block;
		margin-left: 6px;
	}&
	.close-icon {
		position: absolute;
		top: -9px;
		right: -3px;
		cursor: pointer;
		background: white;
		width: 19px;
		height: 19px;
		color: red;
		border-radius: 50%;
	}
	small
	{
		display:block;
		font-size:53%;
	}
	.anticon {
		vertical-align: baseline;
		margin: 0 5px;
	}
`;

function ConversionResultBox({
	can_edit_rate,
	fromCurrency,
	toCurrency,
	fromAmount,
	is_sell,
	toAmount,
	exchangeRate,
	onCancel,
	onStart,
	onAddNewOperation,
	onSetPresetAmount
}) {
	let usdExchangeRate = Number.parseFloat(exchangeRate).toFixed(6);
	let foreignExchangeRate = Number.parseFloat(1 / exchangeRate).toFixed(6);

	const [selectedTargetAmount,SetSelectedTargetAmount] = useState(toAmount);
	const [editAmoutShow,SetEditAmoutShow] = useState(false);
	
	const onAmountHideClick = () =>
	{
		SetEditAmoutShow(false);
	}
	
	const onAmountShowClick = () =>
	{
		SetEditAmoutShow(true);
	}

	const onTargetSubmit = () =>
	{
		onSetPresetAmount('amount',selectedTargetAmount);
		SetEditAmoutShow(false);
	}


    let Currency1 = (is_sell) ?   fromCurrency : toCurrency;
	let Currency2 = (is_sell) ?   toCurrency :  fromCurrency;

	return (
		<ConversionResultContainer
			className="result col-sm-12 mt-3 p-3"
			style={{ backgroundColor: 'rgba(255, 255, 255, .6' }}
		>
			<div className="row">
				<div className="col-lg-10" style={{ fontSize: '100%' }}>
					<div className="col-lg-12">
						<span className="main-price-block"> {fromAmount}</span>
						<span className="main-currency">{Currency1}</span>
						<ArrowRightOutlined />
						<span className={can_edit_rate ? "main-price-button" : "main-price-block"} onClick={onAmountShowClick}>
							{toAmount}
						</span>
						{
							editAmoutShow && can_edit_rate
							?   <span className="main-amount-edit">
									<InputNumber className="main-amount-input" value={toAmount.replace(',','')} 
										onChange={e => {
											SetSelectedTargetAmount && SetSelectedTargetAmount(e);
										}}										
										onKeyDown={e => {
											if (e.keyCode === 13) {
												e.preventDefault();
												onTargetSubmit();
											}
										}}
									/>
									<span className="main-amount-icon-button" onClick={onTargetSubmit}>
										<CheckSquareOutlined className="green" />
									</span>
									<span className="main-amount-icon-button " onClick={onAmountHideClick}>
										<CloseSquareOutlined  className="red"/>
									</span>
								</span> 
							:
							''
						}
						<span className="main-currency">{Currency2}</span>
						<small>
							1 {toCurrency} = {toCurrency === 'USD' ? usdExchangeRate : foreignExchangeRate} {fromCurrency}
							| 
							1 {fromCurrency} = {fromCurrency === 'USD' ? usdExchangeRate : foreignExchangeRate} {toCurrency}
						</small>
					</div>
					<div className="col-lg-12">
						<button className="btn-add" onClick={onAddNewOperation}>ADD OPERATION</button> | <button className="btn-cancel" onClick={onCancel}>CANCEL</button>
					</div>
				</div>
				<div className="col-lg-2">
					<button className="btn-start" onClick={onStart}>START</button>					
				</div>
			</div>
		</ConversionResultContainer>
	);
}

export default ConversionResultBox;
