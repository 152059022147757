import ResourceManager from './resource-manager';
import config from '../utils/config';

class FeesService extends ResourceManager {
	async getFees() {
		return await this.getResource(config.URIs.get_fees);
	}
	async deleteFees(id) {
		return await this.deleteResource(config.URIs.delete_fees.replace(/:id/g, id));
	}
	async addNewFees(data) {
		return await this.postResource(config.URIs.add_new_fees, data);
	}
	async editFees(data, id) {
		return await this.putResource(config.URIs.edit_fees.replace(/:id/g, id), data);
	}
}

export default new FeesService();
