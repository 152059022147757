import React from 'react';
import 'antd/dist/antd.css';
import { Table, Button, Spin, message, Modal, Tag, Tooltip, Select, Row, Col } from 'antd';
import FeesServics from '../../services/fees.service';
import { Link } from 'react-router-dom';
import useFetchData from '../../utils/fetchData';
import useEditRow from '../../utils/editRow';
import currencies from '../../constants/currencies';
import { FILTERS, FILTER_UTILS, useFilters, filterOpts, filterOptsShop } from '../../hooks/filters.hook';

const ListFees = props => {
	const [data, setData, isLoading, setLoading] = useFetchData(FeesServics.getFees.bind(FeesServics));
	const edited = useEditRow();
	const { display, shops, setFilters, filters } = useFilters({
		data,
		filters: [FILTERS.CURRENCY, FILTERS.SHOP, FILTERS.TYPE],
		filterFunctions: FILTER_UTILS
	});

	const columns = [
		{
			title: 'Boundary',
			dataIndex: 'boundary',
			sorter: (a, b) => a.boundary - b.boundary
		},
		{
			title: 'Value',
			dataIndex: 'value',
			sorter: (a, b) => a.value - b.value
		},
		{
			title: 'Currency',
			dataIndex: 'currency_id',
			render: (_, record) => (
				<h4 className="blue">
					{' '}
					{record.currency ? (
						record.currency.code
					) : (
						<Tooltip title="This fees is applied for all currencies">
							<Tag color="gold" style={{ fontSize: '24px' }}>
								Generic
							</Tag>
						</Tooltip>
					)}{' '}
				</h4>
			)
		},
		{
			title: 'Shop',
			dataIndex: 'shop_id',
			render: (_, record) => (
				<h4 className="blue">
					{' '}
					{record.shop ? (
						record.shop.name
					) : (
						<Tooltip title="This fees is applied for all shops">
							<Tag color="gold" style={{ fontSize: '24px' }}>
								Generic
							</Tag>
						</Tooltip>
					)}{' '}
				</h4>
			)
		},
		{
			title: 'Is Fixed',
			dataIndex: 'is_fixed',
			render: value => {
				if (!value) {
					return <Tag color="geekblue"> Ratio </Tag>;
				}
				return <Tag color="blue"> Fixed </Tag>;
			}
		},
		{
			title: 'Type',
			dataIndex: 'is_sell',
			render: value => {
				if (!value) {
					return <Tag color="geekblue"> Buy </Tag>;
				}
				return <Tag color="geekblue"> Sell </Tag>;
			}
		},

		{
			title: 'Action',
			key: 'action',
			fixed: 'right',
			render: (text, record) => (
				<React.Fragment>
					<Button
						danger
						size="small"
						onClick={() => {
							Modal.confirm({
								onOk: deleteRecord.bind(this, record.id),
								title: 'Are you sure you want to deactivate this Fee?'
							});
						}}
					>
						Deactivate
					</Button>
					<Button size="small">
						<Link
							to={{
								pathname: '/dashboard/edit-fees',
								data: record
							}}
						>
							Edit
						</Link>
					</Button>
				</React.Fragment>
			)
		}
	];

	async function deleteRecord(id) {
		let result = await FeesServics.deleteFees(id);
		console.log(result);
		if (result.success) {
			let newData = data.filter(record => record.id !== id);
			setData(newData);
			message.success('the Fee was deactivated successfully');
		}

		// console.log('clicked---------------------')
	}

	return (
		<div>
			<h1 className="h3"> List Fees</h1>
			<div className="pl-2">
				{!isLoading && (
					<Row className="my-3">
						<Col xs={8}>
							<span>Type: </span>
							<Select
								placeholder="Select report type"
								allowClear
								className="w-75"
								onChange={val => {
									setFilters({
										...filters,
										type: val === 0 || val === undefined ? null : val === 1 ? 'Sell' : 'Buy'
									});
								}}
							>
								<Select.Option value={0}>All</Select.Option>
								<Select.Option value={1}>Sell</Select.Option>
								<Select.Option value={2}>Buy</Select.Option>
							</Select>
						</Col>
						<Col xs={8}>
							<span>Currency: </span>
							<Select
								allowClear
								onChange={val => {
									setFilters({
										...filters,
										currency: val
									});
								}}
								className="w-75"
								placeholder="Select Currency"
								showSearch
								filterOption={filterOpts}
							>
								{Object.keys(currencies).map(c => (
									<Select.Option value={c} key={currencies[c]}>
										<span>{c}</span>
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col xs={8}>
							<span>Shop: </span>
							<Select
								allowClear
								onChange={val => {
									setFilters({
										...filters,
										shop: val
									});
								}}
								className="w-75"
								placeholder="Select Shop"
								showSearch
								filterOption={filterOptsShop}
							>
								{shops.map(shop => (
									<Select.Option value={shop.id} key={shop.id}>
										{shop.name}
									</Select.Option>
								))}
							</Select>
						</Col>
						{/* <ComponentGuard
							permissions={[permissions.shopsReadAll, permissions.shopsReadHierarchy]}
						></ComponentGuard> */}
					</Row>
				)}
			</div>
			<Spin spinning={isLoading}>
				<Table
					size="small"
					columns={columns}
					dataSource={display}
					rowClassName={(record, index) => (record.id == edited ? 'table-row-dark' : 'table-row-light')}
				/>
			</Spin>
		</div>
	);
};

export default ListFees;
