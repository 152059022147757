import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Table, Button, Spin, message, Modal, Select, Col, Row,InputNumber } from 'antd';
import useFetchData from '../../utils/fetchData';
import StockService from '../../services/stock.service';
import currencies from '../../constants/currencies';
import { FILTERS, FILTER_UTILS, useFilters, filterOpts, filterOptsShop } from '../../hooks/filters.hook';
import { formatDashedNumber } from '../../utils/strings';

const ExchangeBetween = (props) => {
	const [data, setData] = useFetchData(StockService.listStockDetails.bind(StockService));
	const [isLoadingPage,setLoading] = useState(true);
	const [selectedRecord, setSelectedRecord] = useState({});
	const [transferAgent, setTransferAgent] = useState(null);
	const [transferAmount, setTransferAmount] = useState(null);
	const [transfer_visible, setShowTransfer] = useState(false);
	const { display, shops, setFilters, filters } = useFilters({
		data,
		filters: [FILTERS.CURRENCY, FILTERS.SHOP, 'country'],
		filterFunctions: {
			...FILTER_UTILS,
			filterByShopName: shop => item => item.shopName === shop,
			filterByCurrency: currency => item => item.currencyCode === currency
		}
	});

	useEffect(() => {
		if (data.length>0)
			setLoading(false);
	});

	const onCancel = () => {
		setShowTransfer(false);
	};

	async function onSubmit() {
		
		if (transferAmount > selectedRecord.amount || transferAmount < 0.1) {
			message.error('Please Choose A Valid Amount (Not greater than the original Amount [' + selectedRecord.amount + '])');
			return;
		}
		if (selectedRecord.shop_id === transferAgent) {
			message.error('Please Choose A Diffenernt Shop Other Than The Original');
			return;
		}
		setLoading(true);
		const datatosend = {
			stock_id: selectedRecord.stock_id,
			currencyCode: selectedRecord.currencyCode,
			shop_id: selectedRecord.shop_id,
			currency: selectedRecord.currency_id,
			amount: selectedRecord.amount,
			original_amount: selectedRecord.amount,
			newShop: transferAgent,
			newAmount: transferAmount
		}

		let result = await StockService.transferStock(datatosend);
		if (result) {
			setData(result);
			message.success('the Amount was transfered successfully');
			setShowTransfer(false);
		}
		setLoading(false);
    }

	const columns = [
		{
			title: 'Amount',
			dataIndex: 'amount',
			sorter: (a, b) => a.amount - b.amount,
			sortDirections: ['descend'],
			render: (amount) => formatDashedNumber(amount)
		},
		{
			title: 'Currency',
			dataIndex: 'currencyName'
		},
		{
			title: 'Currency Code',
			dataIndex: 'currencyCode'
		},
		{
			title: 'Shop',
			dataIndex: 'shopName'
		},
		{
			title: 'Transfer To',
			key: 'action',
			fixed: 'right',
			ellipsis: true,
			render: (text, record) => (
				<React.Fragment>
					<Button
						type="primary"
						size="small"
						onClick={() => {
							setSelectedRecord(record);
							setTransferAgent(record.shop_id);
							setTransferAmount(record.amount);
							setShowTransfer(true);
						}}
					>
						Transfer To Agent
					</Button>
				</React.Fragment>
			)
		}
	];
	const filterOpts = (input, option) => option.props.key.toLowerCase().startsWith(input.toLowerCase());

	return (
		<div>
			<h1 className="h3"> List Stocks</h1>
			<div className="pl-2">
				{!isLoadingPage && (
					<>
						<Row className="my-3">
							<Col xs={8}>
								<span>Shop Name: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											shopName: val
										});
									}}
									className="w-75"
									placeholder="Select Shop"
									showSearch
									filterOption={filterOptsShop}
								>
									{shops.map(shop => (
										<Select.Option value={shop.name} key={shop.id}>
											{shop.name}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col xs={8}>
								<span>Currency: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											currency: val
										});
									}}
									className="w-75"
									placeholder="Select Currency"
									showSearch
									filterOption={(input, option) =>
										option.props.key.toLowerCase().startsWith(input.toLowerCase()) ||
										option.props.value.toLowerCase().startsWith(input.toLowerCase())
									}
								>
									{Object.keys(currencies).map(c => (
										<Select.Option value={c} key={currencies[c]}>
											<span>{c}</span>
											<br />
											<small>{currencies[c]}</small>
										</Select.Option>
									))}
								</Select>
							</Col>
						</Row>
					</>
				)}
			</div>			
			<Spin spinning={isLoadingPage}>
				<Table size="small" columns={columns} dataSource={display} />
			</Spin>
			<Modal okText="Transfer" title="Select Agent And Amount" visible={transfer_visible} onCancel={onCancel} onOk={onSubmit}>
				<InputNumber  style={{width:'100%'}} prefix={selectedRecord.currency} placeholder="Amount to Transfer" key="btn_rate_change" value={transferAmount}  onChange={(val)=>{setTransferAmount(val)}} /> 
				<Select
					style={{ width: '100%' }}
					onChange={
						(shop) => setTransferAgent(shop)
						}
					allowClear
					filterOption={(value, option) => option.children.indexOf(value.toLowerCase()) > -1}
					defaultValue={transferAgent}
				>
					{shops.map(shop => (
						<Select.Option value={shop.id} key={shop.id}>
							{shop.name}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</div>
	);
};

export default ExchangeBetween;
