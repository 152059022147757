import ResourceManager from './resource-manager';
import config from '../utils/config';

class EmployeeService extends ResourceManager {
	async getEmployees() {
		return await this.getResource(config.URIs.get_employees);
	}

	async getShopEmployees(shopId) {
		if(shopId>0)
			return await this.getResource(config.URIs.get_shop_employees.replace(/:shopId/g, shopId));
		else
			return {};
	}

	async getEmployee(id) {
		return await this.getResource(config.URIs.get_employee.replace(/:id/g, id));
	}

	async changeEmployeeRole(userId, roleId) {
		return await this.postResource(config.URIs.change_employee_role, {
			user_id: userId,
			role_id: roleId
		});
	}

	async deleteEmployee(id) {
		return await this.deleteResource(config.URIs.delete_employees.replace(/:id/g, id));
	}

	async addNewEmployee(data) {
		return await this.postResource(config.URIs.add_new_employee, data);
	}

	async editEmployee(id, data) {
		return await this.postResource(config.URIs.edit_employee.replace(/:id/g, id), data);
	}

	async toggleActive(id) {
		return await this.getResource(config.URIs.toggle_active_employee.replace(/:id/g, id));
	}
}

export default new EmployeeService();
