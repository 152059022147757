import React, { Component,memo,useContext  } from 'react';

import ConvertAmountForm from '../../components/ConvertAmount/ConvertAmountForm';
import Summary from '../../components/summary/TransactionSummary';
import ConvertOpeationChooser from '../../components/ConvertAmount/ConvertOpeationChooser';
import CustomerInfoForm from '../../components/CustomerForm/CustomerInfoForm';
import { formatDashedNumber } from '../../utils/strings';
import transactionService from '../../services/transaction.service';
import { message, Row, Spin, Modal, Col, notification, InputNumber } from 'antd';
import { Observable, Subject, Subscription } from 'rxjs';
import { switchMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import custoemrService from '../../services/customer-service';
import { InvoiceModal } from '../../components/Invoices/InvoiceModal';
import { fixAutocomplete } from '../../utils/dom';
import SummaryList from '../../components/summary/SummaryList';
import ConversionResultBox from '../../components/ConversionResult/ConversionResultBox';
import authService from '../../services/auth.service';
import permissions from '../../constants/permissions';
import bgEarth from '../../assets/earth.png';

class convertCurrency extends Component {

	state = {
		form: {},
		toCurrency: null,
		trx_count_limit: null,
		fromCurrency: 'USD',
		inputAmount: '',
		slab: 0,
		fees: 0,
		exchangeRate: 0,
		outputAmount: 0,
		showResult: false,
		showCustomerInfo: false,
		showCustomerForm: false,
		currentTransactionId: null,
		isSell: true,
		amountInUSD: true,
		feesIncluded: false,
		amountInHand: true,
		loading: false,
		customers: [],
		showInvoice: false,
		invoice: null,
		customerSearchKey: 'mobile',
		customerType: false,
		selectedCustomer: null,
		editFactorShow: false,
		onGoingOperation: false,
		onGoingCustomerPayment: '',
		storedTransactions: [],
		multiMode: false,
		fetching: false,		
		spot_rate:<Spin size="small" />,
		rate_calc:<Spin size="small" />,
		preset_rate: null,
		currentConvertForm:null,
		totalFees:0,
		can_edit_rate: authService.hasPermissions(permissions.operationCanChangeRates),
		TotalUnsigned: 0
	};

	resetAllState = () => {
		this.setState({
			form: {},
			toCurrency:null,
			trx_count_limit: null,
			fromCurrency: 'USD',
			inputAmount: '',
			slab: 0,
			fees: 0,
			exchangeRate: 0,
			outputAmount: 0,
			showResult: false,
			showCustomerInfo: false,
			showCustomerForm: false,
			currentTransactionId: null,
			isSell: true,
			amountInUSD: true,
			feesIncluded: false,
			amountInHand: true,
			editFactorShow: false,
			loading: false,
			customers: [],
			showInvoice: false,
			invoice: null,
			customerSearchKey: 'mobile',
			customerType: false,
			selectedCustomer: null,
			onGoingOperation: false,
			onGoingCustomerPayment: '',
			storedTransactions: [],
			multiMode: false,		
			spot_rate:<Spin size="small" />,
			rate_calc:<Spin size="small" />,
			preset_rate: null,
			currentConvertForm:null,
			totalFees: 0,
			TotalUnsigned:0,
			OperationType: false,
		});
	}
	
	handleChange = () => this.props.onRecievePush();;

	subscriptions = new Subscription();
	search = new Subject().pipe(
		debounceTime(700),
		distinctUntilChanged(),
		switchMap(v => {

			return Observable.create(async observer => {
				if(v.trim().length < 1 )
				{
					observer.next([]);
					observer.complete();
					return;
				}
				custoemrService
				.searchCustomers({
					[this.state.customerSearchKey]: v.trim(),customerType:this.state.customerType
				})
				.then(resp => {
					observer.next(resp.data);
					observer.complete();
				})
				.catch(e => observer.error(e));
			});
		})
	);

	openNotificationWithIcon = (type,message,description) => {
		notification[type]({
		  message: message,
		  description:description,
		  duration:0
		});
	};

	componentDidMount = () => {
		this.subscriptions.add(
			this.search.subscribe(values => {
				this.setState({
					customers: values,
					fetching: values.length>0
				});
			})
		);
		//this.handleChange();
	};

	componentWillUnmount = () => {		
		this.subscriptions.unsubscribe();
		this.interval = null;
	};

	componentDidUpdate() {
		fixAutocomplete();
	}

	onChangeFactor = () => 
	{
		if(this.state.can_edit_rate)
		this.setState({editFactorShow:true});
	} 

	onFactorCancel = () => 
	{
		this.setState({editFactorShow:false});
	} 

	SetSelectedFactor = (preset_rate) => 
	{
		this.state.preset_rate = preset_rate;
		this.setState({preset_rate:preset_rate,preset_amount:null});
	} 

	onFactorSubmit = async (reset) => 
	{
		this.setState({
			loading_spot:true,
			spot_rate: <Spin size="small" />,
			rate_calc: <Spin size="small" />,
			exchangeRate: <Spin size="small" />,
			editFactorShow:false
		});
		let trForm = this.state.currentConvertForm;
		let resp;
		try {
			let data ={
				amount: trForm.amount,
				from: trForm.isSell ? 'USD' : trForm.otherCurrency,
				to: trForm.isSell ? trForm.otherCurrency : 'USD',
				amount_in_usd: trForm.amountInUSD,
				contain_fees: this.state.feesIncluded,
				in_hand: this.state.amountInHand,
				continue: this.state.multiMode,
				preset_rate: this.state.preset_rate,
			};
			
			if(this.state.storedTransactions.length<1)
				resp = await transactionService.getXhchnageInfo(data);
			else
			{
				this.onConvert(trForm, reset);
				return;
			}

			if (!resp) throw new Error('Something went wrong!');
			this.setState({
				loading_spot:false,
				spot_rate: resp.data.spot_rate,
				rate_calc: resp.data.rate_calc,
				exchangeRate: resp.data.exchange_rate
			}) 
		} catch (e) {
			message.error(e.message || e.msg);
			console.error(e);
			return this.setState({ loading_spot: false });
		}
	} 

	onResetPresets = () => {
		this.setState({
			preset_amount: null,
			preset_fees: null,
			preset_rate: null
		});
	}

	onFromCurrencyChange = async (trForm, reset,preset = undefined,preset_type = undefined) => 
	{
			this.setState({
				preset_amount: null,
				preset_fees: null,
				preset_rate: null
			});
		this.SetSelectedFactor(null);
		this.onConvert (trForm, reset);
	}

	onConvert = async (trForm, reset,preset = undefined,preset_type = undefined) => {
		// this.setState({ loading: true });

		this.resetCurrencyConversionForm = reset;

		this.setState({'currentConvertForm':trForm, onGoingOperation: false});
		if(!trForm.amount || !trForm.otherCurrency)
		{
			message.error("Please Fill the Amount And choose the Currency");
			return false;
		}

		if(!this.state.multiMode)
		{
			this.state.storedTransactions.pop();
		}

		let preset_amount = (preset_type === 'amount') ? preset : this.state.preset_amount;
		let preset_fees = (preset_type === 'fees') ? preset : this.state.preset_fees;
		let preset_rate = this.state.preset_rate;
		this.setState({
			loading_spot: true,
			amountInUSD: trForm.amountInUSD,
			isSell: trForm.isSell,
			otherCurrency:trForm.otherCurrency,
			toCurrency:trForm.otherCurrency,
			amount:trForm.amount,
			storedTransactions: this.state.storedTransactions,
			spot_rate: <Spin size="small" />,
			rate_calc: <Spin size="small" />,
			exchangeRate: <Spin size="small" />,
			preset_amount: preset_amount,
			preset_fees: preset_fees,
			preset_rate: preset_rate,
		});
		console.log(this.state);
		let resp;
		try {
			resp = await transactionService.getTransactionInfo({
				amount: trForm.amount,
				from: trForm.isSell ? 'USD' : trForm.otherCurrency,
				to: trForm.isSell ? trForm.otherCurrency : 'USD',
				amount_in_usd: trForm.amountInUSD,
				contain_fees: this.state.feesIncluded,
				in_hand: this.state.amountInHand,
				continue: this.state.storedTransactions.length>0,
				preset_amount: preset_amount,
				preset_rate: preset_rate,
				preset_fees: preset_fees
			});
			
			if (!resp) throw new Error('Something went wrong!');			

		} catch (e) {
			message.error(e.message || e.msg);
			console.error(e);
			return this.setState({ loading_spot: false });
		}
	
		//check if no available amount
		if (!resp.key) {
			if (resp.data.available === false && (resp.data.amount_foregin || resp.data.amount_usd)) 
			{
				Modal.confirm({
					title: <p>{`No enough ${trForm.otherCurrency} amount is available right now in stock!`}</p>,
					okText: 'Continue',
					cancelText: 'Abort',
					onOk: close => {
						let total =
							(this.state.amountInUSD && this.state.isSell) ||
							(!this.state.amountInUSD && !this.state.isSell)
								? resp.data.amount_foregin
								: resp.data.amount_usd;
						this.setState(
							{
								exchangeRate: resp.data.exchange_rate,
								fees: resp.data.fees,
								totalFees:(this.state.totalFees<resp.data.fees) ? resp.data.fees : this.state.totalFees,
								loading_spot: false,
								outputAmount: total,
								amount_foregin: resp.data.amount_foregin,
								amount_usd: resp.data.amount_usd,
								currentTransactionId: resp.data.id,
								showResult: true,
								onGoingOperation: true,
								spot_rate: resp.data.spot_rate,
								rate_calc: resp.data.rate_calc,
								fromCurrency: 'USD',
								toCurrency:trForm.otherCurrency,
								inputAmount: trForm.amount,
								amountInUSD: trForm.amountInUSD,
								omultiMode: false
							},
							() => {
								this.setState({
									storedTransactions: [...this.state.storedTransactions, this.getCurrentTransaction()],
									totalFees:this.calculateTotalFees( [...this.state.storedTransactions, this.getCurrentTransaction()])
								});
								this.state.multiMode = false;
							}
						);
						close();
					},
					onCancel: async close => {

						if (resp.data.id)
						{
							this.setState({loading_spot: true,loading: true});
							let cancelResp = await transactionService.cancelTransaction({
								id: resp.data.id,
								toCurrency: trForm.isSell ? trForm.otherCurrency : 'USD',
								fromCurrency: trForm.isSell ? 'USD' : trForm.otherCurrency,
								inputAmount: trForm.amount,
								type: 'ongoing'
							});								
						}					

						Modal.info({
							title: 'Operation canceled',
							content: 'Operation canceled successfully!'
						});

						this.setState({
							loading_spot: false,
							loading: false,
							showCustomerForm: false,
							showCustomerInfo: false,
							onGoingOperation: false,
							multiMode: false,
							showResult: !!this.state.storedTransactions.length,
							toCurrency: null,
							fromCurrency: 'USD',
							inputAmount: '',
							feesIncluded: false,
							amountInHand: true,
							preset_amount: null,
							preset_fees: null,
							preset_rate: null
						});
						close();
					},
					content: (
						<div>
							<p>The whole amount is not available right now.</p>
							<p>
								available amount: {formatDashedNumber(Math.floor(resp.data.available_amount))}{' '}
								{this.state.toCurrency}
							</p>
							<p>
								required amount : {formatDashedNumber(resp.data.amount_foregin)}{' '}
								{this.state.toCurrency}
							</p>
							<b>Do you want to continue?</b>
						</div>
					)
				});
				
				return;
			}
			Modal.error({
				title: resp.data &&  resp.data.message ? resp.data.message : resp.msg
			});
			return this.setState({
				loading_spot: false,
				showCustomerForm: false,
				showCustomerInfo: false,
				toCurrency: null,
				fromCurrency: 'USD',
				inputAmount: '',
				feesIncluded: false,
				amountInHand: true
			});
		}
		let total =
			(trForm.amountInUSD && trForm.isSell) || (!trForm.amountInUSD && !trForm.isSell)
				? resp.data.amount_foregin
				: resp.data.amount_usd;
		this.setState(
			{
				loading_spot:false,
				exchangeRate: resp.data.exchange_rate,
				fees: resp.data.fees,
				outputAmount: total,
				currentTransactionId: resp.data.id,
				showResult: true,
				fromCurrency: trForm.isSell ? 'USD' : trForm.otherCurrency,//
				toCurrency: trForm.isSell ? trForm.otherCurrency : 'USD',
				inputAmount: trForm.amount,
				amountInUSD: trForm.amountInUSD,
				amount_usd: resp.data.amount_usd,
				amount_foregin: resp.data.amount_foregin,
				spot_rate: resp.data.spot_rate,
				rate_calc: resp.data.rate_calc,
				multiMode: false
			},
			() => {
				const newTransactionsList = [...this.state.storedTransactions, this.getCurrentTransaction()];
				let TotalUnsignedAmount = 0;
				newTransactionsList.forEach((item) => {
					let OnGoingPayment = (item.onGoingCustomerPayment) ? item.onGoingCustomerPayment : '0';
					let USDToAdd = item.type === 'ongoing' ? OnGoingPayment :  item.amount_usd;
					TotalUnsignedAmount += Number.parseFloat(USDToAdd);
				})
				this.setState({
					storedTransactions: newTransactionsList,
					totalFees: this.calculateTotalFees([...this.state.storedTransactions, this.getCurrentTransaction()]),
					TotalUnsigned: TotalUnsignedAmount
				});
			}
		);

		if(resp.data.current_limit==0 || resp.data.current_limit<0)
		{
			this.openNotificationWithIcon('warning','Please Note','The Maximum Trnsaction Count Is Over Please contact System Administration');
		}
		this.resetCurrencyConversionForm = reset;
	};

	onCustomerSearch = (val) => {
		this.setState({fetching: true});
		this.search.next(val);
	};

	submitOperation = async () => { //LAST Operation Step
		let defaultState = {
			form: {},
			toCurrency: null,
			fromCurrency: 'USD',
			inputAmount: '',
			slab: 0,
			fees: 0,
			exchangeRate: 0,
			outputAmount: 0,
			showResult: false,
			showCustomerInfo: false,
			showCustomerForm: false,
			currentTransactionId: null,
			isSell: true,
			amountInUSD: true,
			feesIncluded: false,
			amountInHand: true,
			loading_spot: false,
			customers: [],
			showInvoice: false,
			editFactorShow: false,
			invoice: null,
			totalFees: 0,
			TotalUnsigned:0,
			customerSearchKey: 'mobile',
			customerType: false,
			selectedCustomer: null,
			onGoingOperation: false,
			onGoingCustomerPayment: '',
			storedTransactions: [],
			multiMode: false,		
			spot_rate:<Spin size="small" />,
			rate_calc:<Spin size="small" />,
			OperationType: false,
		};

		let resp;
		try {
			resp = await transactionService.commitTransaction(
				this.state.selectedCustomer.id,
				this.state.totalFees,
				this.state.customerType,
				this.state.storedTransactions.map(tr => ({
					recieved_payment: (tr.onGoingOperation) ? parseFloat(tr.onGoingCustomerPayment) : undefined,
					recieved_amount: tr.outputAmount,
					type: tr.type,
					id: tr.id,
				}))
			);
			if (!resp || !resp.key) throw new Error('Something went wrong!');
		} catch (e) {
			Modal.error({
				title: "Couldn't commit the operation!",
				content: e.message
			});
			return this.setState({
				loading_spot: false
			});
		}
		this.setState({
			...defaultState,
			invoice: resp.data.invoice,
			showInvoice: true
		});
		this.resetCurrencyConversionForm();

		return true;
		/*return Modal.success({
			title: 'Operation commited',
			content: 'Operation commited successfully!'
		});*/
	};

	onOperationSubmit = async () => {
		this.setState({ loading_spot: true });
		Modal.confirm({
			title: 'Are you sure you want to confirm this operations',
			content: (
				<SummaryList is_confirm="confirm-summery" totalFees={this.state.totalFees} transactions={this.state.storedTransactions}/>
			),
			okText: 'submit',
			onOk: this.submitOperation,
			onCancel: () => this.setState({ loading_spot: false })
		});
		// } else return this.submitOperation();
	};

	cancelOperation = async (id, type) => {
		return await transactionService.cancelOperation({
			type: type,
			type_id: id
			// user_id: 1
		});
	};

	onOperationRemove = async (id, type, last = false) => {
		this.setState({ loading_spot: true,loading: true });
		let resp;
		try {
			resp = await this.cancelOperation(id, type);
			if (!resp) throw new Error('Something went wrong!');
		} catch (e) {
			message.error("Couldn't cancel the operation!: " + e.message);
			return this.setState({ loading_spot: false });
		}
		message.info('Operation canceled successfully!');
		let transactions = this.state.storedTransactions.filter(tr => tr.id !== id);
		return last
			? this.setState({
				loading_spot: false,
					loading: false,
					showCustomerForm: false,
					showCustomerInfo: false,
					showResult: false,
					toCurrency: null,
					fromCurrency: 'USD',
					inputAmount: '',
					feesIncluded: false,
					amountInHand: true,
					storedTransactions: transactions
			  })
			: this.setState({ loading_spot: false, storedTransactions: transactions });
	};

	onSetPresetFees = (totalFees) => {
		this.setState({ totalFees: totalFees });
		this.onSetPresetAmount('fees', totalFees);
	};

	calculateTotalFees = (trx_list) => {
		let totalFees= 0;
		for(const idx in trx_list)
		{	
			let tr = trx_list[idx];
			if(tr.fees > totalFees)
				totalFees = tr.fees;
		}
		return totalFees;
	};

	onSetPresetAmount = (type, new_value) => {
		const trForm = {
			isSell:this.state.isSell,
			amountInUSD:this.state.amountInUSD,
			amount:this.state.amount,
			otherCurrency:this.state.isSell ? this.state.toCurrency :  this.state.fromCurrency,
		}
		this.onConvert(trForm, this.resetCurrencyConversionForm,new_value,type);
	};

	onTransactionCancel = async () => {
		this.setState({
			loading_spot: true,
			preset_amount: null,
			preset_fees: null,
			preset_rate: null
		});
		let resp;
		try {
			resp = await transactionService.cancelTransaction(this.state.storedTransactions);
			if (!resp) throw new Error('Something went wrong!');
		} catch (e) {
			Modal.error({
				title: "Couldn't cancel the operation!",
				content: e.message
			});
			return this.setState({ loading_spot: false });
		}

		this.resetAllState();

		return this.setState({
			loading_spot: false,
			showCustomerForm: false,
			showCustomerInfo: false,
			showResult: false,
			multiMode: false,
			toCurrency: null,
			fromCurrency: 'USD',
			inputAmount: '',
			feesIncluded: false,
			amountInHand: true,
			storedTransactions: [],
			totalFees: 0,
			TotalUnsigned: 0
		});
	};

	confirmonGoingCustomerPayment = (doStart) => {
		Modal.confirm({
			title: 'Enter the amount that the customer will pay',
			content: (
				<InputNumber
					min={0}
					decimalSeparator="."
					// value={this.state.onGoingCustomerPayment}
					style={{ width: '200px' }}
					formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
					parser={value => value.replace(/\$\s?|(,*)/g, '')}
					onChange={amount => this.setState({ onGoingCustomerPayment: amount.toString() })}
				/>
			),
			okText: 'Ok',
			onOk: () =>
			{
				this.state.storedTransactions[this.state.storedTransactions.length - 1].onGoingCustomerPayment = this.state.onGoingCustomerPayment;
				this.setState({
					storedTransactions: [...this.state.storedTransactions]
				});

				if(doStart)
					return this.setState({ showCustomerInfo: true });

				this.resetInputAndState();
			}
		});
	}

	addNewOperation = () => {
		if(this.state.onGoingOperation && !this.state.onGoingCustomerPayment)
		{
			this.confirmonGoingCustomerPayment(false);
			return; 
		}
		return this.resetInputAndState();
	};

	onStart = () => {

		if(this.state.onGoingOperation && !this.state.onGoingCustomerPayment)
		{
			this.confirmonGoingCustomerPayment(true);
			return; 
		}
		this.setState({ showCustomerInfo: true });
	};

	resetInputAndState = () => {
		this.resetCurrencyConversionForm();
		return this.setState({
			loading_spot: false,
			showCustomerForm: false,
			showCustomerInfo: false,
			toCurrency: null,
			fromCurrency: 'USD',
			inputAmount: '',
			feesIncluded: false,
			amountInHand: true,
			multiMode: true,
			onGoingOperation: false,
			onGoingCustomerPayment: '',
			OperationType: false,
		});
	}

	getCurrentTransaction = () => {
		let {
			currentTransactionId,
			inputAmount,
			toCurrency,
			fromCurrency,
			amountInUSD,
			fees,
			outputAmount,
			feesIncluded,
			amountInHand,
			isSell,
			exchangeRate,
			onGoingOperation,
			onGoingCustomerPayment,
			amount_usd,
			amount_foregin,		
			spot_rate,
			rate_calc,
		} = this.state;
		return {
			id: currentTransactionId,
			inputAmount,
			toCurrency,
			fromCurrency,
			amountInUSD,
			fees,
			outputAmount,
			feesIncluded,
			amountInHand,
			exchangeRate,
			onGoingOperation,
			onGoingCustomerPayment,
			type: onGoingOperation ? 'ongoing' : isSell ? 'operation' : 'stock',
			amount_usd,
			amount_foregin,		
			spot_rate,
			rate_calc,
		};
	};

	checkOtherCurrencyField = (field, other) => val => {
		let s = {
			[field]: val
		};

		if (this.state[other] === val) s[other] = null;
		if (this.state[other] !== 'USD' && val !== 'USD') s[other] = 'USD';
		this.setState(s);
	};

	onCustomerCreate = async data => {
		this.setState({ loading_spot: true });
		let resp;
		try {
			resp = await custoemrService.createCustomer(data);
			if (!resp) throw new Error('Something went wrong!');
		} catch (e) {
			Modal.error({
				title: "Couldn't create customer",
				content: e.message
			});
			this.setState({ loading_spot: false });
			return false;
		}
		message.success('Customer created successfully!');
		this.setState({ loading_spot: false, showCustomerForm: false, selectedCustomer: resp.data });
		return true;
	};

	onCustomerUpdate = async (id, data) => {
		this.setState({ loading_spot: true });
		let resp;
		try {
			resp = await custoemrService.updateCustoemr(id, data);
			if (!resp) throw new Error('Something went wrong!');
		} catch (e) {
			Modal.error({
				title: "Couldn't update customer",
				content: e.message
			});
			this.setState({ loading_spot: false });
			return false;
		}
		message.success('Customer update successfully!');
		this.setState({ loading_spot: false });
		return true;
	};

	onChoose =  (is_sell_start) => {
		this.setState({OperationType : true,isSell: is_sell_start });
	}

	render() {
		let {
			inputAmount,
			toCurrency,
			fromCurrency,
			outputAmount,
			fees,
			exchangeRate,
			showCustomerForm,
			showCustomerInfo,
			selectedCustomer,
			showResult,
			amountInUSD,
			loading_spot,
			customerSearchKey,
			customerType,
			customers,
			feesIncluded,
			amountInHand,
			showInvoice,
			invoice,
			spot_rate,
			isSell,
			storedTransactions,
			OperationType = false
		} = this.state;
			
		return (
			<>
				<Row gutter={8} style={{overflowX: 'hidden'}}>
					<Col xs={18} className="container amount-block">
						<div className="globe-bg">
							<img alt="" src={bgEarth} />
						</div>
						{
							OperationType
							?
							<ConvertAmountForm
								user = {this.props.user}
								fromCurrency={amountInUSD ? fromCurrency : toCurrency}
								toCurrency={amountInUSD ? toCurrency : fromCurrency}
								feesIncluded={feesIncluded}
								amountInHand={amountInHand}
								onConvert={this.onConvert}
								onFromCurrencyChange={this.onFromCurrencyChange}
								onResetPresets={this.onResetPresets}
								onChangeFactor={this.onChangeFactor}
								SetSelectedFactor={this.SetSelectedFactor}
								onFactorSubmit={this.onFactorSubmit}
								editFactorShow={this.state.editFactorShow}
								onFactorCancel={this.onFactorCancel}
								onFessIncludedChange={val => this.setState({ feesIncluded: val })}
								onInHandChange={val => this.setState({ amountInHand: val })}
								spot_rate={this.state.spot_rate}
								rate_calc={this.state.rate_calc}
								exchangeRate={this.state.exchangeRate}
								loading_spot ={ this.state.loading_spot}
								trx_count_limit ={ this.state.trx_count_limit}
								result_amount={amountInUSD ? this.state.amount_foregin : this.state.amount_usd}
								can_edit_rate={authService.hasPermissions(permissions.operationCanChangeRates)}
								isSell={isSell}
								/>
								:
								<ConvertOpeationChooser
									onChoose={this.onChoose}
								/>
						}

						{showResult && ! this.state.loading_spot &&
						(
							<ConversionResultBox							
								can_edit_rate={authService.hasPermissions(permissions.operationCanChangeRates)}
								exchangeRate={exchangeRate}
								onStart={this.onStart}
								onCancel={this.onTransactionCancel}
								onAddNewOperation={this.addNewOperation}
								fromCurrency={amountInUSD ? fromCurrency : toCurrency}
								toCurrency={amountInUSD ? toCurrency : fromCurrency}
								is_sell={isSell}
								fromAmount={amountInUSD ? formatDashedNumber(Number.parseFloat(this.state.amount_usd).toFixed(2)) : formatDashedNumber(Number.parseFloat(this.state.amount_foregin).toFixed(2))}
								toAmount={amountInUSD ? formatDashedNumber(Number.parseFloat(this.state.amount_foregin).toFixed(2)) :  formatDashedNumber(Number.parseFloat(this.state.amount_usd).toFixed(2))}
								onSetPresetAmount={this.onSetPresetAmount}
							/>
						)}
					</Col>
					<Col xs={6} className="left-border">
						{showResult || storedTransactions.length ? (
							<SummaryList
								editable
								can_edit_fees={authService.hasPermissions(permissions.operationCanChangeFees)}
								transactions={this.state.storedTransactions}
								onItemRemove={this.onOperationRemove}
								onSetPresetFees={this.onSetPresetFees}
								totalFees={this.state.totalFees}
							/>
						) : null}
					</Col>
				</Row>
				{showCustomerInfo && (
					<Row>
						<CustomerInfoForm
							onCustomerSearch={this.onCustomerSearch}
							onSearchKeyChange={(e) =>
								this.setState({
									customerSearchKey: e.target.value,
									selectedCustomer: null
								})
							}
							onCustomerTypeChange={(type) =>
								this.setState({
									customerType: type,
								})
							}
							onNewCustomer={() =>
								this.setState({
									showCustomerForm: true,
									selectedCustomer: null,
									customerType: false,
									customers: []
								})
							}
							onCustomerSelect={v =>
								this.setState({
									selectedCustomer: customers.find(c => c.id === v),
									showCustomerForm: true
								})
							}
							onCustomerCreate={this.onCustomerCreate}
							onCustomerUpdate={this.onCustomerUpdate}
							onOperationSubmit={this.onOperationSubmit}
							searchKey={customerSearchKey}
							customerType={customerType}
							customers={customers}
							showNewCustomerForm={showCustomerForm}
							selectedCustomer={selectedCustomer || undefined}
							fetching={this.state.fetching}
							FirstIdNeeded={this.props.user.first_id_limit <= this.state.TotalUnsigned}
							SecondIdNeeded={this.props.user.second_id_limit <= this.state.TotalUnsigned}
						/>
					</Row>
				)}
				{showInvoice && (
					<InvoiceModal
						visible={showInvoice}
						invoice={{ data: invoice.data, id: invoice.id, created_at: invoice.created_at,invoice_fees:invoice.fees }}
						onCancel={() => this.setState({ showInvoice: false })}
					/>
				)}
				<Modal okText="Update" title="Enter new Rate Factor" visible={this.state.factorModalvisible} onCancel={this.onFactorCancel} onOk={this.onFactorSubmit}>
					%<InputNumber  style={{width:'90%'}} suffix="%" placeholder="Percentage Factor from spot rate" key="btn_rate_change"  onChange={(val)=>{this.SetSelectedFactor(val)}} /> 
				</Modal>
			</>
		);
	}
}

export default memo(convertCurrency);