import React from 'react';
import { IFrame } from '../IFrame/IFrame';
import { Modal } from 'antd';
import { Invoice } from './Invoice';

export function InvoiceModal({ visible, invoice, onCancel }) {
	return (
		<Modal
			visible={visible}
			title="Invoice"
			okText="Print"
			onOk={() => {
				document.getElementById('invoice_frame').contentWindow.focus();
				document.getElementById('invoice_frame').contentWindow.print();
			}}
			onCancel={onCancel}
			maskClosable={false}
			style={{ top: '10px' }}
		>
			<IFrame unique="true" id="invoice_frame" name="invoice_frame" className="p-2 w-100" style={{ height: '700px' }}>
				<Invoice invoice={invoice} />
			</IFrame>
		</Modal>
	);
}
