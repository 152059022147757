import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CurrencyService from '../../services/currency.service';
import slabService from '../../services/slab.service';
import shopService from '../../services/shop.service';

import { Form, Input, Button, Select, Checkbox, message,Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

const AddNewSlab = props => {
	const [form] = Form.useForm();
	const slabData = props.location.data || null;
	const [currency, currencyData] = useState([]);
	const [shop, shopData] = useState([]);
	const [errors, setErrors] = useState({});

	let history = useHistory();

	useEffect(() => {
		async function fetchData() {
			let currencyResult = await CurrencyService.getCurrencies();
			currencyData(currencyResult.data);
			let resp = await shopService.getShops(0,0,true);
			let shopResult = resp.data;
			shopData(shopResult);
		}
		fetchData();
	}, []);

	const shops = shop.map(shop => {
		return <Option value={shop.id}>{shop.name}</Option>;
	});

	const currencies = currency.map(data => {
		return <Option value={data.id}>{data.code}</Option>;
	});

	const onFinish = async values => {
		setErrors({});

		if (slabData) {
			try {
				await slabService.editSlab(values, slabData.id);
				message.success('Slab was edited successfully!');
				history.push({
					pathname: '/dashboard/list-slabs',
					state: { id: slabData.id }
				});
			} catch (e) {
				message.error(e.message || e.msg);
				console.error(e);
				return setErrors(e.errors);
			}
		} else {
			try {
				await slabService.addNewSlab(values);
				form.resetFields();
				message.success('Slab was added successfully!');
			} catch (e) {
				message.error(e.message || e.msg);
				console.error(e);
				return setErrors(e.errors);
			}
		}
	};

	const onReset = () => {
		setErrors({});
		form.resetFields();
	};

	return (
		<Fragment>
			<h3 className="h3">Add New Slab</h3>
			<Form
				{...layout}
				form={form}
				name="control-hooks"
				onFinish={onFinish}
				initialValues={{
					boundary: slabData ? slabData.boundary : null,
					currency_id: slabData ? slabData.currency_id : null,
					shop_id: slabData ? slabData.shop_id : null,
					value: slabData ? slabData.value : null,
					is_fixed: slabData ? slabData.is_fixed : 1,
					is_sell: slabData ? slabData.is_sell : 1
				}}
			>
				<Form.Item
					name="boundary"
					label="Boundary"
					rules={[
						{
							required: true,
							message: 'Please enter the boundary!'
						}
					]}
					validateStatus={errors?.boundary && 'error'}
					help={errors?.boundary?.join('\n')}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="currency_id"
					label="Currency"
					// rules={[{ required: true, message: 'Please select the Currency!' }]}
					validateStatus={errors?.currency_id && 'error'}
					help={errors?.currency_id?.join('\n')}
				>
					<Select
						showSearch
						placeholder="Please select the Currency!"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
					>
						{currencies}
					</Select>
				</Form.Item>

				<Form.Item
					name="shop_id"
					label="Shop"
					// rules={[{ required: true, message: 'Please select the Shop!' }]}
					validateStatus={errors?.shop_id && 'error'}
					help={errors?.shop_id?.join('\n')}
				>
					<Select
						showSearch
						placeholder="Please select the Shop!"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().startsWith(input.toLowerCase())}
					>
						{shops}
					</Select>
				</Form.Item>

				<Form.Item
					name="value"
					label="Value"
					rules={[
						{
							required: true
						}
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item {...tailLayout} name="is_fixed" valuePropName="checked">
					<Switch checkedChildren="Fixed" unCheckedChildren="Ratio" />
				</Form.Item>

				<Form.Item {...tailLayout} name="is_sell" valuePropName="checked">
					<Switch checkedChildren="Sell" unCheckedChildren="Buy" />
				</Form.Item>

				<Form.Item {...tailLayout}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
					<Button htmlType="button" onClick={onReset}>
						Reset
					</Button>
				</Form.Item>
			</Form>
		</Fragment>
	);
};

export default AddNewSlab;
