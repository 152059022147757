import React, { Fragment, useEffect, useState } from 'react';

import systemRequestsService from '../../services/system_requests.service';
import {SaveOutlined } from '@ant-design/icons';
import {   Table,  Input, Button,message } from 'antd';

const SystemSettings = props => {
    
	const [DBSystemSettings, setDBSystemSettings] = useState([]);
    const [savingPhase, setSavingPhase] = useState(false);
    
	useEffect(() => {
		async function fetchData() {
			let resp = await systemRequestsService.getSystemSettings();
			setDBSystemSettings(resp.data);
		}
		fetchData();
    }, []);
    
    const onchange = (value, key) =>
    {
        let OldVals = [...DBSystemSettings];
        let newData = OldVals.map((itetm) => {
            if (itetm.key == key)
                itetm.value = value;
            return itetm;
        })
        setDBSystemSettings(newData);
    }

    const Save = async () => {
        setSavingPhase(true);
        let resp = await systemRequestsService.setSystemSettings(DBSystemSettings);
        if (!resp.data) {
            setSavingPhase(false);
            throw new Error('There is an error please try again!');
        }
        setSavingPhase(false);
		message.success('Saved successfully');
		
    }

    const columns = [
        {
            title: 'System Key',
			dataIndex: 'key',
            render: (text, record) => {
                return <span>
                    {record.key}
                </span>
            }
        },
        {
            title: 'System Value',
			dataIndex: 'value',
            render: (text, record) => (
                <span>
                    <Input key={record.key} onChange={value => { onchange(value.target.value,record.key)}} value={record.value}></Input>
                </span>
            )
        }
    ];
	
    return (
		<Fragment>
			<h3 className="h3">System Settings</h3>
			<Table
				dataSource={DBSystemSettings}
				columns={columns}
				size="middle"
            ></Table>
            <Button icon={<SaveOutlined />} onClick={()=>{Save()}} loading={savingPhase}> Save </Button>
		</Fragment>
	);
};

export default SystemSettings;