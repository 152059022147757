import styled from 'styled-components';

export const convertAmountDiv = styled.div`
	& > div:nth-child(1) {
		background-color: #fff;
		padding: 15px;
		border-top-right-radius: 18px;
		border-top-left-radius: 18px;
	}
	& .inputs {
		background-image: linear-gradient(to right,#002276ad,#237e07bf);
		border-radius: 19px;
		padding: 3% 5%;
		box-shadow: 0px 0px 5px 0px rgb(6 6 6 / 81%);
	}
	& .inputs.buy {
		background-image: linear-gradient(to left,#002276ad,#237e07bf);
	}
	& .inputs label {
		color: #fff;
		font-size: 13px;
	}
	& .inputs input {
		color: #18357e;
		font-weight: 500;
		height: 30px;
	}
	& .inputs .images {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding-top: 2%;
	}
	& .inputs .submit {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding-top: 2.4%;
	}
	&   .inputs button.submit  {
		background-color: #536ba1;
		border: 0;
		outline: 0;
		border-radius: 5px;
		padding: 4px 9px;
		height: 75px;
		width: 100%;
    	min-width: 49px;
	}&   .inputs.buy button.submit  {
		background-color: #5a9e47;
	}
	&   .inputs button.submit .disabled  {
		background-color: #cccccc;
		opacity:0.65;
	}
	& button:hover {
		box-shadow: 0 3px 5px rgba(250, 250, 250, 0.59);
	}
	& button:active {
		box-shadow: unset;
	}
	& .inputs button.toggle {
		height: 25px;
		border-radius: 5px;
		background: white;
		border: 0;
		outline: 0;
		padding: 0px 7px;
		cursor: pointer;
	}
	& .inputs .toggle .ant-switch-handle::before {
		background-color: #18357e87;
		border-radius: 5px;
		-webkit-box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
		box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
		-webkit-transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		width: 21px;
		height: 21px;
		content: '';
	}
	&  .ant-switch-checked .ant-switch-handle {
		left: calc(100% - 25px) !important;
	}
	&  .inputs .ant-switch-inner{
		color: #969798;
		font-size: 15px;
		font-weight: 600;
	}
	&  .inputs .toggle .ant-switch-inner {
		color: #03ab10 !important;
		margin: 0 0 0px 21px !important;
		font-size: 13px;
		line-height: 13px;
	}
	&  .inputs .toggle.ant-switch-checked .ant-switch-inner {
		color: #656e88 !important;
		margin: 0px 29px 0 0px !important;
	}
	&  .inputs .link-image {
		position: absolute;
		width: 97px;
		height: 81px;
		top: 59px;
		left: 5px;
	}
	&  .inputs .hidden {
		visibility:hidden;
	}
	&  .checkbox-fees {
		padding: 0;
		line-height: 15px;
		display:none;
	}
	&  .checkbox-fees  button{
		background-color: #ffffff; 
		margin: 5px 0;
    	border-radius: 5px;
	}
	&  .checkbox-fees  button{
		background-color: #ffffff; 
		margin: 5px 0;
    	border-radius: 5px;
		height: calc(100% - 31px);
	}
	&  .middle-block button{
		background-color: #ffffff; 
		margin: 5px 0;
    	border-radius: 5px;
		height: calc(100% - 31px);
		width: 37px;
		min-width: 21px;
	}
	&  .checkbox-fees  label,.middle-block label{
		padding-right :7px;
	}
	&   .checkbox-fees .ant-switch-handle::before,.middle-block .ant-switch-handle::before {
		background-color: #989898;
		-webkit-box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
		box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
		-webkit-transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		border-radius: 5px;
	}&
	.checkbox-fees .ant-switch-inner  {
		color: #969798;
		font-size: 13px;
	}&
	.operation-type  {
		font-size: 15px;
		color: white;
		text-shadow: 1px 1px 3px black;
		font-weight: 600;
	}&
	.middle-block .ant-switch-inner  {
		color: #969798;
		font-size: 12px;
		margin: -23px 0px 0 0px !important;
	}&
	.middle-block .ant-switch-checked .ant-switch-inner  {
		color: #969798;
		font-size: 12px;
		margin: 20px 0px 0 0px !important;
	}&
	.checkbox-fees .ant-switch-checked .ant-switch-handle {
		left: calc(100% - 21px) !important;
		top: calc(100% - 20px) !important;
	}&
	.middle-block .ant-switch-handle {
		top: calc(100% - 21px);
		width: calc(100% - 4px);
	}&
	.middle-block .ant-switch-checked .ant-switch-handle {
		left: 3px !important;
		top: 3px !important;
	}&
	.checkbox-fees .ant-switch-checked .ant-switch-handle::before,.middle-block .ant-switch-checked .ant-switch-handle::before {
		background-color: #62ba46;
	}&
	.checkbox-fees .anticon,.middle-block .anticon {
		vertical-align: 1px;
		color:green;
	}&
	.checkbox-fees .anticon .anticon-close,.middle-block .anticon .anticon-close {
		color:red;
	}
	&  .middle-block {
		margin: 0;
		padding: 0;
		text-align: center;
	}
	& .rate-hint {
		position:relative;
		border: 1px solid #9c9c9c;
		margin-top: 5px;
		text-align: center;
		border-radius: 6px;
		color:white;
	}
	& .rate-hint span{
		padding: 3px 0;
		display:inline-block;
	}
	& .factor-button
	{
		border: 1px solid #a5aacc;
		cursor: pointer;
		box-shadow: 0 -1px 2px #556691, inset 0 1px 2px rgb(0 0 0 / 56%), inset 0 0.25em 1em rgb(0 0 0);
		width: 100%;
    	background: lightslategray;
	}
	& .factor-button:hover
	{
		box-shadow: 0 3px 5px rgba(250, 250, 250, 0.59);
	}
	& .factor-block
	{
		padding-bottom: 3px !important;
		width: 100%;
		color: white;
	}
	& .amount-currency-bg{
		/*background-image: url(/static/media/uarrow.a12a4721.svg);
		background-repeat: no-repeat;
		background-position-x: 4%;
		background-position-y: 74%;
		background-size: 37px;*/
	}
	& .customer-rate
	{
		font-size: 115%;
	}
	& .spot-rate
	{
		opacity: 0.5;
	}
	.limit-block
	{
		position: absolute;
		color: red;
		top: 5px;
		left: 17%;
		width: 73%;
		background: white;
		z-index: 1;
		border: 1px solid #304885;
		box-shadow: 0px 0px 5px 0px rgb(6 6 6 / 81%);
		border-radius: 5px;
		font-size: 110%;
		text-align: center;
	}
	&  .amount-input {
		height: 47px;
		border-radius: 7px !important;
		border: 1px solid lightgray;
		padding: 0;
		font-size: 23px;
		font-weight: 400 !important;
		background-color: white;
		overflow: hidden;
	}
	.amount-input-block
	{
		position: absolute;
		z-index: 1;
		width: calc(100% - 87px);
		right: 7px;
		top: 9px;
		margin-left: 0;
	}
	.amount-input-block input {
		font-size: 19px;
		padding: 9px 1px;
		height: 100%;
	}
	.country-select .ant-select-arrow
	{
		left: 57px;
    	color: black;
	}
	
	.country-select, .country-select .ant-select-single .ant-select-selector,
	.country-select	ant-select-selector
	{
		height: 75px !important;
		padding: 3px;
	}

	.country-select small {
		position: absolute;
		z-index: 1;
		top: calc(100% - 13px);
		background: white;
		line-height: 13px;
		width: 100%;
		text-align: center;
		left: 0;
		border-top: 1px dashed #5f6070;
		padding-top: 0px;
	}

	.country-selection-object .ant-select-single,
	.country-selection-object .ant-select-single .ant-select-selector,
	{
		height: 100% !important;
		padding: 3px;
	}
	.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
		height: 33px;
		z-index: 1;
		padding-left: 39px;
		padding-top: 7px;
	}
	. inline-block
	{
		display: inline-block;
	}
`;
export const TinyText = styled.span`
	font-size: 10px;
	color: #6b6b6b;
	font-weight: 100;
`;
