import styled from 'styled-components';

export const formDiv = styled.div`
	position: relative;
	& > div {
		background-color: #fff;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
	}
	& .header {
		background-color: #18357e;
		color: #fff;
		padding: 5px 10px;
	}
	& .header h4 {
		font-size: 18px;
		font-weight: 400;
		margin: 0;
	}
	& .row {
		margin: 0;
	}
	& .search {
		padding: 20px 10px;
	}
	& .search form {
		width: 100%;
	}
	& .search form .form-group:nth-of-type(1) {
		width: 60%;
	}
	& .search form .form-group:nth-of-type(2) {
		width: 40%;
	}
	& .search form input {
		border-radius: 15px;
		overflow-x:hidden;
	}
	& .search button {
		background-color: #008db5;
		color: #fff;
		border: 0;
		outline: 0;
		padding: 5px 10px;
		margin: 0 0 0 2%;
		border-radius: 10px;
		font-size: 15px;
	}
	& .search span {
		color: #18357e;
		font-size: 13px;
	}
	& .search span:nth-of-type(1) {
		margin-right: 5%;
	}
	& .search span:nth-of-type(2) {
		margin-right: 5%;
	}
	& .inputData {
		padding: 3% 0.3%;
	}
	& .inputData .row {
		width: 100%;
		padding-left: 1%;
		margin: 4px 0;
	}
	& .inputData .form-group {
		padding: 0;
	}
	& .inputData .form-group label {
		padding: 0;
		font-size: 13px;
		color: #008db5;
		justify-content: flex-start;
		width: 100%;
	}

	& .inputData .form-group input {
		border-radius: 10px;
		font-size: 13px;
		width: 100%;
		/* padding: 4px 11px; */
	}

	& .inputData .ant-form-item {
		margin-bottom: 12px;
	}
	& .inputData .form-group button {
		width: 100%;
		border: 0;
		outline: 0;
		background-color: #ebebeb;
		padding: 6px 0;
		border-radius: 20px;
	}
	& .inputData button.form-submit {
		width: 100%;
		border: 0;
		outline: 0;
		background-color: #008db5;
		color: #fff;
		border-radius: 9px;
		padding: 5px 0;
		font-size: 17px;
		margin-top: 15px;
		box-shadow: 2px 2px 3px 2px #908d8d;
	}

	& button:disabled {
		background-color: #dbdbdb !important;
		cursor: not-allowed;
	}
`;
