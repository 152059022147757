import React from 'react';
import { Table, Button, Spin, message, Modal, Tag, Input, Row, Col, Select } from 'antd';
import BrokerService from '../../services/broker.service';
import useFetchData from '../../utils/fetchData';
import useEditRow from '../../utils/editRow';
import { useHistory, Link } from 'react-router-dom';
import { FILTERS, FILTER_UTILS, useFilters, filterOpts, filterOptsShop } from '../../hooks/filters.hook';
import country_states from '../../constants/country_states';

const ListBrockers = props => {
	const [data, setData, isLoading, setLoading] = useFetchData(BrokerService.getBrokers.bind(BrokerService), e => {
		message.error(e.message);
	});
	const edited = useEditRow();
	const history = useHistory();
	const { display, shops, setFilters, filters } = useFilters({
		data,
		filters: ['name', 'taxId', 'state'],
		filterFunctions: {
			filterByName: name => item => item.name.toLowerCase().indexOf(name.toLowerCase()) > -1,
			filterByTaxId: taxId => item => item.tax_id === taxId,
			// filterByCountry: country => item => item.country === country,
			filterByState: state => item => item.state.toLowerCase().indexOf(state.toLowerCase()) > -1
		}
	});

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
			render: (_, record) => <Link to={'/dashboard/edit-broker/' + record.id}>{record.name}</Link>
		},
		{
			title: 'Tax ID',
			dataIndex: 'tax_id',
			render: (_, record) => <Link to={'/dashboard/edit-broker/' + record.id}>{record.tax_id}</Link>
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: (a, b) => a.email.localeCompare(b.email),
			sortDirections: ['descend', 'ascend']
		},
		{
			title: 'Mobile',
			dataIndex: 'mobile'
		},
		{
			title: 'Active',
			dataIndex: 'is_active',
			render: value => (value ? <Tag color="blue"> Active </Tag> : <Tag color="volcano">Not Active</Tag>)
		},
		{
			title: 'Created at',
			dataIndex: 'create_at',

			sorter: (a, b) => a.create_at - b.create_at
		},
		{
			title: 'State',
			dataIndex: 'state',

			sorter: (a, b) => a.state.localeCompare(b.state)
		},
		{
			title: 'Action',
			key: 'action',
			fixed: 'right',
			ellipsis: true,
			render: (_, record) => (
				<React.Fragment>
					<Button
						danger={record.is_active}
						type={!record.is_active ? 'primary' : 'ghost'}
						style={{ width: '90px' }}
						size="small"
						onClick={() => {
							Modal.confirm({
								onOk: deleteRecord.bind(this, record.id),
								title: 'Are you sure you want to deactivate this employee?'
							});
						}}
					>
						{record.is_active ? 'Deactivate' : 'Activate'}
					</Button>
					<Button
						type="default"
						size="small"
						onClick={() => history.push('/dashboard/edit-broker/' + record.id)}
					>
						Edit
					</Button>
				</React.Fragment>
			)
		}
	];
	async function deleteRecord(id) {
		let result = await BrokerService.toggleActivate(id);
		if (result.key === 1) {
			let newData = data.map(record => {
				if (record.id === id) {
					record.is_active ^= 1;
				}
				return record;
			});
			setData(newData);
			message.success('the shop was deactivated successfully');
		}
	}

	return (
		<div>
			<h1 className="h3"> List Brokers </h1>
			<div className="pl-2">
				{!isLoading && (
					<>
						<Row className="my-3">
							<Col xs={8}>
								<span>Name: </span>
								<Input.Search
									allowClear
									onChange={e => {
										let val = e.target.value;
										setFilters({
											...filters,
											name: val
										});
									}}
									className="w-75"
									placeholder="Search"
								/>
							</Col>
							<Col xs={{ span: 8 }}>
								<span>Tax Id: </span>
								<Input.Search
									allowClear
									onChange={e => {
										let val = e.target.value;
										setFilters({
											...filters,
											taxId: val
										});
									}}
									className="w-75"
									placeholder="Search"
								/>
							</Col>
						</Row>
						<Row className="my-3">
							<Col xs={8}>
								<span>State: </span>
								<Input.Search
									allowClear
									onChange={e => {
										let val = e.target.value;
										setFilters({
											...filters,
											state: val
										});
									}}
									className="w-75"
									placeholder="Search"
								/>
							</Col>
							{/* <Col xs={8}>
								<span>State: </span>
								<Select
									allowClear
									onChange={val => {
										setFilters({
											...filters,
											parentShop: val
										});
									}}
									className="w-75"
									placeholder="Select State"
									showSearch
									filterOption={filterOptsShop}
									disabled={!filters.country}
								>
									{country_states[filters.country]?.map(s => (
										<Select.Option value={s} key={s}>
											{s}
										</Select.Option>
									))}
								</Select>
							</Col> */}
						</Row>
					</>
				)}
			</div>
			<Spin spinning={isLoading}>
				<Table
					size="small"
					columns={columns}
					dataSource={display}
					owClassName={(record, index) => (record.id == edited ? 'table-row-dark' : 'table-row-light')}
				/>
			</Spin>
		</div>
	);
};

export default ListBrockers;
