import React from 'react';

import { Table, message, Spin, Row, Tag, DatePicker, Button, notification } from 'antd';
import reportsService from '../../services/reports.service';
import { MailTwoTone,FileExcelTwoTone  } from '@ant-design/icons'
import moment from 'moment';
import '../ProfitReport/style.css';

const { RangePicker } = DatePicker;

export default class DetailsReportContainer extends React.Component {
	
	state = {
		data: {},
		display: {},
		shops: [],
		loading: false,
		loadingButton: false,
		ready: false,
		filter: {
			from_to: [moment(), moment()],
			fromDate : '',
			toDate : ''
		}
	};
	
    
    columns = [
		{
			title: 'Invoice Number',
			dataIndex: 'id',
			key: 'id',
			ellipsis: true,
			fixed: 'left'
		},
		{
			title: 'Transaction Date',
			dataIndex: 'date',
			key: 'date',
			ellipsis: true,
			fixed: 'left',
			sorter: (r1, r2) => {
				let p1 = moment(r1.date),
					p2 = moment(r2.date);
				return p1 > p2 ? 1 : p2 > p1 ? -1 : 0;
			},
			render: (text, record) => (
				moment(record.date).format('MM/DD/YYYY')
			)
		},
		{
			title: 'Operation',
			dataIndex: 'is_sell',
			key: 'is_sell',
			ellipsis: true,
			fixed: 'left',
			render: (_, report) => {
				return (
					<>
						{report.is_sell ? <Tag color="gold">Sell</Tag> : report.is_transfer ?
							<Tag color="blue">Transfered</Tag>
							:
							report.is_reconcilation
								? <Tag color="blue">Reconcilation</Tag>
								:
								<Tag color="green">Buy</Tag>
						}
					</>
				);
			},
			sorter: (r1, r2) => r1.is_sell - r2.is_sell
		},
		{
			title: 'Amount(USD)',
			dataIndex: 'usd_amount',
			key: 'usd_amount',
			ellipsis: true,
			fixed: 'left'
		},
		{
			title: 'Amount(Foreign)',
			dataIndex: 'foreign_amount',
			key: 'foreign_amount',
			ellipsis: true,
			fixed: 'left'
		},
		{
			title: 'Currency Code',
			dataIndex: 'currency_code',
			key: 'currency_code',
			ellipsis: true,
			fixed: 'left'
		},
		{
			title: 'Fee(USD)',
			dataIndex: 'fees',
			key: 'fees'
		},
		{
			title: 'Rate',
			dataIndex: 'exchange_rate',
			key: 'exchange_rate'
		},
		{
			title: 'Market Rate',
			dataIndex: 'market_rate',
			key: 'market_rate'
		},
		{
			title: 'Net Profit',
			dataIndex: 'profit',
			key: 'profit'
		},
		{
			title: 'Total Profit + Fees',
			dataIndex: 'profit_and_fees',
			key: 'profit_and_fees'
		},
		{
			title: 'Consumer Name',
			dataIndex: 'customer_name',
			key: 'customer_name'
		},
		{
			title: 'Consumer Address',
			dataIndex: 'customer_address',
			key: 'customer_address'
		},
		{
			title: 'Consumer Phone',
			dataIndex: 'customer_phone',
			key: 'customer_phone'
		},
		{
			title: 'Consumer DOB',
			dataIndex: 'birth_date',
			key: 'birth_date',
			ellipsis: true,
			fixed: 'right'
		},
		{
			title: 'Consumer ID Number',
			dataIndex: 'id_number',
			key: 'id_number',
			ellipsis: true,
			fixed: 'right'
		},
		{
			title: 'Consumer Id Typt',
			dataIndex: 'id_type',
			key: 'id_type',
			ellipsis: true,
			fixed: 'right'
		},
    ];
    
	onSearch = async () => {
		this.setState({ loading: true, ready: false });
		let data;
		let {fromDate ,toDate} = this.state.filter;

		try {
			data = await reportsService.getDetailsReports({from:fromDate ,to:toDate});
			if (!data.success) throw new Error(data.message);
		} catch (e) {
			message.error(e.message);
			console.error(e);
			return this.setState({ loading: false });
		}
		data = data.data.data;

		this.setState({
			data: data,
			display: data,
			ready: true,
			loading: false
		});
	};

    onExport = async () => {
		let {fromDate ,toDate} = this.state.filter;
        this.setState({ loading: true});
		try {
			reportsService.exportDetailsReports({from:fromDate ,to:toDate})
            .then(()=>{
                this.openNotificationWithIcon('success','Exported Successfuly','bottomRight')
                this.setState({loading : false });
            });
		} catch (e) {
            this.openNotificationWithIcon('error',e.message,'bottomRight')
			message.error(e.message);
			console.error(e);
			return this.setState({loading : false });
		}
    };

    openNotificationWithIcon = (type,msg,placement) => {
        notification[type]({
            message: 'Execution Result',
            description: msg,
            placement
        });
    };
    
	setDateRange = (range) =>
	{
		if (!range) return this.setState({ ready: false });
		const fromDate = range[0].format('YYYY-MM-DD');
		const toDate = range[1].format('YYYY-MM-DD');
		this.setState({ filter: { ...this.state.filter,fromDate ,toDate  } });
	}

	render() {
		return (
            <Spin spinning={this.state.loading}>
                <Row className="p-2">
                    <span className="mr-5">
                        <span style={{ fontWeight: '500' }}> Date Range </span>
                        <RangePicker
							size="small"
							value={this.state.from_to}
							onChange={
								(val,dateString) => this.setDateRange(val)
							}
                        />
                    </span>
					<span className="mr-5">
						<Button
							size="small"
							type="primary"
							onClick={this.onSearch}
						>
							Search
						</Button> 
						<Button size="small" type="warning" style={{ minWidth: '215px' }} icon={<FileExcelTwoTone />} htmlType="button" loading={this.loadingButton} onClick={this.onExport}>
                            Export to Excel
                        </Button>
					</span>
                </Row>
				<div
					style={{
						minHeight: '500px',
						textAlign: 'center'
					}}
				>
					{this.state.ready ? (
                        <div style={{marginBottom:'15px'}}>
						    <h5>Detailed Operations</h5>
						    <Table bordered pagination={{ pageSize: 100 }} 
								scroll={{ x: true }}
								rowKey="id"
								columns={this.columns}
								dataSource={this.state.display}
								size="small"
							/>
                        </div>
					) : (
						<p style={{ fontSize: '18px', fontWeight: '700', padding: '1rem 0' }}>
							Please select time range
						</p>
					)}
				</div>
			</Spin>
		);
	}
}