import React, { Fragment, useEffect, useState } from 'react';

import { Form, Input, Button, Select, message, Spin } from 'antd';

import shopService from '../../services/shop.service';
import CurrencyService from '../../services/currency.service';
import StockService from '../../services/stock.service';
import brokerService from '../../services/broker.service';

const { Option } = Select;
const layout = {
	labelCol: {
		span: 4
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

const InsertStock = () => {
	const [form] = Form.useForm();
	const [currency, currencyData] = useState([]);
	const [shop, shopData] = useState([]);
	const [brocker, setBrocker] = useState([]);
	const [loading, setLoading] = useState(false);
	const [current_price, setPrice] = useState('');
	const [current_rate, setRate] = useState(null);
	const [current_amount, setAmount] = useState(null);

	const calculateFromPrice = (price) =>
	{
		let fprice = parseFloat(price);
		let amount = parseFloat(current_amount);
		let calc = amount/fprice;
		let rate = calc.toFixed(6);
		setRate(rate);
		form.setFieldsValue({rate})
	}
	
	const calculateFromRate = (rate) =>
	{
		let frate = parseFloat(rate);
		let amount = parseFloat(current_amount);
		let calc = frate*amount;
		setPrice(calc.toFixed(2));
		form.setFieldsValue({'dolar_amount':calc.toFixed(2)})
	}

	useEffect(() => {
		async function fetchData() {
			try {
				let [currencyResult, shopResult, brokerResult] = await Promise.all([
					CurrencyService.getCurrencies(),
					shopService.getShops( 0,0,true),
					brokerService.getBrokers()
				]);
				// let currencyResult = await CurrencyService.getCurrencies();
				currencyData(currencyResult.data);
				
				shopData(shopResult.data);
				// let brockerResult = await brokerService.getBrokers();
				setBrocker(brokerResult.data);
			} catch (e) {
				console.error(e);
				return message.error('An error occured while fetching data. Please try reloading the page');
			}
		}
		fetchData();
	}, []);

	const shops = shop.map(shop => {
		return <Option value={shop.id}>{shop.name}</Option>;
	});

	const currencies = currency.map(data => {
		if (data.code != 'USD') {
			return <Option value={data.code}>{data.code}</Option>;
		}
		return;
	});

	const brockers = brocker.map(data => {
		return <Option value={data.id}>{data.name}</Option>;
	});

	const onFinish = async values => {
		setLoading(true);
		// values.user_id = 1;
		try {
			await StockService.addStock(values);
			message.success('Stock was added successfully!');
			form.resetFields();
		} catch (error) {
			message.error('there is an error please try Again');
			console.log(error);
		}
		setLoading(false);
	};

	const onReset = () => {
		form.resetFields();
	};

	return (
		<Fragment>
			<h3 className="h3">Insert Stock</h3>
			<Spin spinning={loading}>
				<Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>

					<Form.Item onChange={function(e){
						setAmount(e.target.value)
						}} name="currency_amount" label="Currency Amount" rules={[{ required: true }]}>
						<Input placeholder="Whatever you recieved. e.g. 1500" />
					</Form.Item>

					<Form.Item
						name="currency"
						label="Foreign Currency"
						hasFeedback
						rules={[{ required: true, message: 'Please select the currency!' }]}
					>
						<Select
							showSearch
							placeholder="Select currency"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().startsWith(input.toLowerCase())
							}
						>
							{currencies}
						</Select>
					</Form.Item>
					<div className="col-12">
						<div className="col-6">
							<Form.Item
								name="dolar_amount"
								label="Price In USD"
								rules={[
									{
										required: true
									}
								]}
							>
								<Input
									defaultValue={current_price}
									onChange={
									function(e){
										calculateFromPrice(e.target.value)
									}}
									placeholder="Whatever you paid. e.g. 100" />
							</Form.Item>
						</div>
						<div className="col-6">
							<Form.Item
								name="rate"
								label="Or Rate"
							>
								<Input
								value={current_rate}					
								onChange={function(e){
									calculateFromRate(e.target.value)
								} } 
								placeholder="Buying Rate" />
							</Form.Item>
						</div>
					</div>
					<Form.Item
						name="shop_id"
						label="Shop"
						hasFeedback
						rules={[{ required: true, message: 'Please select the Shop!' }]}
					>
						<Select
							showSearch
							placeholder="Select shop"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().startsWith(input.toLowerCase())
							}
						>
							{shops}
						</Select>
					</Form.Item>

					<Form.Item
						name="broker_id"
						label="Broker"
						hasFeedback
						rules={[{ required: true, message: 'Please select the Customer!' }]}
					>
						<Select
							showSearch
							placeholder="Select cutomer"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().startsWith(input.toLowerCase())
							}
						>
							{brockers}
						</Select>
					</Form.Item>

					<Form.Item {...tailLayout}>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
						<Button htmlType="button" onClick={onReset}>
							Reset
						</Button>
					</Form.Item>
				</Form>
			</Spin>
		</Fragment>
	);
};

export default InsertStock;
