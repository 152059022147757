const base_url = (origin = process.env.API_URL || 'https://www.atlanticxchange.com/exservice'); // (origin = process.env.API_URL || 'http://192.168.178.100:8000'); //
const api_url = `${base_url}/api`;
const base_auth_url = `${api_url}/auth`;
const base_auth_uri = `/auth`;

export default {
	development: {
		app_name: 'xchange',
		default_theme: 'light',
		base_url: base_url,
		api_url: api_url,

		access_token_field_name: 'access_token',
		referesh_token_field_name: 'referesh_token',

		URIs: {
			sign_in_url: `${base_auth_url}/login`,
			forget_password_url: `${base_auth_url}/password-reset`,
			get_user_info: `/profile`,
			update_user_profile: '/profile',
			change_password: '/password/change',
			verify_token_uri: `${base_auth_uri}/token/verify`,

			get_transaction_info: '/operations/store',
			get_xchange_info: '/operations/xchange',
			commit_transaction: '/operations/action',
			commit_transaction_multi: '/operations/action/multi',
			reverse_transaction: '/operations/action/reverse',
			cancel_transaction: '/operations/action',
			cancel_transactions_multi: '/operations/action/multi',

			get_customer: 'customer/:customerId',
			create_customer: 'customer/create',
			update_customer: 'customer/:customerId',
			search_customers: 'customer/search',
			edit_customer: 'customer/:id',
			get_shops: '/shop',
			get_shop: '/shop/shop/:shopId',
			get_shop_search: '/shop/search',
			get_all_shops_limit: '/shop/all_shops_limit',
			delete_shop: '/shop/:shopId',
			activate_shop: '/shop/:shopId/activate',
			deactivate_shop: '/shop/:shopId/deactivate',
			add_new_shop: 'shop/create',
			edit_shop: 'shop/update/:shopId',
			update_shop_slabs: 'shop/update_slabs',
			edit_shop_limit: 'shop/update_limits',
			get_needed_ctr: 'shop/needed-ctr',
			update_needed_ctr: 'shop/update_ctr',
			add_new_employee: 'employees',
			get_employees: '/employees',
			get_shop_employees: '/employees/shop_employees/:shopId',
			get_employee: '/employees/:id',
			delete_employees: '/employees/:id',
			toggle_active_employee: '/employees/:id/toggleActive',
			change_employee_role: '/employees/change-role',
			edit_employee: 'employees/:id',
			get_customers: '/customer',
			delete_customer: '/customer/:id',
			get_currencies: '/curriences',
			edit_currency: '/curriences/:currencyId',
			add_new_currency: 'curriences',
			delete_currency: '/curriences/:currencyId',
			toggle_active_currency: '/curriences/:id/toggleActive',
			add_new_role: 'roles',
			delete_role: 'roles/:id',
			edit_role: 'roles/:id',
			get_role: 'roles/:id',
			get_permissions: 'roles/permission/get-permissions',
			get_role_permission: 'roles/permission/:id',
			get_slabs: 'slab',
			add_new_slab: 'slab',
			edit_slab: 'slab/:id',
			delete_slab: 'slab/:id',
			get_fees: 'fees',
			add_new_fees: 'fees',
			edit_fees: 'fees/:id',
			delete_fees: 'fees/:id',
			add_new_broker: 'broker',
			get_brokers: 'broker',
			get_broker: 'broker/:id',
			edit_broker: 'broker/:id',
			toggle_active_broker: 'broker/:id/toggleActive',
			delete_broker: 'broker/:id',
			add_stock: 'operations/broker',
			get_available_stock: 'shop/:shopId/available-stock',
			get_shop_available_slabs: 'shop/:shopId/shop-slabs',
			get_shop_available_stock: 'shop/available-stock',
			set_shop_available_stock: 'shop/update-stock-status',

			get_invoices: 'invoices',
			get_custoemr_invoices: 'invoices/:custoemrId',
			get_reports: 'reports',
			get_profit_reports: 'reports/profit',
			get_totals_reports: 'reports/totals',
			get_details_reports: 'reports/detailed',
			get_fiat_reports: 'reports/fiat',
			export_totals_reports: 'reports/export',
			export_details_reports: 'reports/export_details',
			export_fiat_reports: 'reports/export_fiat',
			send_totals_reports: 'reports/send_report_to_shop',
			add_stock_by_file: 'operations/brokers/import',
			list_stock: 'stock',
			list_stock_details: 'stock/getrows',
			transfer_stock: 'stock/transfer_stock',
			stock_history: 'stock/stock_history',

			get_ongoing_ops: 'ongoings',
			delete_ongoing_op: 'ongoings/:ongoingId',
			update_ongoing_op: 'ongoings/update',

			get_system_requests: 'system_requests',
			get_system_request_type: 'system_requests/get_list/:request_type',
			get_system_request_item: 'system_requests/get_req_item/:item_id/:item_type',
			approve_system_request_item: 'system_requests/approve/:item_id',
			reject_system_request_item: 'system_requests/reject/:item_id',
			get_system_settings: 'system_requests/get_system_settings',
			set_system_settings: 'system_requests/set_system_settings'
		}
	}
};
