import { useEffect, useState } from 'react';
import { message } from 'antd';

export default function useFetchData(getCall, onError = null, deps = [], as_tree = null) {
	const [data, setData] = useState([]);
	const [isLoading, setLoading] = useState(true);
	getCall = typeof getCall === 'function' ? getCall : () => getCall;
	useEffect(
		() => {
			async function fetchData() {
				try {
					let result = await getCall();
					if (result.key === 0 || result.success === false) throw new Error('Something went wrong.');
					if (result.data) {
						setData(result.data);
					} else {
						setData(result);
					}
				} catch (e) {
					onError && onError(e);
					console.log('hi e');
					message.error(e.message, 15);
				} finally {
					setLoading(false);
				}
			}
			fetchData();
		},
		Array.isArray(deps) ? deps : []
	);
	return [data, setData, isLoading, setLoading];
}
