import ResourceManager from './resource-manager';
import config from '../utils/config';

class CtrService extends ResourceManager {
	async getShopNeededCtr() {
		return await this.getResource(config.URIs.get_needed_ctr);
	}

	async confirmShopNeededCtr(id,ctr_code) {
        let data ={id:id,ctr_code:ctr_code};
		return await this.postResource(config.URIs.update_needed_ctr, data);
	}
}

export default new CtrService();
