import ResourceManager from './resource-manager';
import config from '../utils/config';

class CurrencyService extends ResourceManager {
	async getCurrencies(params) {
		return await this.getResource(config.URIs.get_currencies, {
			params
		});
	}

	async deleteCurrency(currencyID) {
		return await this.deleteResource(config.URIs.delete_currency.replace(/:currencyId/g, currencyID));
	}

	async editCurrency(data, currencyId) {
		return await this.postResource(config.URIs.edit_currency.replace(/:currencyId/g, currencyId), data);
	}

	async addNewCurrency(data) {
		return await this.postResource(config.URIs.add_new_currency, data);
	}

	async toggleActive(id) {
		return await this.getResource(config.URIs.toggle_active_currency.replace(/:id/g, id));
	}
}

export default new CurrencyService();
