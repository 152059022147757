import React from 'react';

import { useState } from 'react';
import SummaryDiv, { SummaryListContainer } from './SummaryStyle';
import { formatDashedNumber } from '../../utils/strings';
import TransactionSummary from './TransactionSummary';
import { InputNumber } from 'antd';
import { CheckSquareOutlined , CloseSquareOutlined } from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';

const SummaryList = ({ transactions = [], onItemRemove, editable = false, onSetPresetFees,totalFees,is_confirm,can_edit_fees }) => {


	const [SelectedTargetFees,SetSelectedTargetFees] = useState(totalFees);
	const [editFeesShow,SetEditFeesShow] = useState(false);
	
	const onFeesHideClick = () =>
	{
		SetEditFeesShow(false);
	}
	
	const onFeesChangeShow = () =>
	{
		if(!can_edit_fees)
			return;
		SetEditFeesShow(!editFeesShow);
	}

	const onTargetFeesSubmit = () =>
	{
		if(!can_edit_fees)
			return;
		onSetPresetFees(SelectedTargetFees);
		SetEditFeesShow(false);
	}

	if (!Array.isArray(transactions)) transactions = [transactions];
	let TotalUSD = 0 ;
	let TotalWithFees = 0;
	

    const showTotalResult = (total) => {

        if (total < 0)
			return (<CurrencyFormat value={total * -1} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$('} suffix={')'} />);
        else
            return (<CurrencyFormat value={total} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} />);
    }

	return (
		<SummaryListContainer className="divscroll">
			<p style={{marginBottom: '-13px'}}>Summery</p>

			<div className="mt-2" style={{height: '221px',overflowY: 'auto'}}>
				{
					transactions.map(tr => 
					{

						let isSell = tr.type=='operation'||tr.type=='ongoing';

						let OnGoibgPayment = (tr.onGoingCustomerPayment) ? tr.onGoingCustomerPayment : '0';

						let USDToAdd = tr.type=='ongoing' ? OnGoibgPayment :  tr.amount_usd;
						TotalUSD +=  (isSell)
						? Number.parseFloat(USDToAdd)
						: Number.parseFloat(USDToAdd)*-1;

						TotalWithFees = TotalUSD;

						return (
							<TransactionSummary
								key="transaction-summary"
								amount={formatDashedNumber(tr.inputAmount)
								}
								fromAmount={ formatDashedNumber(parseFloat(tr.amount_usd).toFixed(2))}
								toAmount={ formatDashedNumber(parseFloat(tr.amount_foregin).toFixed(2))}
								fromCurrency={tr.fromCurrency}
								toCurrency={tr.toCurrency }
								is_sell = {isSell}
								rate={tr.exchangeRate}
								onGoing={tr.onGoingOperation}
								onGoingCustomerPayment={tr.onGoingCustomerPayment}
								onRemove={() =>
									onItemRemove(
										tr.id,
										tr.onGoingOperation ? 'ongoing' : tr.fromCurrency === 'USD' ? 'operation' : 'stock',
										transactions.length === 1
									)
								}
							/>
						)
					}
				)}
				<div className={is_confirm ? "total-amount-block-confirm" : "total-amount-block"}>
					<div className={can_edit_fees ? "fees-button" : "fees-block"} onClick={onFeesChangeShow}>
						<span className="fees-title">Fees:</span>
						<span className="fees-amount">{parseFloat(totalFees).toFixed(2)}$</span>
					</div>
					{
						editFeesShow && can_edit_fees
						?   <span className="main-fees-edit">
								<InputNumber className="fees-input" value={totalFees} 
									onChange={e => {
										SetSelectedTargetFees && SetSelectedTargetFees(e);
									}}
									onKeyDown={e => {
										if (e.keyCode === 13) {
											e.preventDefault();
											onTargetFeesSubmit();
										}
									}}
								/>
								<span className="fees-icon-button" onClick={onTargetFeesSubmit}>
									<CheckSquareOutlined className="green" />
								</span>
								<span className="fees-icon-button " onClick={onFeesHideClick}>
									<CloseSquareOutlined  className="red"/>
								</span>
							</span> 
						:
						''
					}
					<span className="total-title">Total :</span> <span className={(TotalUSD < 0 ? 'total-amount red' : 'total-amount green')}>
						{showTotalResult(TotalWithFees)}
					</span>
				</div>
			</div>
		</SummaryListContainer>
	);
};

export default SummaryList;